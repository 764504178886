import { Container, Row, Col } from '@reassure/ui';
import { HomeIntroPanel } from 'components/home-intro-panel';

import { HomeContainer } from './home.styles';
import { HomeIntroCategories } from 'components/home-intro-categories';
import { DisclaimerBar } from 'components/disclaimer';
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { useCreateSessionQuery } from 'graphql/generated';

export const Home = () => {
    const { route } = useAuthenticator(context => [context.route]);
    const message = route === 'authenticated' ? '' : 'Loading...';

    const { data, loading } = useCreateSessionQuery();

    if (!loading) {
        localStorage.setItem('sessionID', data?.createSession as string);
    }

    return (
        <>
            <Heading level={1}>{message}</Heading>
            <Container>
                <Row>
                    <Col
                        style={{
                            padding: 0,
                        }}
                    >
                        <HomeContainer>
                            <HomeIntroPanel />
                        </HomeContainer>
                    </Col>
                </Row>
            </Container>
            <HomeIntroCategories />
            <DisclaimerBar />
        </>
    );
};
