/* eslint-disable react/no-array-index-key */
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const limit = parseInt(process.env.REACT_APP_TIMEOUT as string, 10) || 900;

const AutomaticLogout = () => {
    const win: Window = window;
    const location = useLocation();
    let timer = 0;

    const tick = async () => {
        timer += 1;
        if (timer % 10 === 0) {
            console.log('AutomaticReturnToHome', `limit=${limit} timer=${timer}`);
        }
        if (timer >= limit) {
            win.location = '/';
        }
    };
    useEffect(() => {
        if (location.pathname !== '/') {
            let sessionInactivityTimerID = 0;
            sessionInactivityTimerID = win.setInterval(() => {
                tick();
            }, 1000);

            return () => {
                win.clearInterval(sessionInactivityTimerID);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return <span />;
};

export default AutomaticLogout;
