
import styled from 'styled-components';

export const DisclaimerBarStyle = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 10px 0;

    .disclaimer {
        
        &__title {
            font-size: ${({ theme }) => theme.fontSizes[8]}!important; // 24px
            color: ${({ theme }) => theme.colors.aubergine};
            font-family: 'PhoenixSansBoldWeb';
        }
        &__content {
            padding: 15px 31px; 
            border: 1px solid ${({ theme }) => theme.colors.lightgrey};
            border-radius: 2px;

            p {
                margin-bottom: 0;
            }
        }
        &__link {
            color: ${({ theme }) => theme.colors.charcoal};
            text-decoration: none;
            font-family: 'PhoenixSansMediumWeb';
        }
    }
`;
