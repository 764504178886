import styled, { keyframes } from 'styled-components';
import { breakpointDown } from '@reassure/ui';

import {
    ModalHeader as RuiModalHeader,
    ModalBody as RuiModalBody,
    ModalCloseButton as RuiModalClose,
    Modal as RuiModal,
} from '@reassure/ui';

interface ResultsFeedbackStyleProps {
    startAnimation: boolean;
}

// Bounce keyframe animation
const bounceAnimation = keyframes`
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-8px);
    }
    60% {
        transform: translateY(-5px);
    }
`;

// Rotation keyframe animation
const rotateAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(20deg);
    }
`;

export const ResultsFeedbackStyle = styled.div<ResultsFeedbackStyleProps>`
    cursor: pointer;
    position: fixed;
    z-index: 100;
    top: 360px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.lightcoral};
    padding: 15px;
    width: 150px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    gap: 15px;
    animation: ${({ startAnimation }) => (startAnimation ? bounceAnimation : 'none')} 1s infinite;

    ${breakpointDown.lg`
        position: static;
        width: 100%;
        margin-bottom: 20px;
    `}

    svg {
        width: 45px;
        animation: ${({ startAnimation }) => (startAnimation ? rotateAnimation : 'none')} 1s infinite alternate; /* Rotate the SVG icon slightly back and forth */
    }
    p {
        font-family: 'PhoenixSansMediumWeb';
        font-size: ${({ theme }) => theme.fontSizes[4]}!important; // 16px
        line-height: ${({ theme }) => theme.fontSizes[7]}!important;
        margin-bottom: 0;
    }
`;

export const Modal = styled(RuiModal)`
    max-width: 600px;
    padding: 50px;
    color: ${({ theme }) => theme.colors.charcoal};

    ${breakpointDown.lg`
        padding: 50px 15px 15px;
    `}

    .modal-close-container {
        display: flex;
        cursor: pointer;
        span {
            color: ${({ theme }) => theme.colors.lightgrey};
            position: absolute;
            top: 16px;
            right: 15px;
        }
    }
`;

export const ModalHeader = styled(RuiModalHeader)`
    font-family: 'PhoenixSansBoldWeb';
    font-size: 28px;
    line-height: 36px;
    justify-content: center;
    padding: 0;
    border: none;
`;

export const ModalBody = styled(RuiModalBody)`
    margin-top: 12px;
    font-size: ${({ theme }) => theme.fontSizes[5]}!important; // 16px

    .results-feedback-row {
        gap: 20px;
        justify-content: center;
        align-items: center;

        p {
            color: ${({ theme }) => theme.colors.lightgrey};
            margin: 0;
        }
    }

    .results-feedback-form-group {
        padding: 15px 0;
        text-align: center;
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey};

        p {
            color: ${({ theme }) => theme.colors.charcoal};
            margin-top: 0;
        }
    }

    .results-feedback-form-group:last-of-type {
        border-bottom: none;
    }

    .result-feedback {
        text-align: center;
        margin-bottom: 16px;
    }

    .result-feedback-stars {
        display: flex;
        justify-content: center;
        gap: 8px;
    }

    .results-feedback-star {
        position: relative;
        cursor: pointer;
    }

    .stars_radio-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        clip: rect(1px, 1px, 1px, 1px);
    }

    svg {
        width: 30px;
        height: 29px;
        stroke-width: 1;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .star-path {
        fill: white;
        stroke: ${({ theme }) => theme.colors.ember};
        stroke-width: 3px;
    }

    .input-group {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .input-group input[type='radio'] {
        position: absolute;
        opacity: 0;
        appearance: none;
    }

    .input-group label {
        display: inline-block;
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        margin: 0 10px;
    }

    .input-group label::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 4px;
        width: 16px;
        height: 16px;
        left: 0;
        border: 1px solid ${({ theme }) => theme.colors.lightgrey};
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.white};
    }

    .input-group input[type='radio']:checked + label::before {
        border-color: ${({ theme }) => theme.colors.ember};
    }

    .input-group input[type='radio']:checked + label::after {
        content: '';
        position: absolute;
        top: 7px;
        left: 3px;
        width: 10px;
        height: 10px;
        background-color: ${({ theme }) => theme.colors.ember};
        border-radius: 50%;
    }

    textarea {
        margin-top: 10px;
        border: 1px solid ${({ theme }) => theme.colors.lightgrey};
        border-radius: 6px;
        padding: 20px;
        width: 100%;
    }

    .submit-container {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        align-items: center;

        p {
            color: ${({ theme }) => theme.colors.lightgrey};
        }
    }

    .result-feedback-submit {
        cursor: pointer;
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.ember};
        width: 190px;
        height: 43px;
        border-radius: 4px;
        font-weight: 900;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: ${({ theme }) => theme.colors.ember};
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;

export const ModalCloseButton = styled(RuiModalClose)`
    position: absolute;
    top: 16px;
    right: 55px;
    height: 18px;
    padding: 0;

    svg {
        width: 12px;
        height: 12px;
    }
`;
