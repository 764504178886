import styled from 'styled-components';
import { breakpointDown } from '@reassure/ui';

import {
    ModalHeader as RuiModalHeader,
    ModalBody as RuiModalBody,
    ModalCloseButton as RuiModalClose,
    Modal as RuiModal,
} from '@reassure/ui';

export const InfoBoxStyle = styled.div`
    cursor: pointer;
    position: fixed;
    top: 360px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.lightcoral};
    padding: 15px;
    width: 200px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    gap: 10px;

    ${breakpointDown.lg`
        position: static;
        width: 100%;
        margin-bottom: 20px;
    `}

    svg {
        width: 45px;
    }
    p {
        font-family: 'PhoenixSansMediumWeb';
        font-size: ${({ theme }) => theme.fontSizes[4]}!important; // 16px
        line-height: ${({ theme }) => theme.fontSizes[7]}!important;
        margin-bottom: 0;
    }
`;

export const Modal = styled(RuiModal)`
    max-width: 460px;
    padding: 50px;
    color: ${({ theme }) => theme.colors.charcoal};

    ${breakpointDown.lg`
        padding: 50px 15px 15px;
    `}

    .modal-close-container {
        display: flex;
        cursor: pointer;
        span {
            color: ${({ theme }) => theme.colors.lightgrey};
            position: absolute;
            top: 16px;
            right: 15px;
        }
    }
`;

export const ModalHeader = styled(RuiModalHeader)`
    font-family: 'PhoenixSansBoldWeb';
    font-size: 28px;
    line-height: 36px;
    padding: 0;
    border: none;
`;

export const ModalBody = styled(RuiModalBody)`
    margin-top: 12px;
    font-size: ${({ theme }) => theme.fontSizes[5]}!important; // 16px
`;

export const ModalCloseButton = styled(RuiModalClose)`
    position: absolute;
    top: 16px;
    right: 55px;
    height: 18px;
    padding: 0;

    svg {
        width: 12px;
        height: 12px;
    }
`;
