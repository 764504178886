import { Link } from '@reassure/ui';
import { ResultsInfoBoxStyle } from './results-info-box.styles';

interface ResultsInfoBoxProps {
    svg: string;
    content: string;
    linkText: string;
    linkUrl: string;
}

export const ResultsInfoBox = ({ svg, content, linkText, linkUrl }: ResultsInfoBoxProps) => {
    return (
        <ResultsInfoBoxStyle>
            <Link href={linkUrl} target="_blank">
                <img src={svg} alt="graphic icon" />
                <div className="link-content">{content}</div>
                <div className="link-text">{linkText}</div>
            </Link>
        </ResultsInfoBoxStyle>
    );
};
