import { Link } from 'react-router-dom';
import { QuestionnaireNextButtonStyles } from './questionnaire-next-button.styles';

interface QuestionnaireNextButtonProps {
    route: string;
    dirtyFields?: {};
    defaultValue?: any;
}

export const QuestionnaireNextButton = ({ route, dirtyFields, defaultValue }: QuestionnaireNextButtonProps) => {
    return (
        <QuestionnaireNextButtonStyles>
            {Boolean(defaultValue) ? (
                <Link
                    className={Boolean(defaultValue) ? '' : 'isDisabled'}
                    aria-disabled={!Boolean(defaultValue)}
                    to={route}
                >
                    Next question
                </Link> // Dev mode
            ) : (
                <Link className={dirtyFields ? '' : 'isDisabled'} aria-disabled={!dirtyFields} to={route}>
                    Next question
                </Link>
            )}
        </QuestionnaireNextButtonStyles>
    );
};
