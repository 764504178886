// Function to convert SVG to base64-encoded image
export const convertSvgToImage = async (chartSVG: any): Promise<string | null> => {
    try {
        const canvas = document.createElement('canvas');
        canvas.width = 920 / 2;
        canvas.height = 242;

        const ctx = canvas.getContext('2d');
        const DOMURL = window.URL || window.webkitURL || window;

        const img = new Image();
        const svgBlob = new Blob([chartSVG], { type: 'image/svg+xml;charset=utf-8' });
        const url = DOMURL.createObjectURL(svgBlob);

        return new Promise<string | null>((resolve, reject) => {
            img.onload = () => {
                if (ctx) {
                    ctx.drawImage(img, 0, 0);
                    DOMURL.revokeObjectURL(url);

                    canvas.toBlob(blob => {
                        if (blob) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const dataUrl = reader.result as string;
                                resolve(dataUrl);
                            };
                            reader.readAsDataURL(blob);
                        } else {
                            reject(new Error('Error converting canvas to blob.'));
                        }
                    }, 'image/png');
                } else {
                    reject(new Error('Canvas context is not available.'));
                }
            };

            img.src = url;
        });
    } catch (error) {
        console.error('Error converting SVG to image:', error);
        return null;
    }
};
