// components/Layout.js
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from '@reassure/ui';
import { Header } from 'components/header';
import { useForm, FormProvider } from 'react-hook-form';
import initialState from 'utils/initial-state';
import { ProgressBarWellbeing } from 'components/progress-bar-wellbeing';
import { ProgressBarWorklife } from 'components/progress-bar-worklife';
import { ProgressBarWealth } from 'components/progress-bar-wealth';
import { FormData, useCalculateResultLazyQuery } from 'graphql/generated';
import AutomaticLogout from 'components/automatic-logout/automatic-logout';

export const LayoutWithProvider = () => {
    const [formData, setFormData] = useState<FormData>();
    const [finishedQuestionaire, setFinishedQuestionaire] = useState(false);

    const navigate = useNavigate();

    const [submitForm, { data, loading, error }] = useCalculateResultLazyQuery({
        variables: { input: formData! },
    });

    const onSubmit = (data: any) => {
        setFormData(data);
        setFinishedQuestionaire(true);
    };

    const hookForm = useForm({
        defaultValues: initialState,
    });

    useEffect(() => {
        if (finishedQuestionaire) {
            // from useCalculateResultLazyQuery hook - apollo
            submitForm();

            if (error || loading || data) {
                if (error) {
                    console.log('There has been a error!!!', error);
                }
                if (data || loading) {
                    // Navigate to new route - results sending response back from server ---
                    const answers = data!.calculateResult.answers;
                    const score = data!.calculateResult.score;
                    const overallSectionParagraphs = data!.calculateResult.overallSectionParagraphs;
                    const topTips = data!.calculateResult.topTips;
                    const allSectionTips = data!.calculateResult.allSectionTips;
                    const totalPercentage = data!.calculateResult.totalPercentage;

                    navigate('/results', {
                        state: { answers, score, overallSectionParagraphs, topTips, allSectionTips, totalPercentage },
                    });
                    console.log('Response back from backend', data!.calculateResult);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, finishedQuestionaire, data]);

    return (
        <>
            <AutomaticLogout />
            <Header />

            <FormProvider {...hookForm}>
                <Container style={{ maxWidth: 1020, marginTop: 60 }}>
                    <Row noGutters>
                        <Col>
                            <ProgressBarWellbeing />
                        </Col>
                        <Col>
                            <ProgressBarWorklife />
                        </Col>
                        <Col>
                            <ProgressBarWealth />
                        </Col>
                    </Row>
                </Container>
                <form onSubmit={hookForm.handleSubmit(onSubmit)}>
                    <Outlet />
                </form>
            </FormProvider>
        </>
    );
};
