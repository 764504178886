function getColorByScoreType(name: string) {
    let color;

    switch (name) {
        case 'wellbeing':
            color = '#A57EF0';
            break;
        case 'worklife':
            color = '#8CDAE9';
            break;
        case 'wealth':
            color = '#5FCF8A';
            break;
        default:
            color = '#000000';
            break;
    }

    return color;
}

export const getHighchartOptions = (score: number, name: string) => {
    return {
        colors: [getColorByScoreType(name), '#F2F2F2'],
        title: {
            text: '', // Set an empty string for the chart title
        },
        exporting: {
            enabled: false,
        },
        chart: {
            type: 'variablepie',
            height: 140,
        },
        plotOptions: {
            variablepie: {
                borderWidth: 3,
            },

            series: {
                animation: {
                    duration: 3000,
                },
                states: {
                    hover: {
                        animation: false, // Disable hover animation on the pie chart
                    },
                },
                dataLabels: {
                    enabled: false,
                },
            },
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        accessibility: {
            enabled: false,
        },
        series: [
            {
                minPointSize: 10,
                innerSize: '60%',
                zMin: 0,
                name: 'answers',
                data: [
                    {
                        name: name,
                        y: score, // % score
                        z: 5,
                    },
                    {
                        name: 'Remaining',
                        y: 100 - score, // Set the percentage of the pie that should be empty
                        z: 1,
                    },
                ],
            },
        ],
    };
};
