import { H1 } from '@reassure/ui';
import { ProgressBarWellbeingStyles, ProgressBarLoader, ProgressBarTitleContainer } from './progress-bar-wellbeing.styles';
import { useLocation } from "react-router-dom"

export const ProgressBarWellbeing = () => {
  const location = useLocation();
  return (
    <>
      <ProgressBarTitleContainer>
        <H1 className={location.pathname.substring(1)}>Wellbeing</H1>
      </ProgressBarTitleContainer>
      <ProgressBarWellbeingStyles>
        <ProgressBarLoader><span className={location.pathname.substring(1)}/></ProgressBarLoader> 
      </ProgressBarWellbeingStyles>
    </>
  )
}