/**
 * Styled-components theme for Reassure-UI form components
 */
import pallette from './pallette';
const { transparent, ember, white, black } = pallette;

const themeConfig = {
    colors: {
        formField: {
            focusHighlight: black,
        },
       
     
        input: {
            text: {
                text: black,
                background: white,
                border: white,
                focus: {
                    background: white,
                    border: white,
                    error: {
                        background: white,
                        border: white,
                    },
                },
                error: {
                    background: white,
                    border: white,
                },
                disabled: {
                    border: '#c9c4c4',
                    background: '#ebeaea',
                },
            },
            checkbox: {
                background: white,
                border: white,
                checked: {
                    background: transparent,
                    border: ember,
                    tickIcon: ember,
                },
                focus: {
                    background: white,
                    border: white,
                    highlight: '#E0F2FE',
                },
            },
            radio: {
                background: white,
                border: ember,
                checked: {
                    background: white,
                    border: ember,
                    dot: ember,
                },
                focus: {
                    background: white,
                    border: ember,
                    highlight: '#E0F2FE',
                },
            },
        },
        label: {
            text: black,
        },
      
    },
};

export default themeConfig;
