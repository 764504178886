const pallette = {
    transparent: 'rgba(0,0,0,0)',
    white: '#ffffff',
    black: '#000000',
    aubergine: '#331E38',
    lightAubergine: '#412149',
    eggshell: '#FFF4EC',
    ember: '#FF455D',
    lightember: '#FF7471',
    coral: '#FFA687',
    lightcoral: '#FFDDCF',
    blue: '#3FA1FF',
    charcoal: '#2E2D2C',
    grey: '#F2F2F2',
    lightgrey: '#ADAAA6',
    brightGreen: '#5FCF8A',
    green: '#4DA999',
    teal: '#8CDAE9',
    purple: '#A57EF0',
    gradientOne: '#FDF4EC',
    gradientTwo: '#F6D7C7',
    gradientThree: '#F0AD93',

    danger: '#ff3c55',
    warning: '#a96700',
    success: '#017d05',
    info: '#202e5b',
};

export default pallette;
