import { Row, Col } from '@reassure/ui';
import { FieldLabel, FieldLabelContainer } from './field.styles';

interface FieldWrapperProps {
    label: string;
    labelFor?: string;
    children: React.ReactNode;
}

export const FieldWrapper = ({ label, labelFor, children }: FieldWrapperProps) => {
    return (
        <Row>
            <Col>
                <FieldLabelContainer>
                    <FieldLabel htmlFor={labelFor}>{label}</FieldLabel>
                </FieldLabelContainer>
                <Row>{children}</Row>
            </Col>
        </Row>
    );
};
