import { Container } from '@reassure/ui';
import styled from 'styled-components';
import ArrowRight from '../../../assets/images/arrow-black.svg';

export const StartContainer = styled(Container)`
    max-width: 1020px;
    margin-top: 15px;
`;

export const StartStyle = styled.div`
    .start {
        padding-top: ${({ theme }) => theme.space[9]};
        &__title {
            text-align: center;
            margin-bottom: ${({ theme }) => theme.space[7]};
        }
        &__content {
            text-align: center;
            line-height: 1.5 !important;
            font-size: ${({ theme }) => theme.fontSizes[8]}!important; // 24px

            &--small {
                line-height: ${({ theme }) => theme.fontSizes[8]}!important; // 24px
                font-size: ${({ theme }) => theme.fontSizes[5]}!important; // 18px
            }
        }
        &__btn {
            margin: 40px auto;
            border: 2px solid ${({ theme }) => theme.colors.ember};
            color: ${({ theme }) => theme.colors.charcoal};
            text-decoration: none;
            font-family: 'PhoenixSansMediumWeb';
            border-radius: 4px;
            font-size: 16px;
            max-width: 320px;
            height: 48px;
            padding: 0 37px;
            display: flex;
            align-items: center;
            gap: 130px;
            transition: border-bottom-right-radius 180ms ease-in-out;

            &:hover {
                border-bottom-right-radius: 22px;
            }
            &:after {
                content: '';
                background: url(${ArrowRight}) no-repeat center center;
                height: 32px;
                width: 32px;
            }
        }
    }
`;
