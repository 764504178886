import { Container, H1, H4, P, Row, Col } from '@reassure/ui';
import { Link } from 'react-router-dom';
import { HomeIntroVideo } from 'components/home-intro-video';
import { HomeIntroPanelStyle } from './home-intro-panel.styles';

export const HomeIntroPanel = () => {
    return (
        <HomeIntroPanelStyle>
            <Container>
                <Row>
                    <Col className="mid-life" role="region" aria-label="main section" col="12" lg="6">
                        <Row>
                            <Col col="12" lg="12" xl="10">
                                <H1 id="main-content" role="heading" aria-label="main heading">
                                    Introducing our new Mid-life MOT
                                </H1>

                                <P className="mid-life__content" role="region" aria-label="main content">
                                    Average life expectancy continues to rise. This means that many of us will live and
                                    work for longer. The decisions you make now will affect you for the rest of your
                                    life, so there's no better time to start looking after your future.
                                    <br />
                                    <br />
                                    Our new mid-life assessment helps you take stock: to see where you are, where you
                                    want to be and how to get there. It's designed primarily for Phoenix Group
                                    colleagues aged 40 and above and focuses on the key areas of wealth, work and
                                    wellbeing.
                                    <br />
                                    <br />
                                    At the end you get a personalised report which includes tips and support to help you
                                    live better longer.
                                </P>
                            </Col>
                        </Row>
                    </Col>
                    <Col role="region" aria-label="video section" col="12" lg="6">
                        <HomeIntroVideo />
                    </Col>
                </Row>

                <div className="mid-life__button-box">
                    <Row>
                        <Col col="12" lg="6" xl="8">
                            <H4>Take the assessment now and get your personal report</H4>
                            <P>It only takes 15 minutes and will help you look after your future. </P>
                        </Col>
                        <Col col="12" lg="6" xl="4">
                            <Link className="mid-life__btn" to="/start">
                                <span>Start the assessment</span>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </Container>
        </HomeIntroPanelStyle>
    );
};
