/**
 * Styled-components theme for Reassure-UI component design system
 */

import pallette from './pallette';
import forms from './forms';
import page from './page';

const theme = {
    name: 'light',
    breakpoints: ['320px', '375px', '576px', '768px', '992px', '1400px'],
    colors: {
        primaryText: pallette.charcoal,
        secondaryText: pallette.white,
        ...page.colors,
        ...forms.colors,
        ...pallette,
    },
} as const;

export default theme;
