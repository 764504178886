import { Svg, G, Path } from '@react-pdf/renderer';

import { styles } from './results-pdf.styles';

const MySvg: any = Svg;
const MyG: any = G;
const MyPath: any = Path;

export const GrowthIcon = () => (
    <MySvg style={styles.growth_icon} viewBox="0 0 25 24">
        <MyG stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <MyG transform="translate(-152.000000, -639.000000)" fill="#FF455D" fill-rule="nonzero">
                <MyG transform="translate(152.000000, 639.000000)">
                    <MyPath d="M17.7083333,10 L17.7083333,11 L14.2760417,11 L13.234375,12 L15.625,12 L15.625,13 L12.1927083,13 L11.1510417,14 L13.5416667,14 L13.5416667,15 L10.109375,15 L9.06770833,16 L12.5,16 C15.9479167,16 18.75,13.31 18.75,10 L18.75,6.705 L15.3177083,10 L17.7083333,10 Z" />
                    <MyPath d="M12.5,0 L12.5,0 C5.59895833,0 0,5.37 0,12 L0,12 C0,18.625 5.59895833,24 12.5,24 L12.5,24 C19.4010417,24 25,18.63 25,12 L25,12 C25,5.37 19.40625,0 12.5,0 Z M19.7916667,10 C19.7916667,13.86 16.5208333,17 12.5,17 L8.02604167,17 L6.35416667,18.605 L6.09375,18.855 L5.359375,18.15 L5.61979167,17.9 L7.96354167,15.65 L9.375,14.295 L9.375,11 L10.4166667,11 L10.4166667,13.295 L11.4583333,12.295 L11.4583333,9 L12.5,9 L12.5,11.295 L13.5416667,10.295 L13.5416667,7 L14.5833333,7 L14.5833333,9.295 L18.015625,6 L14.5833333,6 C11.1354167,6 8.33333333,8.69 8.33333333,12 L8.33333333,13.5 C8.33333333,14.05 7.86458333,14.5 7.29166667,14.5 L7.29166667,12 C7.29166667,8.14 10.5625,5 14.5833333,5 L19.7916667,5 L19.7916667,10 Z" />
                </MyG>
            </MyG>
        </MyG>
    </MySvg>
);
