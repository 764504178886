import styled from 'styled-components';
import { breakpointDown } from '@reassure/ui';

export const QuestionnaireBackButtonStyles = styled.button`
    cursor: pointer;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
    color: ${({ theme }) => theme.colors.charcoal};
    font-family: 'PhoenixSansMediumWeb';
    font-size: ${({ theme }) => theme.fontSizes[4]}; // 16px
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: 10px;

    ${breakpointDown.sm`
        width: 250px;
    `}

    ${breakpointDown.xs`
        margin-bottom: 20px;
    `}
`;
