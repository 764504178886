import { StyleSheet, Font } from '@react-pdf/renderer';
import PhoenixSansBoldWeb from '../../theme/fonts/PhoenixSans-Bold.ttf';
import PhoenixSansMediumWeb from '../../theme/fonts/PhoenixSans-Medium.ttf';
import PhoenixSansRegularWeb from '../../theme/fonts/PhoenixSans-Regular.ttf';
import PhoenixSerifRegularWeb from '../../theme/fonts/PhoenixSerif-Regular.ttf';

export const styles = StyleSheet.create({
    body: {
        flexDirection: 'row',
        padding: 20,
        boxSizing: 'border-box',
    },

    header: {},

    logo: {
        width: '100px',
        height: '17px',
    },

    resultsTop: {
        margin: '5px 0',
    },

    results_title: {
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 16,
        lineHeight: 1.4,
        marginTop: 10,
        color: '#2E2D2C',
    },

    results_text: {
        paddingTop: 2,
        fontFamily: 'PhoenixSansRegularWeb',
        fontSize: 12,
        textAlign: 'center',
    },

    results_text_score: {
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 16,
        lineHeight: 1.2,
        color: '#2E2D2C',
        margin: 'auto',
        marginTop: 5,
        marginBottom: 5,
        maxWidth: 205,
        paddingTop: 4,
        paddingLeft: 4,
        paddingBottom: 0,
    },

    results_score_breakdown_text: {
        margin: 'auto',
        marginTop: 1,
        color: '#2E2D2C',
        marginBottom: 0,
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 14,
        lineHeight: 1.4,
    },
    results_score_color: {
        color: '#4DA999',
        fontSize: 18,
        fontFamily: 'PhoenixSansBoldWeb',
    },

    resultsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 10,
    },

    results_icon: {
        width: 20,
        height: 20,
        margin: 'auto',
    },

    resultsChartWellbeing: {
        width: '33%',
        padding: 2,
        borderRadius: 4,
        border: '1px solid #a57ef0',
        minHeight: 250,
    },

    resultsChartWorklife: {
        width: '33%',
        padding: 5,
        borderRadius: 4,
        border: '1px solid #8cdae9',
        minHeight: 250,
    },

    resultsChartWealth: {
        width: '33%',
        padding: 5,
        borderRadius: 4,
        border: '1px solid #5fcf8a',
        minHeight: 250,
    },

    results_section_chart: {
        marginTop: 1,
        objectFit: 'cover',
        objectPosition: '25% 25%',
    },

    results_section_wellbeing: {
        padding: 8,
        textAlign: 'center',
    },

    results_section_worklife: {
        padding: 8,
        textAlign: 'center',
    },

    results_section_wealth: {
        padding: 8,
        textAlign: 'center',
    },

    results_h4: {
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 16,
        marginTop: 5,
        marginBottom: 8,
    },

    results_h5: {
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 14,
        marginBottom: 3,
    },

    results_med_text: {
        fontFamily: 'PhoenixSansMediumWeb',
        marginBottom: 2,
    },

    results_bold_text: {
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 13,
        lineHeight: 1.4,
    },
    results_reg_text: {
        fontFamily: 'PhoenixSansRegularWeb',
        fontSize: 11,
        lineHeight: 1.2,
    },
    results_para_reg_text: {
        fontFamily: 'PhoenixSansRegularWeb',
        fontSize: 11,
        lineHeight: 1.2,
    },

    results_para_strong_text: {
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 11,
        lineHeight: 1.2,
    },

    results_overallTitle: {
        color: '#2E2D2C',
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 16,
        lineHeight: 1.4,
    },

    results_overall: {
        width: '100%',
        padding: '10px 0',
        marginBottom: 0,
    },

    top_tips: {
        margin: '4px 0',
    },

    table_container: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 0',
        border: '1px solid #ADAAA6',
        borderBottom: 'none',
    },

    table_container_last: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 0',
        border: '1px solid #ADAAA6',
    },

    top_tips_table: {
        width: '100%',
    },

    top_tips_title_container: {
        padding: 8,
        border: '1px solid #ADAAA6',
        borderBottom: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    checklist_icon: {
        width: 24,
        height: 28,
    },

    link_container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
    },

    link_inner_container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    link_arrow: {
        width: 12,
        height: 7,
        margin: '0 5px 0 2px',
    },

    top_tips_title: {
        marginLeft: 10,
        fontSize: 18,
        fontFamily: 'PhoenixSansRegularWeb',
    },

    top_tips_table_number: {
        fontFamily: 'PhoenixSerifRegularWeb',
        fontSize: 25,
        color: '#FF455D',
        width: '10%',
        textAlign: 'center',
    },

    table_square: {
        borderLeft: '1px solid #ADAAA6',
        width: '10%',
    },

    top_tips_content: {
        width: '80%',
        paddingRight: 20,
    },

    top_tips_text: {
        fontFamily: 'PhoenixSansRegularWeb',
        fontSize: 11,
        marginBottom: '2px',
    },

    top_tips_link: {
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 11,
        lineHeight: 1.2,
        textDecoration: 'none',
        color: '#2E2D2C',
    },
    purple_background: {
        backgroundColor: '#331E38',
        color: '#ffffff',
        fontSize: 12,
        fontFamily: 'PhoenixSansRegularWeb',
        padding: 8,
        textAlign: 'center',
    },

    section: {
        margin: '20px 0 4px',
    },

    section_tips_title: {
        fontSize: 15,
        fontFamily: 'PhoenixSansRegularWeb',
        marginLeft: 10,
        flexWrap: 'wrap',
        display: 'flex',
        width: '80%',
    },

    text_title_containter: {
        marginTop: 20,
        padding: 8,
        border: '1px solid #ADAAA6',
        borderBottom: 'none',
    },

    section_tips_title_container: {
        padding: 8,
        border: '1px solid #ADAAA6',
        borderBottom: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    section_table: {
        width: '100%',
    },

    text_title: {
        fontSize: 16,
        fontFamily: 'PhoenixSansBoldWeb',
    },

    section_tips_text: {
        fontFamily: 'PhoenixSansRegularWeb',
        fontSize: 11,
        width: '90%',
        lineHeight: 1.2,
    },

    section_tips_link_container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
    },

    section_tips_link: {
        fontFamily: 'PhoenixSansBoldWeb',
        fontSize: 11,
        textDecoration: 'none',
        color: '#2E2D2C',
    },
    section_tips_wellbeing_title_container: {
        marginTop: 0,
    },

    section_tips_wellbeing: {
        width: '90%',
        padding: '10px 10px 5px',
    },

    section_tips_worklife: {
        width: '90%',
        padding: '10px 10px 5px',
    },

    section_tips_wealth: {
        width: '90%',
        padding: '10px 10px 5px',
    },

    info_box_container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 30,
    },

    info_box_link: {
        backgroundColor: '#331E38',
        textDecoration: 'none',
        color: 'white',
        borderRadius: 4,
        width: '48%',
        minHeight: 87,
        padding: '20px 10px',
        textAlign: 'center',
    },

    info_box_link_text: {
        color: 'white',
        fontSize: 11,
        fontFamily: 'PhoenixSansRegularWeb',
    },

    info_box_link_text_bold: {
        color: 'white',
        fontSize: 11,
        fontFamily: 'PhoenixSansBoldWeb',
    },

    growth_icon: {
        width: 25,
        height: 24,
        textAlign: 'center',
        margin: '0 auto 5px',
    },

    passion_icon: {
        width: 25,
        height: 24,
        margin: '0 auto 5px',
    },

    answers_question: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 8px',
        width: '100%',
    },

    answers_answer: {
        paddingTop: 5,
        width: '100%',
    },

    answers_title: {
        fontSize: 11,
        width: '100%',
    },

    answers_text: {
        fontSize: 11,
        fontFamily: 'PhoenixSansRegularWeb',
        width: '100%',
    },

    bold: {
        fontFamily: 'PhoenixSansBoldWeb',
    },

    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        top: 0,
        right: 0,
        color: 'grey',
    },
});

Font.register({
    family: 'PhoenixSansBoldWeb',
    fontStyle: 'normal',
    src: PhoenixSansBoldWeb,
});

Font.register({
    family: 'PhoenixSansMediumWeb',
    fontStyle: 'normal',
    src: PhoenixSansMediumWeb,
});

Font.register({
    family: 'PhoenixSansRegularWeb',
    fontStyle: 'normal',
    src: PhoenixSansRegularWeb,
});

Font.register({
    family: 'PhoenixSerifRegularWeb',
    fontStyle: 'normal',
    src: PhoenixSerifRegularWeb,
});
