// components/Layout.js
import { Outlet } from 'react-router-dom';
import { Header } from 'components/header';
import AutomaticLogout from 'components/automatic-logout/automatic-logout';

export const Layout = () => {
    return (
        <>
            <AutomaticLogout />
            <Header />
            <Outlet />
        </>
    );
};
