import styled from 'styled-components';
import { breakpointDown, Container } from '@reassure/ui';

export const RetirementIntranetBackground = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    min-height: calc(100vh - 120px);
    display: flex;

    ${breakpointDown.md`
        min-height: calc( 100vh - 156px);
    `}
`;

export const RURetirementContainer = styled(Container)`
    max-width: 700px;
`;

export const RetirementStyles = styled.div`
    margin: ${({ theme }) => theme.space[9]} auto 0;

    ${breakpointDown.md`
        margin-top: 0;
        overflow-wrap: break-word;  
        
    `}
    h1 {
        line-height: 28px !important;
        font-size: ${({ theme }) => theme.fontSizes[8]}!important;
    }
    .retirement {
        &__outer-list {
            margin: 0;
            padding: 0 0 0 15px;

            p {
                font-size: ${({ theme }) => theme.fontSizes[4]}!important; // 16px
                line-height: ${({ theme }) => theme.fontSizes[7]}!important;
            }
        }

        &__inner-list {
            margin: 0;
            padding-bottom: 15px;

            li {
                padding: 5px 0;
                p {
                    font-size: ${({ theme }) => theme.fontSizes[4]}!important; // 16px
                    line-height: ${({ theme }) => theme.fontSizes[7]}!important;
                }
                a {
                    color: ${({ theme }) => theme.colors.black};
                }
            }
        }
    }
`;
