import { Container, Row, Col, P, Link } from '@reassure/ui';
import { DisclaimerBarStyle } from './disclaimer.styles';

export const DisclaimerBar = () => {
    return (
        <DisclaimerBarStyle>
            <Container>
                <Row>
                    <Col className="disclaimer">
                        <div className="disclaimer__content">
                            <P className="disclaimer__title">Confidentiality</P>
                            <P>This questionnaire is completely confidential and for your use only. See how the data is stored and used in the
                                <Link
                                    href="https://www.thephoenixgroup.com/site-services/privacy-hub"
                                    onClick={function noRefCheck(){}}
                                    target="_blank"
                                    className="disclaimer__link"> privacy policy
                                </Link>
                            </P>
                        </div>
                    </Col>
                </Row>
            </Container> 
        </DisclaimerBarStyle>
    );
};