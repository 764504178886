import { Row, Col, H1, P } from '@reassure/ui';
import { AboutStyles, QuestionContainer } from 'components/styles';
import { Link } from 'react-router-dom';

export const AboutWellbeing = () => {
    return (
        <QuestionContainer>
            <Row>
                <Col>
                    <AboutStyles>
                        <div role="region" aria-label="about wellbeing mid-life mot" className="about">
                            <H1 className="about__title">About your Wellbeing</H1>
                            <P className="about__content">
                                The Wellbeing section of the Mid-life MOT is designed to help you think about what sort
                                of areas of your personal wellbeing - be that physical, social, or mental - you might
                                want support with or that you might choose to make changes to. Answer these confidential
                                questions to find out more.
                            </P>

                            <Link style={{ margin: 'auto' }} className="about__btn" to="/question-one">
                                <span>Continue</span>
                            </Link>
                        </div>
                    </AboutStyles>
                </Col>
            </Row>
        </QuestionContainer>
    );
};
