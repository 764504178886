import styled from 'styled-components';
import { breakpointDown } from '@reassure/ui';

export const ResultsInfoBoxStyle = styled.div`
    button,
    a {
        background-color: ${({ theme }) => theme.colors.aubergine};
        font-size: ${({ theme }) => theme.fontSizes[8]}!important; // 24px;
        line-height: ${({ theme }) => theme.fontSizes[8]}!important; // 24;
        text-align: center;
        border: 1px solid ${({ theme }) => theme.colors.lightgrey};
        border-radius: 4px;
        width: 100%;
        min-height: 212px;
        padding: 35px 30px;
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        gap: 10px;
        text-decoration: none;

        ${breakpointDown.md`
            margin-bottom: 20px;
        `}

        &:hover,
        &:focus {
            color: ${({ theme }) => theme.colors.white};
        }

        .link-content {
            font-family: 'PhoenixSansBoldWeb';
        }
        .link-text {
            margin-top: 20px;
            border-radius: 5px;
            border: 2px solid ${({ theme }) => theme.colors.ember};
            padding: 10px;
            display: flex;
            align-items: center;
        }

        img {
            width: 48px;
            height: 48px;
        }

        .link-arrow {
            height: 18px;
            width: 18px;
            margin-left: 5px;
        }
    }
`;
