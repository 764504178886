import { useCallback, useState } from 'react';
import { P } from '@reassure/ui';
import { InfoBoxStyle, Modal, ModalHeader, ModalBody, ModalCloseButton } from './info-box.styles';

interface InfoBoxProps {
    content?: string;
}

export const InfoBox = ({ content }: InfoBoxProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = useCallback(() => {
        setIsOpen(isOpen => !isOpen);
    }, [setIsOpen]);

    return (
        <>
            <InfoBoxStyle
                onClick={() => {
                    toggleModal();
                }}
            >
                <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="question02" transform="translate(-1255.000000, -337.000000)">
                            <g id="button" transform="translate(1240.000000, 314.000000)">
                                <g id="PG_Icon_Information" transform="translate(15.000000, 23.000000)">
                                    <g id="Clearance"></g>
                                    <g
                                        id="Icons_LIVE"
                                        transform="translate(3.000000, 3.000000)"
                                        fill="#000000"
                                        fillRule="nonzero"
                                    >
                                        <circle id="Oval" cx="9.5" cy="4.5" r="1.5"></circle>
                                        <path
                                            d="M9,0 C4.03714286,0 0,4.03714286 0,9 C0,13.9628571 4.03714286,18 9,18 C13.9628571,18 18,13.9628571 18,9 C18,4.03714286 13.9628571,0 9,0 Z M9,16.7142857 C4.74428571,16.7142857 1.28571429,13.2557143 1.28571429,9 C1.28571429,4.74428571 4.74428571,1.28571429 9,1.28571429 C13.2557143,1.28571429 16.7142857,4.74428571 16.7142857,9 C16.7142857,13.2557143 13.2557143,16.7142857 9,16.7142857 Z"
                                            id="Shape"
                                        ></path>
                                        <path
                                            d="M10.125,12.7272727 L10.125,7 L7,7 C7,7.7 7.5625,8.27272727 8.25,8.27272727 L8.875,8.27272727 L8.875,12.7272727 L7,12.7272727 L7,14 L12,14 L12,12.7272727 L10.125,12.7272727 Z"
                                            id="Path"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <P>Why are we asking this question?</P>
            </InfoBoxStyle>
            <QuestionInfoModal content={content} isOpen={isOpen} toggleModal={toggleModal} />
        </>
    );
};

interface QuestionInfoModalProps {
    isOpen: boolean;
    toggleModal: () => void;
    content?: string;
}

const QuestionInfoModal = ({ content, isOpen, toggleModal }: QuestionInfoModalProps) => {
    return (
        <Modal isOpen={isOpen}>
            <div className="modal-close-container" onClick={toggleModal}>
                <ModalCloseButton />
                <span>Close</span>
            </div>
            <ModalHeader>Why are we asking this question?</ModalHeader>
            <ModalBody>{content}</ModalBody>
        </Modal>
    );
};
