import { H1 } from '@reassure/ui';
import { useLocation } from 'react-router-dom';
import { ProgressBarWealthStyles, ProgressBarLoader, ProgressBarTitleContainer } from './progress-bar-wealth.styles';

export const ProgressBarWealth = () => {
  const location = useLocation();
  return (
    <>
      <ProgressBarTitleContainer>
        <H1 className={location.pathname.substring(1)}>Wealth</H1>
      </ProgressBarTitleContainer>
      <ProgressBarWealthStyles>
        <ProgressBarLoader><span className={location.pathname.substring(1)}/></ProgressBarLoader>    
      </ProgressBarWealthStyles>
    </>
  )
}