import { Svg, G, Path } from '@react-pdf/renderer';

import { styles } from './results-pdf.styles';

const MySvg: any = Svg;
const MyG: any = G;
const MyPath: any = Path;

export const ArrowSvg = () => (
    <MySvg style={styles.link_arrow} viewBox="0 0 18 18">
        <MyG stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <MyG transform="translate(-943.000000, -428.000000)" fill="#2E2D2C" fill-rule="nonzero">
                <MyG transform="translate(730.000000, 335.000000)">
                    <MyG transform="translate(213.000000, 93.000000)">
                        <MyG transform="translate(1.125000, 4.100625)">
                            <MyPath d="M10.850625,0 L10.0575,0.793125 L13.595625,4.336875 L1.125,4.336875 C0.50625,4.336875 0,4.843125 0,5.461875 L13.595625,5.461875 L10.0575,9 L10.850625,9.793125 L15.75,4.899375 L10.850625,0 Z"></MyPath>
                        </MyG>
                    </MyG>
                </MyG>
            </MyG>
        </MyG>
    </MySvg>
);
