// RequireAuth.js
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const RequireAuth = ({ children }: any) => {
    const location = useLocation();
    const { route } = useAuthenticator(context => [context.route]);

    if (route !== 'authenticated' && route !== 'idle') {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
};
