import { Accordion, Table, THead, TBody, TR, TH, TD, H2, P } from '@reassure/ui';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import questions from '../../utils/MOT-questions-answers.json';
import { ResultsAnswersStyle, AccordionSectionContainer } from './results-answers.styles';

dayjs.extend(advancedFormat);
const questionTextArray = questions.data.map(item => ({ label: item.questionText }));

export const ResultsAnswers = ({ answers }: any) => {
    const yourAnswers = [...answers.wellbeingA, ...answers.worklifeA, ...answers.wealthA];

    return (
        <ResultsAnswersStyle>
            <Accordion>
                <AccordionSectionContainer title="Summary of questions and answers">
                    <div className="answers">
                        <Table className="answers__table">
                            <THead>
                                <TR className="answers__header">
                                    <TH colSpan={3}>
                                        <H2>Your answers from {dayjs().format('MMMM Do, YYYY')}</H2>
                                    </TH>
                                </TR>
                            </THead>
                            <TBody>
                                {yourAnswers.map((item: any, index: number) => (
                                    <TR key={index} className="answers__row">
                                        <TD className="answers__question">
                                            <P>
                                                <span className="answers--bold">
                                                    {item?.section.charAt(0).toUpperCase() +
                                                        item?.section.slice(1).toLowerCase()}{' '}
                                                    - Question {index + 1}
                                                </span>
                                                <br />
                                                <span className="answers__question--text">
                                                    {questionTextArray[index].label}
                                                </span>
                                            </P>
                                        </TD>

                                        <TD className="answers__answer">
                                            <P>
                                                <span className="answers--bold">Your answer</span>
                                                <br />
                                                {item?.answerText}
                                            </P>
                                        </TD>
                                    </TR>
                                ))}
                            </TBody>
                        </Table>
                    </div>
                </AccordionSectionContainer>
            </Accordion>
        </ResultsAnswersStyle>
    );
};
