import { H2, P, Link } from '@reassure/ui';
import { TopTip } from 'graphql/generated';
import IconWellbeing from '../../assets/images/icon-wellbeing.svg';
import IconWorklife from '../../assets/images/icon-worklife.svg';
import IconWealth from '../../assets/images/icon-wealth.svg';
import { SectionTipsStyles } from './section-tips.styles';
interface SectionTipsProps {
    wellbeingTips: [TopTip];
    worklifeTips: [TopTip];
    wealthTips: [TopTip];
}

export const SectionTips = ({ wellbeingTips, worklifeTips, wealthTips }: SectionTipsProps) => {
    return (
        <SectionTipsStyles>
            <div className="section-tips">
                <section className="section-tips__section">
                    <H2>
                        We have further suggestions that could help you live better longer. When you're ready, take a
                        look...
                    </H2>

                    <div className="section-tips__sub-title">
                        <div className="section-tips__info-box">
                            <P>
                                Tip - when accessing wellbeing content, use Organisation code {''}
                                <span className="section-tips__bold">'wecare'</span>
                                {''} and access code {''}
                                <span className="section-tips__bold">'BeWell'</span>
                                {''} if prompted
                            </P>
                        </div>
                    </div>

                    {wellbeingTips.length > 0 && (
                        <div className="section-tips__wellbeing">
                            {wellbeingTips.map((item: any, index: number) => (
                                <div key={index} className="section-tips__row">
                                    {index === 0 && (
                                        <header className="section-tips__header">
                                            <div className="section-tips__header-background section-tips__header-background--wellbeing">
                                                <div className="section-tips__icon section-tips__icon--wellbeing">
                                                    <img src={IconWellbeing} alt="icon" />
                                                </div>
                                            </div>
                                            <div className="section-tips__section-name">
                                                {item?.section[0].toUpperCase() + item?.section.substring(1)}
                                            </div>
                                        </header>
                                    )}
                                    <div className="section-tips__content-container">
                                        <div className="section-tips__inner-content">
                                            <P>{item?.text}</P>

                                            <div className="section-tips__link-container">
                                                {item?.link_1_url !== '' && (
                                                    <Link
                                                        href={item?.link_1_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_1}
                                                    </Link>
                                                )}
                                                {item?.link_2_url !== '' && (
                                                    <Link
                                                        href={item?.link_2_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_2}
                                                    </Link>
                                                )}
                                                {item?.link_3_url !== '' && (
                                                    <Link
                                                        href={item?.link_3_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_3}
                                                    </Link>
                                                )}
                                                {item?.link_4_url !== '' && (
                                                    <Link
                                                        href={item?.link_4_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_4}
                                                    </Link>
                                                )}
                                                {item?.link_5_url !== '' && (
                                                    <Link
                                                        href={item?.link_5_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_5}
                                                    </Link>
                                                )}
                                                {item?.link_6_url !== '' && (
                                                    <Link
                                                        href={item?.link_6_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_6}
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                        <div className="section-tips__tick-box"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {worklifeTips.length > 0 && (
                        <div className="section-tips__worklife">
                            {worklifeTips.map((item: any, index: number) => (
                                <div key={index} className="section-tips__row">
                                    {index === 0 && (
                                        <header className="section-tips__header">
                                            <div className="section-tips__header-background section-tips__header-background--worklife">
                                                <div className="section-tips__icon section-tips__icon--worklife">
                                                    <img src={IconWorklife} alt="icon" />
                                                </div>
                                            </div>
                                            <div className="section-tips__section-name">
                                                {item?.section[0].toUpperCase() + item?.section.substring(1)}
                                            </div>
                                        </header>
                                    )}
                                    <div className="section-tips__content-container">
                                        <div className="section-tips__inner-content">
                                            <P>{item?.text}</P>

                                            <div className="section-tips__link-container">
                                                {item?.link_1_url !== '' && (
                                                    <Link
                                                        href={item?.link_1_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_1}
                                                    </Link>
                                                )}
                                                {item?.link_2_url !== '' && (
                                                    <Link
                                                        href={item?.link_2_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_2}
                                                    </Link>
                                                )}
                                                {item?.link_3_url !== '' && (
                                                    <Link
                                                        href={item?.link_3_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_3}
                                                    </Link>
                                                )}
                                                {item?.link_4_url !== '' && (
                                                    <Link
                                                        href={item?.link_4_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_4}
                                                    </Link>
                                                )}
                                                {item?.link_5_url !== '' && (
                                                    <Link
                                                        href={item?.link_5_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_5}
                                                    </Link>
                                                )}
                                                {item?.link_6_url !== '' && (
                                                    <Link
                                                        href={item?.link_6_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_6}
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                        <div className="section-tips__tick-box"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {wealthTips.length > 0 && (
                        <div className="section-tips__wealth">
                            {wealthTips.map((item: any, index: number) => (
                                <div key={index} className="section-tips__row">
                                    {index === 0 && (
                                        <header className="section-tips__header section-tips__header--wealth">
                                            <div className="section-tips__header-background section-tips__header-background--wealth">
                                                <div className="section-tips__icon section-tips__icon--wealth">
                                                    <img src={IconWealth} alt="icon" />
                                                </div>
                                            </div>
                                            <div className="section-tips__section-name">
                                                {item?.section[0].toUpperCase() + item?.section.substring(1)}
                                            </div>
                                        </header>
                                    )}
                                    <div className="section-tips__content-container">
                                        <div className="section-tips__inner-content">
                                            <P>{item?.text}</P>

                                            <div className="section-tips__link-container">
                                                {item?.link_1_url !== '' && (
                                                    <Link
                                                        href={item?.link_1_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_1}
                                                    </Link>
                                                )}
                                                {item?.link_2_url !== '' && (
                                                    <Link
                                                        href={item?.link_2_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_2}
                                                    </Link>
                                                )}
                                                {item?.link_3_url !== '' && (
                                                    <Link
                                                        href={item?.link_3_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_3}
                                                    </Link>
                                                )}
                                                {item?.link_4_url !== '' && (
                                                    <Link
                                                        href={item?.link_4_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_4}
                                                    </Link>
                                                )}
                                                {item?.link_5_url !== '' && (
                                                    <Link
                                                        href={item?.link_5_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_5}
                                                    </Link>
                                                )}
                                                {item?.link_6_url !== '' && (
                                                    <Link
                                                        href={item?.link_6_url}
                                                        target="_blank"
                                                        className="section-tips__link"
                                                    >
                                                        {item?.link_6}
                                                    </Link>
                                                )}
                                            </div>
                                        </div>

                                        <div className="section-tips__tick-box"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </section>
            </div>
        </SectionTipsStyles>
    );
};
