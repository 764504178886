import { P } from '@reassure/ui';
import { HomeIntroVideoStyle } from './home-intro-video.styles';

export const HomeIntroVideo = () => {
    return (
        <HomeIntroVideoStyle>
            <div className="video__container">
                <iframe
                    title="MidLifeMOT"
                    src="https://player.vimeo.com/video/859798087?h=ad97846590&byline=0&portrait=0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                />
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            <P>
                Watch a message from Andy Briggs
                <br />- Chief Executive Officer
            </P>
        </HomeIntroVideoStyle>
    );
};
