import { Row, Col } from '@reassure/ui';
import { FieldWrapper, RadioFieldGroup } from 'components/form';
import { QuestionnaireBackButton } from 'components/questionnaire-back-button';
import { InfoBox } from 'components/info-box';

import {
    QuestionContainer,
    QuestionContainerWhite,
    QuestionnaireSubmitButtonStyles,
    QuestionNumber,
} from '../../styles';

import { useFormContext } from 'react-hook-form';

import questions from '../../../utils/MOT-questions-answers.json';
import popupTextData from '../../../utils/midlife-mot-info-pop-up-text.json';

export const QuestionEighteen = () => {
    const { data } = popupTextData;
    const popupText = data.find(item => item.question === 'wealth_question7');

    const { data: questionsData } = questions;
    const question = questionsData.find(item => item.question === '18');

    const options = questionsData.map((item: any) => {
        const options = [];
        for (let i = 1; i <= 5; i++) {
            const label = item[`answerValue${i}`];
            const value = item[`answerLabel${i}`];
            if (label && value) {
                options.push({ label, value });
            }
        }
        return options.reverse();
    });

    const {
        // control,
        formState: { dirtyFields, isDirty, isSubmitted },
    } = useFormContext();
    return (
        <>
            <QuestionContainer>
                <Row>
                    <Col>
                        <QuestionNumber>Question 18 of 18</QuestionNumber>
                    </Col>
                </Row>
            </QuestionContainer>
            <QuestionContainerWhite>
                <QuestionContainer>
                    <Row>
                        <Col>
                            <FieldWrapper label={question!.questionText}>
                                <InfoBox content={popupText!.QuestionInfoButtonText} />
                                <RadioFieldGroup stacked name="wealth7" options={options[17]} />
                            </FieldWrapper>

                            <Row style={{ marginTop: 25, justifyContent: 'space-between' }}>
                                <QuestionnaireBackButton route={'/question-seventeen'} />

                                <QuestionnaireSubmitButtonStyles>
                                    <button
                                        className={
                                            'submit-btn' +
                                            (!dirtyFields.wealth7 ? ' isDisabled ' : '') +
                                            (isSubmitted ? ' submit-btn__submitting' : '')
                                        }
                                        disabled={!isDirty || isSubmitted}
                                        type="submit"
                                    >
                                        <span>See the results</span>
                                    </button>
                                </QuestionnaireSubmitButtonStyles>
                            </Row>
                        </Col>
                    </Row>
                </QuestionContainer>
            </QuestionContainerWhite>
        </>
    );
};
