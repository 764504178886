import { H1 } from '@reassure/ui';
import { useLocation } from 'react-router-dom';
import {
    ProgressBarLoader,
    ProgressBarTitleContainer,
    ProgressBarWorklifeStyles,
} from './progress-bar-worklife.styles';

export const ProgressBarWorklife = () => {
    const location = useLocation();
    return (
        <>
            <ProgressBarTitleContainer>
                <H1 className={location.pathname.substring(1)}>Work-life</H1>
            </ProgressBarTitleContainer>
            <ProgressBarWorklifeStyles>
                <ProgressBarLoader>
                    <span className={location.pathname.substring(1)} />
                </ProgressBarLoader>
            </ProgressBarWorklifeStyles>
        </>
    );
};
