import { useState } from 'react';
import { Container, Row, P } from '@reassure/ui';
import Star from './star';

type RatingStarsProps = {
    label: 'overallExperienceRating' | 'howEasyRating';
    register: any;
    textOne: string;
    textTwo: string;
};

const RatingStars = ({ register, label, textOne, textTwo }: RatingStarsProps) => {
    const [gradeIndex, setGradeIndex] = useState(-1);
    const grades = ['Poor', 'Fair', 'Good', 'Very good', 'Excellent'];

    const activeStar = {
        fill: '#FF455D',
    };

    const handleChangeGrade = (index: number) => {
        setGradeIndex(index);
        // Update the form value with the selected rating (index + 1)
        register(label, { value: index + 1 });
    };

    return (
        <Container>
            <Row className="results-feedback-row">
                <P>{textOne}</P>
                <div className="result-feedback-stars">
                    {grades.map((grade, index) => (
                        <Star
                            label={label}
                            index={index}
                            key={grade}
                            style={gradeIndex >= index ? activeStar : {}}
                            handleChangeGrade={handleChangeGrade}
                        />
                    ))}
                </div>
                <P>{textTwo}</P>
            </Row>
        </Container>
    );
};

export default RatingStars;
