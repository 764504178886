import { Path } from 'react-hook-form';

interface IFormValues {
    overallExperienceRating?: number;
    howEasyRating?: number;
}

type StarProps = {
    index: number;
    label: Path<IFormValues>;
    style: React.CSSProperties;
    handleChangeGrade: (index: number) => void;
};

const Star = ({ index, label, style, handleChangeGrade }: StarProps) => {
    const changeGrade = (e: any) => {
        handleChangeGrade(Number(e.target.value));
    };
    return (
        <label className="results-feedback-star">
            <input
                onChange={changeGrade}
                type="radio"
                name={label}
                id={`star-${index}`}
                value={index}
                className="stars_radio-input"
            />

            <svg width="30" height="29" viewBox="0 0 48 48">
                <path
                    className="star-path"
                    d="M24,36.5l-9.3,5.6c-0.3,0.2-0.5,0.2-0.8,0.2s-0.6-0.1-0.8-0.3c-0.2-0.2-0.4-0.4-0.5-0.6
                c-0.1-0.3-0.1-0.6-0.1-0.9l2.5-10.6l-8.2-7.1c-0.3-0.2-0.4-0.5-0.5-0.8c0-0.3,0-0.6,0-0.8c0.1-0.3,0.2-0.5,0.5-0.7s0.5-0.3,0.8-0.3l10.8-0.9l4.2-10c0.1-0.3,0.3-0.5,0.6-0.7c0.3-0.2,0.5-0.2,0.8-0.2c0.3,0,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.4,0.6,0.7l4.2,10l10.9,0.9c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.1,0.5,0,0.8s-0.2,0.5-0.5,0.8l-8.2,7.1l2.4,10.6c0.1,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.3,0.5-0.5,0.6c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.1-0.9-0.2L24,36.5z"
                    style={style}
                />
            </svg>
        </label>
    );
};

export default Star;
