import { Row, Col, H1, P, Link } from '@reassure/ui';

import { RUHelpContainer, HelpStyles, HelpBackground } from './help.styles';

export const Help = () => {
    return (
        <HelpBackground>
            <RUHelpContainer>
                <Row>
                    <Col>
                        <HelpStyles>
                            <div role="region" aria-label="help mid-life mot" className="help">
                                <H1 className="help__title">
                                    If you need further support or someone to talk to there's a variety of Wellbeing
                                    resources available{' '}
                                </H1>
                                <ul className="help__outer-list">
                                    <li>
                                        <P>
                                            Our Employee Assistance Programme Confidential Helpline 0800 316 9337 (UK)
                                            and 1800 55 88 99 (Ireland) is available 24 hours a day, 365 days a year or
                                            click on the link below to access the Wellbeing Homepage
                                        </P>
                                        <ul className="help__inner-list">
                                            <li>
                                                <P>
                                                    <Link
                                                        href="https://phoenixgroup365.sharepoint.com/sites/OurPlace-Wellbeing?OR=Teams-HL&CT=1662561931111&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjA3MzEwMTAwNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
                                                        target="blank"
                                                    >
                                                        Wellbeing Homepage
                                                    </Link>
                                                </P>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <P>
                                            You can speak to one of our qualified Mental Health First Aiders (MHFA) who
                                            are here to help by contacting them via email
                                        </P>
                                        <ul className="help__inner-list">
                                            <li>
                                                <P>
                                                    <Link
                                                        target="_blank"
                                                        href="https://phoenixgroup365.sharepoint.com/sites/OurPlace-Wellbeing/SitePages/Mental-Health-First-Aiders-(MHFA).aspx"
                                                    >
                                                        Mental Health First Aiders (MHFA) (sharepoint.com)
                                                    </Link>
                                                </P>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <P>
                                            Our charity partner Samaritans service is available 24 hours a day, 365 days
                                            a year, you can call them on 116 123 (Freephone)
                                        </P>
                                    </li>
                                    <li>
                                        <P>
                                            You can contact HR People Advice Team on ext. 51000 (0131245 1000 if calling
                                            externally).
                                        </P>
                                    </li>
                                    <li>
                                        <P>
                                            Our colleague-led networks are always available to support colleagues, find
                                            out more about them here
                                        </P>
                                        <ul className="help__inner-list">
                                            <li>
                                                <P>
                                                    <Link
                                                        href="https://phoenixgroup365.sharepoint.com/sites/OurPlace-DEI/SitePages/Colleague-Networks.aspx"
                                                        target="_blank"
                                                    >
                                                        Colleagues networks
                                                    </Link>
                                                </P>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </HelpStyles>
                    </Col>
                </Row>
            </RUHelpContainer>
        </HelpBackground>
    );
};
