import { breakpointDown } from '@reassure/ui';
import styled from 'styled-components';
import BackgroundWellbeing from '../../assets/images/background-wellbeing.svg';
import BackgroundWorklife from '../../assets/images/background-worklife.svg';
import BackgroundWealth from '../../assets/images/background-wealth.svg';

export const TopTipsStyles = styled.div`
    padding: 40px 0 10px;
    .top-tips {
        img {
            width: 21px;
            height: 21px;
            display: block;
        }
        &__section {
            &__header {
                padding: 0px 8px !important;
                color: ${({ theme }) => theme.colors.charcoal};
            }

            h2 {
                margin: 0 0 20px;
                font-family: 'PhoenixSansBoldWeb';
                font-size: 28px;
                line-height: ${({ theme }) => theme.sizes[7]}!important; // 32px
                display: flex;
                align-items: center;
                gap: 15px;

                ${breakpointDown.md`
                    text-align: left;
                `}
            }
        }

        &__card {
            display: flex;
            position: relative;
            margin-bottom: 15px;
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.07);
            ${breakpointDown.md`
              padding: 20px 0;
            `};
        }

        &__card-section {
            padding: 20px;
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;
            min-width: 152px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right;

            &--wellbeing {
                background-image: url(${BackgroundWellbeing});
            }

            &--worklife {
                background-image: url(${BackgroundWorklife});
            }

            &--wealth {
                background-image: url(${BackgroundWealth});
            }
        }

        &__number {
            font-family: 'PhoenixSansBoldWeb';
            line-height: 52px;
            font-size: 25px;
            text-align: center;
            color: ${({ theme }) => theme.colors.charcoal};

            ${breakpointDown.md`
               width: 15%;
               min-width: 75px;
            `};
        }

        &__icon {
            background-color: ${({ theme }) => theme.colors.white};
            border-radius: 100%;
            padding: 10px;

            &--wellbeing {
                border: 4px solid ${({ theme }) => theme.colors.purple};
            }

            &--worklife {
                border: 4px solid ${({ theme }) => theme.colors.teal};
            }

            &--wealth {
                border: 4px solid ${({ theme }) => theme.colors.green};
            }
        }

        &__content {
            padding: 20px;
            padding-right: 50px;

            ${breakpointDown.md`
                padding: 20px 20px 20px 0;
                max-width: 300px;
            `};

            p {
                color: ${({ theme }) => theme.colors.charcoal};
                font-size: 16px !important;
                line-height: 22px !important;

                ${breakpointDown.sm`
                    font-size: 18px !important;
                `}
            }
        }

        &__tick-box {
            position: absolute;
            bottom: 20px;
            right: 20px;
            border-radius: 2px;
            height: 25px;
            width: 25px;
            border: 2px solid ${({ theme }) => theme.colors.lightgrey};
        }

        &__link-container {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
        }

        &__link {
            color: ${({ theme }) => theme.colors.charcoal};
            overflow-wrap: break-word;
            margin-bottom: 5px;
            display: inline;
            width: fit-content;
            font-family: 'PhoenixSansRegularWeb';
            text-decoration: underline;
            -webkit-text-decoration-color: ${({ theme }) => theme.colors.ember}; /* Safari */
            text-decoration-color: ${({ theme }) => theme.colors.ember};
        }

        &__square {
            width: 10%;
            border: 1px solid ${({ theme }) => theme.colors.lightgrey};

            ${breakpointDown.md`
                display: none;
            `};
        }
    }
`;
