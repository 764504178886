import styled, { keyframes } from 'styled-components';
import { breakpointDown } from '@reassure/ui';
import CalendarIcon from '../../assets/images/icon-calander.svg';

const SpinAnimation = keyframes`
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
`;

export const ReminderCardStyles = styled.div`
    .reminder {
        background: radial-gradient(461.23% 197.89% at 108.33% 56.59%, #fdf4ec 2.03%, #f6d7c7 28.62%, #f0ad93 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 30px 0;
        margin-bottom: 30px;

        ${breakpointDown.md`
            padding: 30px 20px;
        `}

        &__paragraph {
            margin-bottom: 40px;
            max-width: 500px;

            ${breakpointDown.md`
                max-width: 100%;
                text-align: center;
            `}
        }

        &__btn {
            width: 188px;
            display: flex;
            align-items: center;
            background-color: transparent;
            cursor: pointer;
            text-decoration: none;
            gap: 14px;
            padding: 6px 12px;
            border: 2px solid ${({ theme }) => theme.colors.ember};
            font-family: 'PhoenixSansMediumWeb';
            color: ${({ theme }) => theme.colors.charcoal};
            transition: border-bottom-right-radius 180ms ease-in-out;

            &:first-child {
                margin-bottom: 10px;
            }

            &:focus,
            &:hover {
                border-bottom-right-radius: 22px;
            }

            &:before {
                content: '';
                display: inline-block;
                background: url(${CalendarIcon}) no-repeat center center;
                height: 32px;
                width: 32px;
            }

            &--downloading {
                opacity: 0.6;
                pointer-events: none;
                padding: 10px 12px;

                &::before {
                    background: none;
                    opacity: 1;
                    content: '\f021';
                    font-family: 'Font Awesome 6 Free';
                    animation: ${SpinAnimation} 1s infinite linear;
                    font-weight: 900;
                    height: auto;
                    width: auto;
                }
            }
        }
    }
`;
