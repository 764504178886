import styled from 'styled-components';
import { breakpointDown, AccordionSection } from '@reassure/ui';
export const ResultsAnswersStyle = styled.div`
    .answers {
        &--bold {
            font-family: 'PhoenixSansBoldWeb';
        }
        &__table {
            background-color: ${({ theme }) => theme.colors.grey};
            border-collapse: collapse;
            margin: 0;
            border: 1px solid ${({ theme }) => theme.colors.lightgrey};
            border-left: none;
            border-right: none;
            border-bottom: none;
            width: 100%;
            th {
                background-color: transparent;

                ${breakpointDown.md`
                    border-bottom: 1px solid ${({ theme }: any) => theme.colors.lightgrey};
                `}
            }
            &__header {
                padding: 0px 8px;
                color: ${({ theme }) => theme.colors.charcoal};
                border: 1px solid ${({ theme }) => theme.colors.lightgrey};
            }

            h2 {
                margin: 20px 20px 15px;
                font-family: 'PhoenixSansBoldWeb';
                font-size: 28px;
                line-height: ${({ theme }) => theme.sizes[7]}!important; // 32px
                display: flex;
                align-items: center;
                gap: 15px;

                ${breakpointDown.md`
                    text-align: left;
                `}
            }
        }

        &__row {
            border: 1px solid ${({ theme }) => theme.colors.lightgrey};
            border-left: none;
            border-right: none;
            border-bottom: none;
            background-color: transparent !important;
            ${breakpointDown.md`
              padding: 20px 0;
            `};

            ${breakpointDown.sm`
                display: flex;
                flex-direction: column;
                border: none;
                padding: 0;
            `}
        }

        &__question {
            width: 50%;
            vertical-align: top;
            border: 1px solid ${({ theme }) => theme.colors.lightgrey};
            border-left: none;
            border-bottom: none;
            padding: 20px;

            ${breakpointDown.sm`
               width: 100%;
               border: none;
            `};

            p {
                color: ${({ theme }) => theme.colors.charcoal};
                line-height: ${({ theme }) => theme.sizes[6]}!important; // 24px;
                font-size: ${({ theme }) => theme.fontSizes[5]}!important; //24px;
            }
        }

        &__answer {
            width: 50%;
            vertical-align: top;
            padding: 20px;

            ${breakpointDown.md`
                width: 100%;
                border-bottom: 1px solid ${({ theme }: any) => theme.colors.lightgrey};
            `};

            ${breakpointDown.sm`
                padding: 0 20px 20px;
            `}

            p {
                color: ${({ theme }) => theme.colors.charcoal};
                line-height: ${({ theme }) => theme.sizes[6]}!important; // 24px;
                font-size: ${({ theme }) => theme.fontSizes[5]}!important; //24px;
            }
        }
    }
`;

export const AccordionSectionContainer = styled(AccordionSection)`
    background-color: ${({ theme }) => theme.colors.grey};
    border: 1px solid ${({ theme }) => theme.colors.aubergine};
    margin: 20px 0 0;
    border-radius: ${({ theme }) => theme.borderRadius.medium};

    h4 {
        font-size: 24px;
        margin-left: 40px;
    }

    div.accordion__icon {
        background-color: transparent;
        border: 2px solid ${({ theme }) => theme.colors.charcoal};
        top: 25px;
        left: 17px;
        right: 0;

        :focus {
            box-shadow: 0 0 0 0.2rem rgb(100 27 36 / 25%);
        }
    }

    span.accordion__icon__symbol-line {
        height: 2px;
    }
    span.accordion__icon__symbol-line--two {
        background-color: ${({ theme }) => theme.colors.charcoal};
    }
    span.accordion__icon__symbol-line--one {
        background-color: ${({ theme }) => theme.colors.charcoal};
    }
    div.accordion__content {
        overflow: scroll;
    }
    div.accordion__content > div {
        padding: 0px !important;
    }
`;
