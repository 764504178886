export function splitStringByDelimiters(str: string) {
    const delimiters = ['.', '!', ','];
    const splits = [];

    let currentSplit = '';
    let foundDelimiter = false;

    for (let i = 0; i < str.length; i++) {
        const char = str[i];

        if (delimiters.includes(char)) {
            foundDelimiter = true;
            currentSplit += char;
        } else {
            if (foundDelimiter) {
                splits.push(currentSplit.trim());
                currentSplit = '';
                foundDelimiter = false;
            }
            currentSplit += char;
        }
    }

    if (currentSplit) {
        splits.push(currentSplit.trim());
    }

    return splits;
}
