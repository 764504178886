import { Label, breakpointUp, breakpointDown } from '@reassure/ui';
import styled from 'styled-components';
import ApprovalIcon from '../../../assets/images/approval-icon.svg';

export const FieldLabelContainer = styled.div`
    ${breakpointUp.sm`
         label {
            margin: 0;
            margin-top: 8px;
            margin-bottom: 35px;
            &:not([for]) {
                margin-top: 0;
            }
         }
    `};

    label {
        color: ${({ theme }) => theme.colors.charcoal};
        font-size: ${({ theme }) => theme.fontSizes[11]}; // 36px
        line-height: ${({ theme }) => theme.fontSizes[12]};

        ${breakpointDown.md`
             font-size: ${({ theme }: any) => theme.fontSizes[8]};
             line-height: 27px;
        `};
    }
`;

export const FieldLabel = styled(Label)``;

export const RadioFieldContainer = styled.div`
    .input-container {
        display: inline-flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
        width: 100%;
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.lightgrey};
    }
    label,
    input {
        font-family: 'PhoenixSansMediumWeb';
        font-size: ${({ theme }) => theme.fontSizes[6]}!important; // 20px
        line-height: 36px;
        cursor: pointer;
    }
    /* Answer Label */
    label {
        width: 100%;
        /* border: 1px solid red; */
        margin-bottom: 0;
        padding: 10px;
    }

    input {
        margin: 0;
        margin-top: 2px;
    }

    input:where([type='radio']) {
        -webkit-appearance: none;
        appearance: none;
        width: 22px;
        height: 22px;
        padding: 15px;
        margin: calc(0.75em - 11px) 0.25rem 0 0;
        vertical-align: top;
        border: 2px solid ${({ theme }) => theme.colors.lightgrey};
        border-radius: 4px;
        background: #fff no-repeat center center;
    }

    input:where([type='radio']):where(:active:not(:disabled), :focus) {
        border-color: ${({ theme }) => theme.colors.lightgrey};
        outline: none;
    }
    input:where([type='radio']):disabled {
        background: #eee;
    }
    input[type='radio']:checked {
        background-image: url(${ApprovalIcon});
    }

    .input-container:focus-within {
        background: ${({ theme }) => theme.colors.lightcoral};
    }
`;

export const RadioFieldsContainer = styled.div<{ stacked?: boolean }>`
    display: inline-flex;
    flex-wrap: wrap;
    gap: 6px;
    flex-direction: ${({ stacked }) => (stacked ? 'column' : 'row')};
    width: 100%;

    div {
        padding: 0 10px;
        border-radius: 8px;
    }
`;

export const CheckboxFieldContainer = styled.div`
    display: inline-flex;
    align-items: flex-start;
    gap: 4px;
    label,
    input {
        cursor: pointer;
    }
    label {
        margin-bottom: 0;
    }
    input {
        margin: 0;
        margin-top: 2px;
    }
`;
