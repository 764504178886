import { Row, Col } from '@reassure/ui';
import { FieldWrapper, RadioFieldGroup } from 'components/form';
import { QuestionnaireBackButton } from 'components/questionnaire-back-button';
import { QuestionnaireNextButton } from 'components/questionnaire-next-button';
import { InfoBox } from 'components/info-box';

import { QuestionContainer, QuestionContainerWhite, QuestionNumber } from '../../styles';
import { useFormContext } from 'react-hook-form';

import questions from '../../../utils/MOT-questions-answers.json';
import popupTextData from '../../../utils/midlife-mot-info-pop-up-text.json';

export const QuestionSix = () => {
    const { data } = popupTextData;
    const popupText = data.find(item => item.question === 'wellbeing_question6');

    const { data: questionsData } = questions;
    const question = questionsData.find(item => item.question === '6');

    const options = questionsData.map((item: any) => {
        const options = [];
        for (let i = 1; i <= 5; i++) {
            const label = item[`answerValue${i}`];
            const value = item[`answerLabel${i}`];
            if (label && value) {
                options.push({ label, value });
            }
        }
        return options.reverse();
    });

    const {
        control,
        formState: { dirtyFields },
    } = useFormContext();

    return (
        <>
            <QuestionContainer>
                <Row>
                    <Col>
                        <QuestionNumber>Question 6 of 18</QuestionNumber>
                    </Col>
                </Row>
            </QuestionContainer>
            <QuestionContainerWhite>
                <QuestionContainer>
                    <Row>
                        <Col>
                            <FieldWrapper label={question!.questionText}>
                                <InfoBox content={popupText!.QuestionInfoButtonText} />
                                <RadioFieldGroup stacked name="wellbeing6" options={options[5]} />
                            </FieldWrapper>

                            <Row style={{ marginTop: 25, justifyContent: 'space-between' }}>
                                <QuestionnaireBackButton route={'/question-five'} />
                                <QuestionnaireNextButton
                                    defaultValue={control._defaultValues.wellbeing6}
                                    dirtyFields={dirtyFields.wellbeing6}
                                    route={'/about-worklife'}
                                />
                            </Row>
                        </Col>
                    </Row>
                </QuestionContainer>
            </QuestionContainerWhite>
        </>
    );
};
