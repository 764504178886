/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://n43bbk7z55dl7oa3xhybgnp5eu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6oextbapefgznnyidwp32tit3q",
    "aws_cognito_identity_pool_id": "eu-west-1:3e61270a-16a0-4b34-80fe-a2f140a785bb",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_HtGsFOoWD",
    "aws_user_pools_web_client_id": "4tbth48sqj09jthdhv6g3vdgm0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "pmmwebapp-20220725120421-hostingbucket-uat",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3me7ykfevnxkc.cloudfront.net"
};


export default awsmobile;
