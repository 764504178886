import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

import { RequireAuth } from './components/require-auth';
import { Layout } from './components/layout';
import { LayoutWithProvider } from 'components/layout-with-provider';

import { Login } from './components/login';
import { Home } from './components/pages/home';
import { Start } from 'components/pages/start';
import { QuestionOne } from 'components/pages/question-one';
import { QuestionTwo } from 'components/pages/question-two';
import { QuestionThree } from 'components/pages/question-three';
import { QuestionFour } from 'components/pages/question-four';
import { QuestionFive } from 'components/pages/question-five';
import { QuestionSix } from 'components/pages/question-six';
import { QuestionSeven } from 'components/pages/question-seven';
import { QuestionEight } from 'components/pages/question-eight';
import { QuestionNine } from 'components/pages/question-nine';
import { QuestionTen } from 'components/pages/question-ten';
import { QuestionEleven } from 'components/pages/question-eleven';
import { QuestionTwelve } from 'components/pages/question-twelve';
import { QuestionThirteen } from 'components/pages/question-thirteen';
import { QuestionFourteen } from 'components/pages/question-fourteen';
import { QuestionFifteen } from 'components/pages/question-fifteen';
import { QuestionSixteen } from 'components/pages/question-sixteen';
import { QuestionSeventeen } from 'components/pages/question-seventeen';
import { QuestionEighteen } from 'components/pages/question-eighteen';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import { AboutWellbeing } from 'components/pages/about-wellbeing/about-wellbeing';
import { AboutWorklife } from 'components/pages/about-worklife';
import { AboutWealth } from 'components/pages/about-wealth';
import { Results } from 'components/pages/results';
import { Help } from 'components/pages/help/help';
import { RetirementIntranet } from 'components/pages/retirement-intranet';

const MyRoutes = () => {
    const { route } = useAuthenticator(context => [context.route]);

    useEffect(() => {
        const federatedSignIn = async () => {
            if (process.env.REACT_APP_AUTH_DOMAIN !== '') {
                await Auth.federatedSignIn({ customProvider: 'AzureAD' });
            }
        };
        if (route !== 'authenticated' && route !== 'idle') {
            federatedSignIn();
        }
    }, [route]);
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LayoutWithProvider />}>
                        <Route
                            path="/about-wellbeing"
                            element={
                                <RequireAuth>
                                    <AboutWellbeing />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-one"
                            element={
                                <RequireAuth>
                                    <QuestionOne />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-two"
                            element={
                                <RequireAuth>
                                    <QuestionTwo />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-three"
                            element={
                                <RequireAuth>
                                    <QuestionThree />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-four"
                            element={
                                <RequireAuth>
                                    <QuestionFour />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-five"
                            element={
                                <RequireAuth>
                                    <QuestionFive />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-six"
                            element={
                                <RequireAuth>
                                    <QuestionSix />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/about-worklife"
                            element={
                                <RequireAuth>
                                    <AboutWorklife />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-seven"
                            element={
                                <RequireAuth>
                                    <QuestionSeven />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-eight"
                            element={
                                <RequireAuth>
                                    <QuestionEight />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-nine"
                            element={
                                <RequireAuth>
                                    <QuestionNine />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-ten"
                            element={
                                <RequireAuth>
                                    <QuestionTen />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-eleven"
                            element={
                                <RequireAuth>
                                    <QuestionEleven />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-twelve"
                            element={
                                <RequireAuth>
                                    <QuestionTwelve />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/about-wealth"
                            element={
                                <RequireAuth>
                                    <AboutWealth />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-thirteen"
                            element={
                                <RequireAuth>
                                    <QuestionThirteen />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-fourteen"
                            element={
                                <RequireAuth>
                                    <QuestionFourteen />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-fifteen"
                            element={
                                <RequireAuth>
                                    <QuestionFifteen />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-sixteen"
                            element={
                                <RequireAuth>
                                    <QuestionSixteen />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-seventeen"
                            element={
                                <RequireAuth>
                                    <QuestionSeventeen />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/question-eighteen"
                            element={
                                <RequireAuth>
                                    <QuestionEighteen />
                                </RequireAuth>
                            }
                        />
                    </Route>
                    <Route path="/" element={<Layout />}>
                        <Route
                            index
                            element={
                                <RequireAuth>
                                    <Home />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/start"
                            element={
                                <RequireAuth>
                                    <Start />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/results"
                            element={
                                <RequireAuth>
                                    <Results />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/help"
                            element={
                                <RequireAuth>
                                    <Help />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/further-information"
                            element={
                                <RequireAuth>
                                    <RetirementIntranet />
                                </RequireAuth>
                            }
                        />

                        <Route path="/login" element={<Login />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
};

export const App = () => (
    <Authenticator.Provider>
        <MyRoutes />
    </Authenticator.Provider>
);
