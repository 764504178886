import { Row, Col, H1, P } from '@reassure/ui';
import { Link } from 'react-router-dom';

import { StartStyle, StartContainer } from './start.styles';

import { useUpdateSessionEventLazyQuery } from 'graphql/generated';
export const Start = () => {
    const [callUpdateEvent] = useUpdateSessionEventLazyQuery({
        variables: { input: { sessionID: localStorage.getItem('sessionID'), sessionEvent: 'startedMOTAt' } },
    });

    const onClickUpdateEvent = () => {
        callUpdateEvent();
    };

    return (
        <StartContainer>
            <Row>
                <Col>
                    <StartStyle>
                        <div role="region" aria-label="start mid-life mot" className="start">
                            <H1 className="start__title">Mid-life MOT</H1>
                            <P className="start__content">
                                There are 18 questions to answer. <br />
                                This should take around 10-15 mins.
                            </P>
                            <Link onClick={() => onClickUpdateEvent()} className="start__btn" to="/about-wellbeing">
                                <span>Get started</span>
                            </Link>

                            <P className="start__content start__content--small">
                                The assessment is completely voluntary, anonymous and for your use only. Your answers
                                will only be used to provide relevant tools and resources, and all information entered
                                will be deleted upon exit of the assessment. It provides a holistic view across
                                wellbeing, wealth, and work-life. Our Place can be used to find information if you are
                                only interested in specific subjects.
                            </P>
                        </div>
                    </StartStyle>
                </Col>
            </Row>
        </StartContainer>
    );
};
