import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
const ics = require('ics');

const todaysDate = dayjs();
const startdate = todaysDate.add(182, 'day');
const year = dayjs(startdate).year();
const month = dayjs(startdate).month() + 1;
const day = dayjs(startdate).date();

let alarms = [];
alarms.push({
    action: 'display',
    description: 'Reminder',
    trigger: { minutes: 15, before: true },
});

const event = {
    title: 'Mid-life MOT Reminder',
    description: `This is a reminder for you to retake the Mid-life MOT in 6 months time you can retake it here - ${process.env.REACT_APP_DOMAIN_NAME}`,
    start: [year, month, day, 9, 0],
    duration: { minutes: 15 },
    alarms: alarms,
    location: 'Online',
};

export const downloadCalendar = () => {
    ics.createEvent(event, (error: any, value: any) => {
        if (error) {
            console.log(error);
        }

        const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
        saveAs(blob, 'reminderMOT.ics');
    });
};
