import { H2, P } from '@reassure/ui';
import { ReminderCardStyles } from './reminder-card.styles';
import IconReminder from '../../assets/images/icon-reminder.svg';
import { downloadCalendar } from '../pages/results/event';

import { useSetLinks } from '../../services/hooks';

interface reminderCardProps {
    onClickUpdateEvent: (value: string) => void;
}

export const ReminderCard = ({ onClickUpdateEvent }: reminderCardProps) => {
    const { downloadingEventLink1, downloadEventTopLink1 } = useSetLinks();

    return (
        <ReminderCardStyles>
            <div className="reminder">
                <img src={IconReminder} alt="reminder icon" />
                <H2 className="reminder__title">Set a reminder for the future</H2>
                <P className="reminder__paragraph">
                    Ensure you check back in the future by setting a reminder.
                    <br />
                    Simply click the button below to add it directly to your calendar.
                </P>

                <button
                    type="button"
                    className={`reminder__btn ${
                        downloadingEventLink1 ? ' reminder__btn--downloading' : 'reminder__btn--calendar'
                    }`}
                    onClick={() => {
                        downloadCalendar();
                        downloadEventTopLink1();
                        onClickUpdateEvent('reminderAt');
                    }}
                >
                    Set reminder
                </button>
            </div>
        </ReminderCardStyles>
    );
};
