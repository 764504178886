import { useNavigate } from 'react-router-dom';
import { QuestionnaireBackButtonStyles } from './questionnaire-back-button.styles';

interface QuestionnaireBackButtonProps {
    route: string;
}

export const QuestionnaireBackButton = ({ route }: QuestionnaireBackButtonProps) => {
    const navigate = useNavigate();

    return (
        <QuestionnaireBackButtonStyles type="button" onClick={() => navigate(route)}>
            <svg
                width="18px"
                height="18px"
                viewBox="0 0 18 18"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g
                        id="question02"
                        transform="translate(-210.000000, -737.000000)"
                        fill="#2E2D2C"
                        fillRule="nonzero"
                    >
                        <g id="Back" transform="translate(210.000000, 725.000000)">
                            <g
                                id="PG_Icon_back"
                                transform="translate(9.000000, 21.000000) rotate(180.000000) translate(-9.000000, -21.000000) translate(0.000000, 12.000000)"
                            >
                                <g id="Icons_LIVE" transform="translate(1.125000, 4.100625)">
                                    <path
                                        d="M10.850625,0 L10.0575,0.793125 L13.595625,4.336875 L1.125,4.336875 C0.50625,4.336875 0,4.843125 0,5.461875 L13.595625,5.461875 L10.0575,9 L10.850625,9.793125 L15.75,4.899375 L10.850625,0 Z"
                                        id="Path"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <span>Back</span>
        </QuestionnaireBackButtonStyles>
    );
};
