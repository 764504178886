import styled from 'styled-components';

export const HomeContainer = styled.div`
    padding: ${({ theme }) => theme.space[4]} 0;
    p {
        line-height: 1.5;
        font-size: 1em;
        margin-bottom: 0;
    }
    button {
        display: block;
        border: none;
        background-color: ${({ theme }) => theme.colors.blue};
        max-width: 150px;
        margin-left: auto;
        :hover, :active, :focus {
            background-color: ${({ theme }) => theme.colors.lightgrey};
            border: none;
        }
    }
`;
