import { breakpointDown} from '@reassure/ui';
import styled from 'styled-components';

export const HomeIntroCategoriesStyle = styled.div`
    padding: 40px 0 120px;
    background-color: ${({ theme }) => theme.colors.white};

    .mid-life {
        &__intro-image {
            display: block;
            margin: 0 auto;
            width: 100%;

            ${breakpointDown.md`
                max-width: 700px;
            `};
            ${breakpointDown.sm`
                max-width: 500px;
            `};
        }
      
        &__intro-categories-heading {
            font-size: 32px;
        }
        &__link {
            font-family: 'PhoenixSansMediumWeb';
            font-size: ${({ theme }) => theme.fontSizes[5]}; // 18px
            color: ${({ theme }) => theme.colors.ember};
            text-decoration: none;
        }
    }
`;
