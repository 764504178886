import styled from 'styled-components';
import { InputGroup, Label } from '@reassure/ui';

export const StyledInputGroupLabel = styled(Label)``;

export const StyledInputGroup = styled(InputGroup)`
    position: relative;

    ${StyledInputGroupLabel} {
        width: 48px;
        height: 36px;
        padding: 0;
        margin: 0;
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 14px;
        border-color: ${({ theme }) => theme.colors.input.text.border};
        position: absolute;
        pointer-events: none;
    }
    &.left {
        ${StyledInputGroupLabel} {
            left: 0;
        }
        input {
            padding-left: 60px;
        }
    }
    &.right {
        ${StyledInputGroupLabel} {
            right: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        input {
            padding-right: 60px;
        }
    }
`;

export const FieldsSpacer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
