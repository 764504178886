import { useCallback, useEffect, useState } from 'react';
import { P } from '@reassure/ui';
import { ResultsFeedbackStyle, Modal, ModalHeader, ModalBody, ModalCloseButton } from './results-feedback.styles';
import RatingStars from './rating-stars';

import { useCreateFeedback } from './hook';

export const ResultsFeedbackBox = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [animationRunning, setAnimationRunning] = useState(false);

    const toggleModal = useCallback(() => {
        setIsOpen(isOpen => !isOpen);
    }, [setIsOpen]);

    useEffect(() => {
        let animationTimeout: NodeJS.Timeout | null = null;

        if (!animationRunning) {
            // Start animation after 10 seconds
            animationTimeout = setTimeout(() => {
                setAnimationRunning(true);
            }, 10000);
        }

        return () => {
            if (animationTimeout) clearTimeout(animationTimeout);
        };
    }, [animationRunning]);

    // Reset the animationRunning flag after the animation completes once
    useEffect(() => {
        if (animationRunning) {
            setTimeout(() => {
                setAnimationRunning(false);
            }, 500); // Adjust the timeout value to match the duration of your bounce animation (e.g., 0.5s)
        }
    }, [animationRunning]);

    return (
        <>
            <ResultsFeedbackStyle
                onClick={() => {
                    toggleModal();
                }}
                startAnimation={animationRunning}
            >
                <svg width="29" height="27" viewBox="0 0 29 27" fill="none">
                    <path
                        opacity="0.5"
                        d="M8.3918 22.1737L14.5 18.7339L20.6082 22.2111L18.9671 15.697L24.339 11.2924L17.2559 10.7111L14.5 4.56256L11.7441 10.6826L4.66102 11.255L10.0329 15.6506L8.3918 22.1737ZM14.5 21.7047L7.08685 25.9172C6.8368 26.0599 6.57667 26.1236 6.30645 26.1081C6.03626 26.0926 5.79573 26.0135 5.58485 25.8708C5.37397 25.7281 5.21741 25.5434 5.11516 25.3167C5.01289 25.0901 4.99142 24.8341 5.05076 24.5486L7.00818 16.591L0.469609 11.2175C0.232254 11.0248 0.0873527 10.8075 0.0349046 10.5654C-0.017517 10.3233 -0.0108914 10.0875 0.0547813 9.85788C0.11412 9.62828 0.250806 9.43437 0.464838 9.27615C0.678898 9.11793 0.937441 9.02634 1.24047 9.00137L9.88601 8.28979L13.2348 0.772623C13.3535 0.512111 13.5331 0.318213 13.7736 0.190928C14.0142 0.0636433 14.2563 0 14.5 0C14.7437 0 14.9858 0.0636433 15.2264 0.190928C15.4669 0.318213 15.6465 0.512111 15.7652 0.772623L19.114 8.28979L27.769 9.00137C28.0657 9.02634 28.3211 9.11793 28.5352 9.27615C28.7492 9.43437 28.8859 9.62828 28.9452 9.85788C29.0109 10.0875 29.0175 10.3233 28.9651 10.5654C28.9126 10.8075 28.7677 11.0248 28.5304 11.2175L21.9918 16.591L23.9492 24.5486C24.0086 24.8341 23.9871 25.0901 23.8848 25.3167C23.7826 25.5434 23.626 25.7281 23.4152 25.8708C23.2043 26.0135 22.9637 26.0926 22.6936 26.1081C22.4233 26.1236 22.1632 26.0599 21.9131 25.9172L14.5 21.7047Z"
                        fill="black"
                    />
                </svg>

                <P>Share your feedback</P>
            </ResultsFeedbackStyle>
            <ResultsFeedbackModel isOpen={isOpen} toggleModal={toggleModal} />
        </>
    );
};

interface ResultsFeedbackModelProps {
    isOpen: boolean;
    toggleModal: () => void;
}

const ResultsFeedbackModel = ({ isOpen, toggleModal }: ResultsFeedbackModelProps) => {
    const { register, handleSubmit, onSubmit } = useCreateFeedback();

    return (
        <Modal isOpen={isOpen}>
            <div className="modal-close-container" onClick={toggleModal}>
                <ModalCloseButton />
                <span>Close</span>
            </div>
            <ModalHeader>Your feedback is important to us</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className="results-feedback-form-group">
                            <P>How would you rate you overall experience using Mid-Life MOT?</P>
                            <RatingStars
                                textOne="Terrible"
                                textTwo="Brilliant"
                                label="overallExperienceRating"
                                register={register}
                            />
                        </div>

                        <div className="results-feedback-form-group">
                            <P>How easy did you find this tool to use?</P>
                            <RatingStars
                                textOne="Very difficult"
                                textTwo="Very easy"
                                label="howEasyRating"
                                register={register}
                            />
                        </div>

                        <div className="results-feedback-form-group">
                            <p>Do you plan to take an action from the list of tips you received?</p>

                            <div className="input-group">
                                <input id="yes" {...register('takeActionFromTips')} type="radio" value="yes" />
                                <label htmlFor="yes">Yes</label>
                                <input id="no" {...register('takeActionFromTips')} type="radio" value="no" />
                                <label htmlFor="no">No</label>
                            </div>
                        </div>

                        <div className="results-feedback-form-group">
                            <label>
                                Is there anything else you would like to tell us about your experience? (Please do not
                                include any personal information).
                            </label>
                            <textarea {...register('anythingElse')} placeholder="Optional feedback here..." />
                        </div>
                    </div>
                    <div className="submit-container">
                        <p>Your feedback is anonymous</p>
                        <input
                            onClick={toggleModal}
                            className="result-feedback-submit"
                            type="submit"
                            value="Submit feedback"
                        />
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};
