import styled from 'styled-components';
import { breakpointDown } from '@reassure/ui';

export const ProgressBarTitleContainer = styled.div`
    h1 {
        transition: opacity 0.2s ease-in-out;

        ${breakpointDown.md`
            font-size: 24px;
        `};
    }
    .about-worklife,
    .about-wealth,
    .question-seven,
    .question-eight,
    .question-nine,
    .question-ten,
    .question-eleven,
    .question-twelve,
    .question-thirteen,
    .question-fourteen,
    .question-fifteen,
    .question-sixteen,
    .question-seventeen,
    .question-eighteen {
        transition: opacity 0.2s ease-in-out;
        opacity: 0.6;
    }
`;

export const ProgressBarWellbeingStyles = styled.div`
    height: 8px;
    background-color: ${({ theme }) => theme.colors.lightcoral};
    border-left: 1px solid ${({ theme }) => theme.colors.eggshell};
    border-right: 1px solid ${({ theme }) => theme.colors.eggshell};
`;

export const ProgressBarLoader = styled.div`
    span {
        display: block;
        width: 0;
        height: 8px;
        background-color: ${({ theme }) => theme.colors.coral};
        transition: width 0.2s ease-in;

        &.question-two {
            width: calc(100% / 6);
        }

        &.question-three {
            width: calc((100% / 6) * 2);
        }

        &.question-four {
            width: calc((100% / 6) * 3);
        }

        &.question-five {
            width: calc((100% / 6) * 4);
        }

        &.question-six {
            width: calc((100% / 6) * 5);
        }

        &.about-worklife,
        &.about-wealth,
        &.question-seven,
        &.question-eight,
        &.question-nine,
        &.question-ten,
        &.question-eleven,
        &.question-twelve,
        &.question-thirteen,
        &.question-fourteen,
        &.question-fifteen,
        &.question-sixteen,
        &.question-seventeen,
        &.question-eighteen {
            width: 100%;
        }
    }
`;
