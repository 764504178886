import styled from 'styled-components';
import ArrowRight from '../../assets/images/arrow-white.svg';
import { breakpointDown } from '@reassure/ui';

export const HomeIntroPanelStyle = styled.div`
    padding: 60px 0 45px;

    h1 {
        font-family: 'PhoenixSansMediumWeb';
        font-size: ${({ theme }) => theme.fontSizes[11]}; // 36px
        line-height: 42px;
        margin-top: 0;
    }
    .mid-life {
        &__intro {
            line-height: 28px !important;
            font-size: ${({ theme }) => theme.fontSizes[8]}!important; // 24px
            padding-bottom: ${({ theme }) => theme.fontSizes[8]};
        }

        &__btn {
            /* margin-top: 60px; */
            display: block;
            background-color: ${({ theme }) => theme.colors.aubergine};
            color: ${({ theme }) => theme.colors.white};
            text-decoration: none;
            margin-left: 0;
            font-family: 'PhoenixSansMediumWeb';
            border-radius: 4px;
            font-size: 20px;
            max-width: 400px;
            height: 48px;
            padding: 0 27px;
            display: flex;
            align-items: center;
            gap: 100px;
            transition: border-bottom-right-radius 180ms ease-in-out;

            ${breakpointDown.md`
                 margin-top: 20px;
            `};

            ${breakpointDown.xs`
                 justify-content: space-between;
                 gap: 0;
            `};

            &:hover {
                border-bottom-right-radius: 22px;
            }

            &:after {
                content: '';
                background: url(${ArrowRight}) no-repeat center center;
                height: 32px;
                width: 32px;
            }
        }

        &__button-box {
            margin-top: 30px;
            padding: 18px;
            border-radius: 2px;
            border: 1px solid ${({ theme }) => theme.colors.lightgrey};

            h4 {
                margin: 0;
            }
            > div {
                align-items: center;
            }
        }
    }
    p {
        line-height: 1.5;
    }
`;
