import styled from 'styled-components';
import { breakpointDown } from '@reassure/ui';

export const HeaderStyle = styled.header`
    background-color: ${({ theme }) => theme.colors.aubergine};
    height: 82px;
    display: flex;

    ${breakpointDown.md`
        padding-top: 50px;
        height: 156px;
    `}

    a {
        display: block;

        ${breakpointDown.md`
           text-align: center;
        `}
    }
    svg {
        margin-bottom: 22px;

        ${breakpointDown.md`
            margin-bottom: 15px;
        `}
    }

    .headerRow {
        height: 100%;
        align-items: flex-end;

        ${breakpointDown.md`
            display: block;
        `}
    }

    .nav-list {
        display: flex;
        justify-content: flex-end;
        gap: 50px;
        margin-bottom: 22px;
        padding: 0;

        ${breakpointDown.md`
            margin-top: 15px;
            margin-bottom: 0;
           justify-content: center;
        `}

        &__item {
            a,
            button {
                font-size: ${({ theme }) => theme.fontSizes[5]}; // 18px
                color: ${({ theme }) => theme.colors.white};
                text-decoration: none;
                line-height: 1.5em;

                ${breakpointDown.sm`
                    font-size: ${({ theme }: any) => theme.fontSizes[3]};
                `}
            }
        }
    }
`;
