import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { getHighchartOptions } from '../components/pages/results/getHighchartOptions';
import { useUpdateSessionEventLazyQuery } from 'graphql/generated';

export const useSetLinks = () => {
    const [downloadingEventLink1, setDownloadingEventLink1] = useState(false);
    const [downloadingEventLink2, setDownloadingEventLink2] = useState(false);

    const [downloadingPDFLink1, setDownloadingPDFLink1] = useState(false);
    const [downloadingPDFLink2, setDownloadingPDFLink2] = useState(false);

    const downloadEventTopLink1 = () => {
        setDownloadingEventLink1(true);
        setTimeout(() => {
            setDownloadingEventLink1(false);
        }, 3000);
    };

    const downloadEventTopLink2 = () => {
        setDownloadingEventLink2(true);
        setTimeout(() => {
            setDownloadingEventLink2(false);
        }, 3000);
    };

    const downloadPDFLink1 = () => {
        setDownloadingPDFLink1(true);
        setTimeout(() => {
            setDownloadingPDFLink1(false);
        }, 3000);
    };

    const downloadPDFLink2 = () => {
        setDownloadingPDFLink2(true);
        setTimeout(() => {
            setDownloadingPDFLink2(false);
        }, 3000);
    };

    return {
        downloadPDFLink1,
        downloadPDFLink2,
        downloadEventTopLink1,
        downloadEventTopLink2,
        downloadingEventLink1,
        downloadingEventLink2,
        downloadingPDFLink1,
        downloadingPDFLink2,
    };
};

export const useAppState = () => {
    const MyPDFDownloadLink: any = PDFDownloadLink;
    const { state } = useLocation();

    const { answers, score, overallSectionParagraphs, topTips, allSectionTips, totalPercentage } = state;

    let chartOptionsWellbeing, chartOptionsWorklife, chartOptionsWealth;

    if (answers) {
        chartOptionsWellbeing = getHighchartOptions(score?.wellbeingScore, 'wellbeing');
        chartOptionsWorklife = getHighchartOptions(score?.worklifeScore, 'worklife');
        chartOptionsWealth = getHighchartOptions(score.wealthScore, 'wealth');
    }

    return {
        state,
        answers,
        score,
        totalPercentage,
        overallSectionParagraphs,
        topTips,
        allSectionTips,
        MyPDFDownloadLink,
        chartOptionsWellbeing,
        chartOptionsWorklife,
        chartOptionsWealth,
    };
};

export const useOnClickUpdateEvent = () => {
    const [callUpdateEvent] = useUpdateSessionEventLazyQuery({
        variables: { input: { sessionID: localStorage.getItem('sessionID'), sessionEvent: '' } },
        fetchPolicy: 'no-cache',
    });

    const onClickUpdateEvent = (eventName: string) => {
        // -- Call the useUpdateSessionResultLazyQuery from this function --
        const sessionID = localStorage.getItem('sessionID');
        callUpdateEvent({
            variables: { input: { sessionID, sessionEvent: eventName } },
        });
    };

    return {
        onClickUpdateEvent,
    };
};
