import styled from 'styled-components';
import { breakpointDown } from '@reassure/ui';

export const ProgressBarTitleContainer = styled.div`
    h1 {
        transition: opacity 0.2s ease-in-out;

        ${breakpointDown.md`
            font-size: 24px;
        `};
    }
    .about-wellbeing,
    .about-worklife,
    .question-one,
    .question-two,
    .question-three,
    .question-four,
    .question-five,
    .question-six,
    .question-seven,
    .question-eight,
    .question-nine,
    .question-ten,
    .question-eleven {
        transition: opacity 0.2s ease-in-out;
        opacity: 0.6;
    }
`;

export const ProgressBarWealthStyles = styled.div`
    height: 8px;
    background-color: ${({ theme }) => theme.colors.lightcoral};
    border-left: 1px solid ${({ theme }) => theme.colors.eggshell};
    border-right: 1px solid ${({ theme }) => theme.colors.eggshell};
`;

export const ProgressBarLoader = styled.div`
    span {
        display: block;
        width: 0;
        height: 8px;
        background-color: ${({ theme }) => theme.colors.coral};
        transition: width 0.2s ease-in;

        &.question-two,
        &.question-three,
        &.question-four,
        &.question-five,
        &.question-six &.question-seven,
        &.question-eight,
        &.question-nine,
        &.question-ten,
        &.question-eleven,
        &.question-twelve {
            width: 0;
        }

        &.question-thirteen {
            width: calc(100% / 6);
        }
        &.question-fourteen {
            width: calc((100% / 6) * 2);
        }
        &.question-fifteen {
            width: calc((100% / 6) * 3);
        }
        &.question-sixteen {
            width: calc((100% / 6) * 4);
        }
        &.question-seventeen {
            width: calc((100% / 6) * 5);
        }
        &.question-eighteen {
            width: 100%;
        }
    }
`;
