import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, H1, H3, H4, H5, P } from '@reassure/ui';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import variablePie from 'highcharts/modules/variable-pie';
import HCExporting from 'highcharts/modules/exporting';

import { TopTips } from 'components/top-tips';
import { SectionTips } from 'components/section-tips';
import { ResultsInfoBox } from 'components/results-info-box';
import { ResultsAnswers } from 'components/results-answers';
import { ResultsPdf } from 'components/results-pdf';

import { ResultsStyle, RuiContainer } from './results.styles';

import IdeaIcon from '../../../assets/images/idea-icon.svg';
import PassionIcon from '../../../assets/images/passion-icon.svg';
import IconHelp from '../../../assets/images/icon-help.svg';
import IconWellbeing from '../../../assets/images/icon-wellbeing.svg';
import IconWorklife from '../../../assets/images/icon-worklife.svg';
import IconWealth from '../../../assets/images/icon-wealth.svg';

import dayjs from 'dayjs';
import { useUpdateSessionResultLazyQuery } from 'graphql/generated';
import { useAppState, useOnClickUpdateEvent, useSetLinks } from '../../../services/hooks';
import { ReminderCard } from 'components/reminder-card';
import { splitStringByDelimiters } from './splitStringByDelimiters';
import { ResultsFeedbackBox } from 'components/results-feedback';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

variablePie(Highcharts);
HCExporting(Highcharts);

export const Results = () => {
    const [isSticky, setIsSticky] = useState(false);
    const headerRef = useRef<HTMLDivElement | null>(null);

    const chartComponentWellbeing = useRef<HighchartsReact.RefObject>(null);
    const chartComponentWorklife = useRef<HighchartsReact.RefObject>(null);
    const chartComponentWealth = useRef<HighchartsReact.RefObject>(null);

    const { downloadPDFLink1, downloadPDFLink2, downloadingPDFLink1 } = useSetLinks();
    const { onClickUpdateEvent } = useOnClickUpdateEvent();

    const {
        state,
        answers,
        score,
        totalPercentage,
        overallSectionParagraphs,
        topTips,
        allSectionTips,
        MyPDFDownloadLink,
        chartOptionsWellbeing,
        chartOptionsWorklife,
        chartOptionsWealth,
    } = useAppState();

    const [callUpdateSessionResult] = useUpdateSessionResultLazyQuery({
        variables: { input: {} },
    });

    const updateSessionResults = useCallback(
        (wellbeingscore: string, worklifescore: string, wealthscore: string) => {
            const sessionID = localStorage.getItem('sessionID');

            // Call the useUpdateSessionResultLazyQuery hook here --
            callUpdateSessionResult({
                variables: {
                    input: {
                        sessionID,
                        wellbeingScore: +wellbeingscore,
                        worklifeScore: +worklifescore,
                        wealthScore: +wealthscore,
                    },
                },
            });
        },
        [callUpdateSessionResult]
    );

    const wellbeingResult = splitStringByDelimiters(overallSectionParagraphs.wellbeingParagraph);
    const worklifeResult = splitStringByDelimiters(overallSectionParagraphs.worklifeParagraph);
    const wealthResult = splitStringByDelimiters(overallSectionParagraphs.wealthParagraph);

    useEffect(() => {
        if (score) {
            updateSessionResults(score?.wellbeingScore, score?.worklifeScore, score?.wealthScore);
        }
    }, [score, updateSessionResults]);

    useEffect(() => {
        // To apply sticky save your results for later bar
        const handleScroll = () => {
            if (headerRef.current) {
                const headerHeight = headerRef.current.offsetHeight;
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                setIsSticky(scrollTop >= headerHeight);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (state === null) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '24px' }}>
                <Link to="/" style={{ color: '#2E2D2C' }}>
                    Error, please go back home and start assessment
                </Link>
            </div>
        );
    }

    return (
        <ResultsStyle>
            <div ref={headerRef} className={`header-sticky-bar ${isSticky ? 'sticky' : ''}`}>
                <Container>
                    <Row>
                        <Col>
                            <div className="header-sticky-bar__content">
                                <H3 className="header-sticky-bar__title">Save your results for later</H3>

                                <div className="header-sticky-bar__download-pdf">
                                    <div className="tooltip">
                                        <img className="tooltip__icon" src={IconHelp} alt="icon help" />
                                        <span className="tooltip__text">How to download your MOT</span>

                                        <div className="tooltip__help-box">
                                            <P>
                                                Simply click "download PDF", wait for an option box to appear, choose
                                                "save as", select a folder to save it to, and rename if needed.
                                            </P>
                                        </div>
                                    </div>

                                    <div
                                        onClick={() => {
                                            downloadPDFLink1();
                                            onClickUpdateEvent('savedPDFAt');
                                        }}
                                    >
                                        <MyPDFDownloadLink
                                            className={`${
                                                downloadingPDFLink1 ? 'pdf__btn pdf__btn--downloading' : 'pdf__btn'
                                            }`}
                                            document={
                                                <ResultsPdf
                                                    overallSectionParagraphs={overallSectionParagraphs}
                                                    answers={answers}
                                                    topTips={topTips}
                                                    allSectionTips={allSectionTips}
                                                    score={score}
                                                    totalPercentage={totalPercentage}
                                                    chartComponentWellbeing={chartComponentWellbeing}
                                                    chartComponentWorklife={chartComponentWorklife}
                                                    chartComponentWealth={chartComponentWealth}
                                                />
                                            }
                                            fileName={`MOT-results-${dayjs().format('DD-MMMM-YYYY')}.pdf`}
                                        >
                                            {({ blob, url, loading, error }: any) =>
                                                url ? 'Download PDF' : 'Loading PDF..'
                                            }
                                        </MyPDFDownloadLink>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={`results ${isSticky ? 'results__sticky-margin' : ''}`}>
                <ResultsFeedbackBox />
                <RuiContainer>
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                            <div role="region" aria-label="results mid-life mot" className="results__top">
                                <H1 className="results__title">Thanks for completing the Mid-Life MOT</H1>
                                <P>
                                    Let's explore your scores in wellbeing, work, and wealth to identify areas of
                                    strength and opportunities for growth. Let's start by looking at your combined
                                    score:
                                </P>
                                <H3 className="results__subtitle">
                                    Your combined score is <span className="score-color">{totalPercentage}%</span>
                                </H3>
                                <P>{score.overallMessage}</P>

                                <H3>Your Scores Breakdown</H3>
                            </div>
                        </Col>
                        <Col lg={2}></Col>
                    </Row>

                    <Row>
                        <Col lg={4}>
                            <div className="results__section results__section--wellbeing">
                                <img src={IconWellbeing} alt="icon wellbeing" className="results__section--img" />
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={chartOptionsWellbeing}
                                    ref={chartComponentWellbeing}
                                />
                                <H5 className="results__section-title">Wellbeing</H5>
                                <P className="results__section-score">{score?.wellbeingScore}%</P>

                                {wellbeingResult.map((item, index) =>
                                    index === 0 ? (
                                        <span key={index} className="strong">
                                            {item}{' '}
                                        </span>
                                    ) : (
                                        <span key={index}>{item} </span>
                                    )
                                )}
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="results__section results__section--worklife">
                                <img src={IconWorklife} alt="icon worklife" className="results__section--img" />
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={chartOptionsWorklife}
                                    ref={chartComponentWorklife}
                                />
                                <H5 className="results__section-title">Work-life</H5>
                                <P className="results__section-score">{score?.worklifeScore}%</P>

                                {worklifeResult.map((item, index) =>
                                    index === 0 ? (
                                        <span key={index} className="strong">
                                            {item}{' '}
                                        </span>
                                    ) : (
                                        <span key={index}>{item} </span>
                                    )
                                )}
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="results__section results__section--wealth">
                                <img src={IconWealth} alt="icon wealth" className="results__section--img" />
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={chartOptionsWealth}
                                    ref={chartComponentWealth}
                                />
                                <H5 className="results__section-title">Wealth</H5>
                                <P className="results__section-score">{score?.wealthScore}%</P>

                                {wealthResult.map((item, index) =>
                                    index === 0 ? (
                                        <span key={index} className="strong">
                                            {item}{' '}
                                        </span>
                                    ) : (
                                        <span key={index}>{item} </span>
                                    )
                                )}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <div className="results__improve">
                                <H4 className="results__improve-title">How can I improve my score</H4>

                                <div className="results__improve-text">
                                    <P>
                                        Follow the top tips below. Make sure you
                                        <span
                                            onClick={() => {
                                                downloadPDFLink2();
                                                onClickUpdateEvent('savedPDFAt');
                                            }}
                                        >
                                            <MyPDFDownloadLink
                                                document={
                                                    <ResultsPdf
                                                        overallSectionParagraphs={overallSectionParagraphs}
                                                        answers={answers}
                                                        topTips={topTips}
                                                        allSectionTips={allSectionTips}
                                                        score={score}
                                                        totalPercentage={totalPercentage}
                                                        chartComponentWellbeing={chartComponentWellbeing}
                                                        chartComponentWorklife={chartComponentWorklife}
                                                        chartComponentWealth={chartComponentWealth}
                                                    />
                                                }
                                                fileName={`MOT-results-${dayjs().format('DD-MMMM-YYYY')}.pdf`}
                                            >
                                                {({ blob, url, loading, error }: any) => 'download the PDF'}
                                            </MyPDFDownloadLink>
                                        </span>
                                        so you can keep track of your progress. You can also set a reminder down below,
                                        allowing you to check your progress in 6 months time.
                                    </P>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <TopTips topTips={topTips} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ReminderCard onClickUpdateEvent={onClickUpdateEvent} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <SectionTips
                                wellbeingTips={allSectionTips.wellbeingTips}
                                worklifeTips={allSectionTips.worklifeTips}
                                wealthTips={allSectionTips.wealthTips}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <ResultsInfoBox
                                svg={IdeaIcon}
                                content={'Looking for more information?'}
                                linkText={'Find additional resources'}
                                linkUrl={'/further-information'}
                            />
                        </Col>
                        <Col lg={6}>
                            <ResultsInfoBox
                                svg={PassionIcon}
                                content={'Need to speak to someone?'}
                                linkText={'Get additional support'}
                                linkUrl={'/help'}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <ResultsAnswers answers={answers} />
                        </Col>
                    </Row>
                </RuiContainer>
            </div>
        </ResultsStyle>
    );
};
