import { memo } from 'react';
import { RadioFieldsContainer } from './field.styles';
import { RadioField } from './radio-field';

interface Option {
    label: string;
    value: string;
}

interface RadioFieldGroupProps {
    name: string;
    options: Option[];
    stacked?: boolean;
}

export const RadioFieldGroup = memo(({ name, options, stacked }: RadioFieldGroupProps) => {
    return (
        <RadioFieldsContainer stacked={stacked}>
            {options.map(option => (
                <RadioField key={option.label} name={name} label={option.label} value={option.value} />
            ))}
        </RadioFieldsContainer>
    );
});
