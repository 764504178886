import { Container } from '@reassure/ui';
import styled, { keyframes } from 'styled-components';
import ArrowRight from '../assets/images/arrow-black.svg';
import { breakpointDown } from '@reassure/ui';

export const QuestionNumber = styled.span`
    font-family: 'PhoenixSansBoldWeb';
    display: block;
    color: ${({ theme }) => theme.colors.charcoal};
    font-size: ${({ theme }) => theme.fontSizes[8]}!important; // 24px
    margin-bottom: ${({ theme }) => theme.fontSizes[7]};
    line-height: 22px;
`;

export const QuestionContainer = styled(Container)`
    max-width: 1020px;
    margin-top: 15px;
`;

export const RUContainer = styled(Container)`
    max-width: 1020px;
`;

export const QuestionContainerWhite = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    padding-top: 23px;
    padding-bottom: 130px;
`;
export const HelpStyles = styled.div``;

export const AboutStyles = styled.div`
    .about {
        &__title {
            text-align: center;
            margin-top: 56px;
            margin-bottom: ${({ theme }) => theme.space[7]};
        }
        &__content {
            text-align: center;
            line-height: 1.5 !important;
            margin-bottom: 25px;
            font-size: ${({ theme }) => theme.fontSizes[8]}!important; // 24px
        }
        &__btn {
            margin-left: auto;
            border: 2px solid ${({ theme }) => theme.colors.ember};
            color: ${({ theme }) => theme.colors.charcoal};
            text-decoration: none;
            font-family: 'PhoenixSansMediumWeb';
            border-radius: 4px;
            font-size: 16px;
            max-width: 320px;
            height: 48px;
            padding: 0 37px;
            display: flex;
            align-items: center;
            gap: 130px;
            transition: border-bottom-right-radius 180ms ease-in-out;

            ${breakpointDown.lg`
                margin-right: 15px;
            `}

            ${breakpointDown.xs`
                margin: 0 0 20px 10px;
            `}

            &:hover {
                border-bottom-right-radius: 22px;
            }
            &:after {
                content: '';
                background: url(${ArrowRight}) no-repeat center center;
                height: 32px;
                width: 32px;
            }
        }
    }
`;

const SpinAnimation = keyframes`
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
`;

export const QuestionnaireSubmitButtonStyles = styled.div`
    .submit-btn {
        margin-right: 10px;
        color: ${({ theme }) => theme.colors.charcoal};
        text-decoration: none;
        background-color: transparent;
        border: 2px solid ${({ theme }) => theme.colors.ember};
        display: block;
        border-radius: 4px;
        font-family: 'PhoenixSansMediumWeb';
        font-size: ${({ theme }) => theme.fontSizes[4]}; // 16px
        max-width: 320px;
        min-width: 320px;
        height: 48px;
        cursor: pointer;
        padding: 11px 34px 10px 25px;
        display: flex;
        align-items: center;
        gap: 125px;
        transition: border-bottom-right-radius 180ms ease-in-out;

        ${breakpointDown.xs`
            margin-left: 10px;
        `}

        &:hover {
            border-bottom-right-radius: 22px;
        }

        &:after {
            content: '';
            background: url(${ArrowRight}) no-repeat center center;
            height: 32px;
            width: 32px;
        }

        &__submitting {
            opacity: 0.6;
            color: $white;
            pointer-events: none;
            position: relative;
            &::before {
                opacity: 1;
                position: absolute;
                right: 40px;
                content: '\f021';
                font-family: 'Font Awesome 6 Free';
                animation: ${SpinAnimation} 1s infinite linear;
                font-weight: 900;
            }
            &::after {
                display: none;
            }
        }
    }

    .isDisabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;
