import { Svg, G, Polygon, Path, Defs } from '@react-pdf/renderer';

import { styles } from './results-pdf.styles';

const MySvg: any = Svg;
const MyG: any = G;
const MyPolygon: any = Polygon;
const MyPath: any = Path;
const MyDefs: any = Defs;

export const Logo = () => (
    <MySvg fixed style={styles.logo} viewBox="0 0 100 17">
        <MyDefs>
            <MyPolygon points="0 0 59.8522678 0 59.8522678 11.1715102 0 11.1715102" />
        </MyDefs>
        <MyG stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <MyG transform="translate(-40.000000, -50.000000)">
                <MyG transform="translate(40.000000, 50.000000)">
                    <MyPath
                        d="M16.2635577,-1.81188398e-15 L0.000126353243,-1.81188398e-15 C0.000126353243,1.33780118 1.06882208,2.532183 2.32338344,2.532183 L12.4763719,2.532183 C13.1038421,2.532183 14.1259135,2.24547421 14.6140161,1.69595984 C14.1259135,2.24547421 13.8006803,3.24863023 13.8006803,3.89362759 L13.8006803,14.332452 C13.8006803,15.6221869 14.962372,16.7210857 16.2635577,16.7210857 L16.2635577,-1.81188398e-15"
                        fill="#DD4451"
                    />
                    <MyPath
                        d="M10.9430753,5.49410443 L0,5.49410443 C0,6.83190561 1.06882208,8.02628743 2.32338344,8.02628743 L7.29538356,8.02628743 C7.92260106,8.02628743 8.80568387,7.73957864 9.29353375,7.19006427 C8.80568387,7.73957864 8.48019792,8.59957512 8.48019792,9.24431267 L8.48019792,14.3323221 C8.48019792,15.6224467 9.64188964,16.7210857 10.9430753,16.7210857 L10.9430753,5.49410443"
                        fill="#E5736E"
                    />
                    <MyG transform="translate(0.000000, 5.828490)">
                        <MyPath
                            d="M5.62259297,5.11191264 L0,5.11191264 C0,6.42568068 0.90620546,7.52457952 2.2305138,7.52457952 C2.78811067,7.52457952 3.34570753,7.38116016 3.74068777,7.04677482 C3.41545452,7.45286984 3.27596054,8.02615753 3.27596054,8.59944521 C3.27596054,9.96101971 4.32140727,10.8927259 5.62259297,10.8927259 L5.62259297,5.11191264"
                            fill="#EB958A"
                        />
                        <MyPath
                            d="M54.3459198,8.91005724 C52.4872636,8.91005724 51.1397063,7.35738685 51.1397063,5.58971733 C51.1397063,3.82204782 52.4872636,2.26937743 54.3459198,2.26937743 C56.181327,2.26937743 57.4825127,3.82204782 57.4825127,5.58971733 C57.4825127,7.35738685 56.181327,8.91005724 54.3459198,8.91005724 Z M54.3459198,0 C51.1862042,0 48.7698248,2.3409572 48.7698248,5.58971733 C48.7698248,8.83834756 51.1862042,11.1793048 54.3459198,11.1793048 C57.5056354,11.1793048 59.8522678,8.83834756 59.8522678,5.58971733 C59.8522678,2.3409572 57.5056354,0 54.3459198,0 L54.3459198,0 Z"
                            fill="#2E2D2C"
                        />
                    </MyG>
                    <MyPath
                        d="M29.5556662,8.52773554 L26.5120693,8.52773554 L26.5120693,2.4365701 L29.5556662,2.4365701 C31.3214528,2.4365701 32.7386307,3.53533902 32.7386307,5.49410443 C32.7386307,7.45286984 31.3214528,8.52773554 29.5556662,8.52773554 Z M29.0445673,-1.81188398e-15 L23.9563222,-1.81188398e-15 L23.9563222,16.7212156 L26.5120693,16.7212156 L26.5120693,10.9643056 L29.0445673,10.9643056 C33.110362,10.9643056 35.1781328,8.48005899 35.1781328,5.49410443 C35.1781328,2.50814987 33.110362,-1.81188398e-15 29.0445673,-1.81188398e-15 L29.0445673,-1.81188398e-15 Z"
                        fill="#2E2D2C"
                    />
                    <MyPath
                        d="M42.752378,5.82848977 C41.0795874,5.82848977 39.9875163,6.59287335 39.4531684,7.8111584 C39.5925361,7.30958039 39.685532,6.54519681 39.685532,5.99568244 L39.685532,2.4365701 C39.685532,0.955479478 38.9420696,-1.81188398e-15 37.3621486,-1.81188398e-15 L37.3156506,-1.81188398e-15 L37.3156506,16.7212156 L39.685532,16.7212156 L39.685532,11.0599185 C39.685532,9.19663613 40.5916112,8.07396398 42.1250341,8.07396398 C43.6817061,8.07396398 44.4949155,9.19663613 44.4949155,11.0599185 L44.4949155,16.7212156 L46.8646706,16.7212156 L46.8646706,10.7015001 C46.8646706,7.71567541 45.238378,5.82848977 42.752378,5.82848977"
                        fill="#2E2D2C"
                    />
                    <MyPath
                        d="M79.368537,5.82848977 C77.8909622,5.82848977 76.93371,6.37696487 76.3117994,7.16330305 C76.151078,6.47049923 75.8972344,6.11519857 75.1167504,6.11519857 L73.9783077,6.11519857 L73.9783077,16.7212156 L76.3481891,16.7212156 L76.3481891,11.1554015 C76.3481891,9.3638288 77.1381496,8.07396398 78.8109402,8.07396398 C80.4837308,8.07396398 81.1575726,9.3638288 81.1575726,11.1554015 L81.1575726,16.7212156 L83.5273277,16.7212156 L83.5273277,10.8210162 C83.5273277,7.64396573 81.9010351,5.82848977 79.368537,5.82848977"
                        fill="#2E2D2C"
                    />
                    <MyPath
                        d="M66.6596751,8.02615753 C68.439866,8.02615753 69.4815221,9.18286579 69.6220269,10.4149212 L63.7237312,10.4149212 C64.0651376,8.93214174 65.1939775,8.02615753 66.6596751,8.02615753 Z M66.6596751,5.82848977 C63.5464576,5.82848977 61.3624418,8.2889631 61.3624418,11.3943039 C61.3624418,14.4757414 63.5464576,16.960118 66.8687897,16.960118 C68.5649557,16.960118 69.9821337,16.4106036 71.1670744,14.83403 L69.5871534,13.4724555 C68.8436909,14.3085488 68.1930981,14.7624502 66.9152877,14.7624502 C65.243508,14.7624502 63.9655713,13.8056717 63.6774859,12.1347842 L71.7944182,12.1347842 C71.8640389,11.7525924 71.8872879,11.3225942 71.8872879,10.9404024 C71.8872879,8.0978672 69.842766,5.82848977 66.6596751,5.82848977 L66.6596751,5.82848977 Z"
                        fill="#2E2D2C"
                    />
                    <MyPath
                        d="M96.3044203,11.4260016 L99.976751,6.11519857 L98.420079,6.11519857 C97.5604979,6.11519857 96.9796521,6.42568068 96.4684269,7.19006427 L95.4229801,8.76663788 C95.1906165,9.12505636 95.0279999,9.38773202 94.9350039,9.76992381 C94.8188853,9.38773202 94.6562686,9.12505636 94.423905,8.76663788 L93.4015809,7.19006427 C92.9137311,6.44958391 92.3328852,6.11519857 91.4500551,6.11519857 L89.9630038,6.11519857 L93.613728,11.4237932 L89.9166321,16.7212156 L91.4268061,16.7212156 C92.3096362,16.7212156 92.9137311,16.3868303 93.3783319,15.6223168 L94.423905,13.926357 C94.6097706,13.6396482 94.7723873,13.3769725 94.9117549,12.923071 C95.0279999,13.3769725 95.1906165,13.6396482 95.3764821,13.926357 L96.4451779,15.6223168 C96.9331541,16.3868303 97.5139999,16.7212156 98.39683,16.7212156 L100,16.7212156 L96.3044203,11.4260016"
                        fill="#2E2D2C"
                    />
                    <MyPath
                        d="M86.9659048,0.50157801 C86.1294464,0.50157801 85.4789799,1.1704786 85.4789799,2.00657186 C85.4789799,2.86643843 86.1294464,3.53533902 86.9659048,3.53533902 C87.7791143,3.53533902 88.4295808,2.86643843 88.4295808,2.00657186 C88.4295808,1.1704786 87.7791143,0.50157801 86.9659048,0.50157801"
                        fill="#2E2D2C"
                    />
                    <MyPolygon
                        fill="#2E2D2C"
                        points="85.7809641 16.7212156 88.1508456 16.7212156 88.1508456 6.11519857 85.7809641 6.11519857"
                    />
                </MyG>
            </MyG>
        </MyG>
    </MySvg>
);
