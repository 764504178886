import { Document, Page, Text, View, Link, Image } from '@react-pdf/renderer';

import { ArrowSvg } from './arrowSVG';
import { ChecklistIcon } from './checklist-icon';
import { GrowthIcon } from './growthIcon';
import { Logo } from './logo';
import { WellbeingIcon } from './wellbeingIconSVG';
import { WorklifeIcon } from './worklifeIconSVG';
import { WealthIcon } from './wealthIconSVG';
import { PassionIcon } from './passionIcon';

import questions from '../../utils/MOT-questions-answers.json';

import { styles } from './results-pdf.styles';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { convertSvgToImage } from './createSVG';

const questionTextArray = questions.data.map(item => ({ label: item.questionText }));

dayjs.extend(advancedFormat);

export const ResultsPdf = ({
    overallSectionParagraphs,
    allSectionTips,
    topTips,
    answers,
    score,
    totalPercentage,
    chartComponentWellbeing,
    chartComponentWorklife,
    chartComponentWealth,
}: any) => {
    const { wellbeingParagraph, worklifeParagraph, wealthParagraph } = overallSectionParagraphs;
    const yourAnswers = [...answers.wellbeingA, ...answers.worklifeA, ...answers.wealthA];

    const wellbeingChart = chartComponentWellbeing.current?.chart;
    const worklifeChart = chartComponentWorklife.current?.chart;
    const wealthChart = chartComponentWealth.current?.chart;

    const currentWidth = 920 / 2;
    const currentHeight = 242;

    let chartSVGWellbeing = wellbeingChart.getSVG({
        chart: {
            width: currentWidth,
            height: currentHeight,
            marginLeft: 10,
            border: 0,
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            variablepie: {
                borderWidth: 3,
            },
            series: {
                dataLabels: {
                    enabled: false,
                },
            },
        },
    });

    let chartSVGWorklife = worklifeChart.getSVG({
        chart: {
            width: currentWidth,
            height: currentHeight,
            marginLeft: 10,
            border: 0,
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            variablepie: {
                borderWidth: 3,
            },
            series: {
                dataLabels: {
                    enabled: false,
                },
            },
        },
    });

    let chartSVGWealth = wealthChart.getSVG({
        chart: {
            width: currentWidth,
            height: currentHeight,
            marginLeft: 10,
            border: 0,
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            variablepie: {
                borderWidth: 3,
            },
            series: {
                dataLabels: {
                    enabled: false,
                },
            },
        },
    });

    // Asynchronously convert the SVG to an image
    const chartImagePromiseWellbeing = convertSvgToImage(chartSVGWellbeing);
    const chartImagePromiseWorklife = convertSvgToImage(chartSVGWorklife);
    const chartImagePromiseWealth = convertSvgToImage(chartSVGWealth);

    // typescript
    const MyDocument: any = Document;
    const MyPage: any = Page;
    const MyView: any = View;
    const MyText: any = Text;
    const MyLink: any = Link;
    const MyImage: any = Image;

    interface PDFProps {
        pageNumber: number;
        totalPages: number;
    }

    return (
        <MyDocument>
            {/* 1st PAGE */}
            <MyPage size="A4" style={styles.body}>
                <MyView>
                    <MyView style={styles.header}>
                        <Logo />
                        <MyText
                            style={styles.pageNumber}
                            render={({ pageNumber, totalPages }: PDFProps) => `P${pageNumber} of ${totalPages}`}
                        />
                    </MyView>
                    <MyView style={styles.resultsTop}>
                        <MyText style={styles.results_title}>Thanks for completing the Mid-Life MOT</MyText>

                        <MyText style={styles.results_text}>
                            Let's explore your scores in wellbeing, work, and wealth to identify areas of strength and
                            opportunities for growth.
                        </MyText>
                        <MyText style={styles.results_text_score}>
                            Your combined score is{' '}
                            <MyText style={styles.results_score_color}>{totalPercentage}%</MyText>
                        </MyText>

                        <MyText style={styles.results_score_breakdown_text}>Your Scores Breakdown</MyText>
                    </MyView>

                    <MyView style={styles.resultsContainer}>
                        {/* Render chartImage as an Image */}
                        <MyView style={styles.resultsChartWellbeing}>
                            {chartImagePromiseWellbeing && (
                                <MyImage style={styles.results_section_chart} src={chartImagePromiseWellbeing} />
                            )}

                            <MyView style={styles.results_section_wellbeing}>
                                <WellbeingIcon />
                                <MyText style={styles.results_h4}>Wellbeing</MyText>
                                <MyText style={styles.results_h5}>{score?.wellbeingScore}%</MyText>
                                <MyText style={styles.results_reg_text}>{wellbeingParagraph}</MyText>
                            </MyView>
                        </MyView>

                        <MyView style={styles.resultsChartWorklife}>
                            {chartImagePromiseWorklife && (
                                <MyImage style={styles.results_section_chart} src={chartImagePromiseWorklife} />
                            )}
                            <MyView style={styles.results_section_worklife}>
                                <WorklifeIcon />
                                <MyText style={styles.results_h4}>Work-life</MyText>
                                <MyText style={styles.results_h5}>{score?.worklifeScore}%</MyText>
                                <MyText style={styles.results_reg_text}>{worklifeParagraph}</MyText>
                            </MyView>
                        </MyView>

                        <MyView style={styles.resultsChartWealth}>
                            {chartImagePromiseWealth && (
                                <MyImage style={styles.results_section_chart} src={chartImagePromiseWealth} />
                            )}
                            <MyView style={styles.results_section_wealth}>
                                <WealthIcon />
                                <MyText style={styles.results_h4}>Wealth</MyText>
                                <MyText style={styles.results_h5}>{score?.wealthScore}%</MyText>
                                <MyText style={styles.results_reg_text}>{wealthParagraph}</MyText>
                            </MyView>
                        </MyView>
                    </MyView>

                    <MyView style={styles.results_overall}>
                        <MyText style={styles.results_overallTitle}>Overall result</MyText>
                        <MyText style={styles.results_reg_text}>{score.overallMessage}</MyText>
                    </MyView>

                    <MyView style={styles.top_tips}>
                        <MyView style={styles.top_tips_title_container}>
                            <ChecklistIcon />
                            <MyText style={styles.top_tips_title}>Explore your top tips and take action…</MyText>
                        </MyView>

                        {/* ===== TOP TIPS ====== */}
                        <MyView style={styles.top_tips_table}>
                            {topTips.map((item: any, index: number) => (
                                <MyView
                                    style={
                                        index < topTips.length - 1
                                            ? styles.table_container
                                            : styles.table_container_last
                                    }
                                    key={index}
                                >
                                    <MyText style={styles.top_tips_table_number}>{index + 1}</MyText>
                                    <MyView style={styles.top_tips_content}>
                                        <MyText style={styles.top_tips_text}>{item?.text}</MyText>

                                        <MyView style={styles.link_container}>
                                            {item?.link_1_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_1_url} style={styles.top_tips_link}>
                                                        {item?.link_1}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_2_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_2_url} style={styles.top_tips_link}>
                                                        {item?.link_2}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_3_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_3_url} style={styles.top_tips_link}>
                                                        {item?.link_3}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_4_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_4_url} style={styles.top_tips_link}>
                                                        {item?.link_4}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_5_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_5_url} style={styles.top_tips_link}>
                                                        {item?.link_5}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_6_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_6_url} style={styles.top_tips_link}>
                                                        {item?.link_6}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                        </MyView>
                                    </MyView>
                                    <MyText style={styles.table_square}></MyText>
                                </MyView>
                            ))}
                        </MyView>
                    </MyView>
                </MyView>
            </MyPage>

            {/* PAGE 2  */}
            <MyPage size="A4" style={styles.body}>
                <MyView>
                    <MyView style={styles.header}>
                        <Logo />
                        <MyText
                            style={styles.pageNumber}
                            render={({ pageNumber, totalPages }: PDFProps) => `P${pageNumber} of ${totalPages}`}
                        />
                    </MyView>

                    {/* ===== SECTION TIPS - WELLBEING & WORKLIFE ====== */}
                    <MyView style={styles.section}>
                        <MyView style={styles.section_tips_title_container}>
                            <ChecklistIcon />

                            <MyText style={styles.section_tips_title}>
                                We have further suggestions that could help you live better longer. When you're ready,
                                take a look
                            </MyText>
                        </MyView>

                        <MyView style={styles.section_table}>
                            <MyView>
                                <MyText style={styles.purple_background}>
                                    Tip - when accessing wellbeing content, use Organisation code {''}
                                    <MyText style={styles.bold}>'wecare'</MyText> and access code{' '}
                                    <MyText style={styles.bold}>'BeWell'</MyText> if prompted
                                </MyText>
                            </MyView>
                            <MyView
                                style={[styles.text_title_containter, styles.section_tips_wellbeing_title_container]}
                            >
                                <MyText style={styles.text_title}>Wellbeing</MyText>
                            </MyView>

                            {allSectionTips.wellbeingTips.map((item: any, index: number) => (
                                <MyView
                                    style={
                                        index < allSectionTips.wellbeingTips.length - 1
                                            ? styles.table_container
                                            : styles.table_container_last
                                    }
                                    key={index}
                                >
                                    <MyView style={styles.section_tips_wellbeing}>
                                        <MyText style={styles.section_tips_text}>{item?.text}</MyText>

                                        <MyView style={styles.section_tips_link_container}>
                                            {item?.link_1_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_1_url} style={styles.top_tips_link}>
                                                        {item?.link_1}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}

                                            {item?.link_2_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_2_url} style={styles.top_tips_link}>
                                                        {item?.link_2}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}

                                            {item?.link_3_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_3_url} style={styles.top_tips_link}>
                                                        {item?.link_3}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}

                                            {item?.link_4_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_4_url} style={styles.top_tips_link}>
                                                        {item?.link_4}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}

                                            {item?.link_5_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_5_url} style={styles.top_tips_link}>
                                                        {item?.link_5}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}

                                            {item?.link_6_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_6_url} style={styles.top_tips_link}>
                                                        {item?.link_6}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                        </MyView>
                                    </MyView>
                                    <MyText style={styles.table_square}></MyText>
                                </MyView>
                            ))}

                            <MyView style={styles.text_title_containter}>
                                <MyText style={styles.text_title}>Work-life</MyText>
                            </MyView>

                            {allSectionTips.worklifeTips.map((item: any, index: number) => (
                                <MyView
                                    style={
                                        index < allSectionTips.worklifeTips.length - 1
                                            ? styles.table_container
                                            : styles.table_container_last
                                    }
                                    key={index}
                                >
                                    <MyView style={styles.section_tips_worklife}>
                                        <MyText style={styles.section_tips_text}>{item?.text}</MyText>

                                        <MyView style={styles.link_container}>
                                            {item?.link_1_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_1_url} style={styles.section_tips_link}>
                                                        {item?.link_1}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_2_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_2_url} style={styles.top_tips_link}>
                                                        {item?.link_2}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_3_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_3_url} style={styles.top_tips_link}>
                                                        {item?.link_3}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_4_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_4_url} style={styles.top_tips_link}>
                                                        {item?.link_4}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_5_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_5_url} style={styles.top_tips_link}>
                                                        {item?.link_5}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                            {item?.link_6_url !== '' && (
                                                <MyView style={styles.link_inner_container}>
                                                    <MyLink src={item?.link_6_url} style={styles.top_tips_link}>
                                                        {item?.link_6}
                                                    </MyLink>
                                                    <ArrowSvg />
                                                </MyView>
                                            )}
                                        </MyView>
                                    </MyView>
                                    <MyText style={styles.table_square}></MyText>
                                </MyView>
                            ))}

                            {/* ===== SECTION TIPS - WEALTH ====== */}
                            {allSectionTips.wealthTips.length > 0 && (
                                <MyView style={styles.section}>
                                    <MyView style={styles.section_table}>
                                        <MyView style={styles.text_title_containter}>
                                            <MyText style={styles.text_title}>Wealth</MyText>
                                        </MyView>

                                        {allSectionTips.wealthTips.map((item: any, index: number) => (
                                            <MyView
                                                style={
                                                    index < allSectionTips.wealthTips.length - 1
                                                        ? styles.table_container
                                                        : styles.table_container_last
                                                }
                                                key={index}
                                            >
                                                <MyView style={styles.section_tips_wealth}>
                                                    <MyText style={styles.section_tips_text}>{item?.text}</MyText>

                                                    <MyView style={styles.link_container}>
                                                        {item?.link_1_url !== '' && (
                                                            <MyView style={styles.link_inner_container}>
                                                                <MyLink
                                                                    src={item?.link_1_url}
                                                                    style={styles.section_tips_link}
                                                                >
                                                                    {item?.link_1}
                                                                </MyLink>
                                                                <ArrowSvg />
                                                            </MyView>
                                                        )}
                                                        {item?.link_2_url !== '' && (
                                                            <MyView style={styles.link_inner_container}>
                                                                <MyLink
                                                                    src={item?.link_2_url}
                                                                    style={styles.top_tips_link}
                                                                >
                                                                    {item?.link_2}
                                                                </MyLink>
                                                                <ArrowSvg />
                                                            </MyView>
                                                        )}
                                                        {item?.link_3_url !== '' && (
                                                            <MyView style={styles.link_inner_container}>
                                                                <MyLink
                                                                    src={item?.link_3_url}
                                                                    style={styles.top_tips_link}
                                                                >
                                                                    {item?.link_3}
                                                                </MyLink>
                                                                <ArrowSvg />
                                                            </MyView>
                                                        )}
                                                        {item?.link_4_url !== '' && (
                                                            <MyView style={styles.link_inner_container}>
                                                                <MyLink
                                                                    src={item?.link_4_url}
                                                                    style={styles.top_tips_link}
                                                                >
                                                                    {item?.link_4}
                                                                </MyLink>
                                                                <ArrowSvg />
                                                            </MyView>
                                                        )}
                                                        {item?.link_5_url !== '' && (
                                                            <MyView style={styles.link_inner_container}>
                                                                <MyLink
                                                                    src={item?.link_5_url}
                                                                    style={styles.top_tips_link}
                                                                >
                                                                    {item?.link_5}
                                                                </MyLink>
                                                                <ArrowSvg />
                                                            </MyView>
                                                        )}
                                                        {item?.link_6_url !== '' && (
                                                            <MyView style={styles.link_inner_container}>
                                                                <MyLink
                                                                    src={item?.link_6_url}
                                                                    style={styles.top_tips_link}
                                                                >
                                                                    {item?.link_6}
                                                                </MyLink>
                                                                <ArrowSvg />
                                                            </MyView>
                                                        )}
                                                    </MyView>
                                                </MyView>
                                                <MyText style={styles.table_square}></MyText>
                                            </MyView>
                                        ))}
                                    </MyView>
                                </MyView>
                            )}
                        </MyView>
                    </MyView>
                </MyView>
            </MyPage>

            {/* PAGE 3 */}
            <MyPage size="A4" style={styles.body}>
                <MyView>
                    <MyView style={styles.header}>
                        <Logo />
                        <MyText
                            style={styles.pageNumber}
                            render={({ pageNumber, totalPages }: PDFProps) => `P${pageNumber} of ${totalPages}`}
                        />
                    </MyView>

                    <MyView style={styles.info_box_container}>
                        <MyLink
                            src={`${process.env.REACT_APP_DOMAIN_NAME}further-information`}
                            style={styles.info_box_link}
                        >
                            <GrowthIcon />
                            <MyText style={styles.info_box_link_text}>Looking for more information?</MyText>
                            <MyText style={styles.info_box_link_text_bold}>Click here for additional resources</MyText>
                        </MyLink>

                        <MyLink src={`${process.env.REACT_APP_DOMAIN_NAME}help`} style={styles.info_box_link}>
                            <PassionIcon />
                            <MyText style={styles.info_box_link_text}>Need to speak to someone?</MyText>
                            <MyText style={styles.info_box_link_text_bold}>Click here for additional support</MyText>
                        </MyLink>
                    </MyView>

                    <MyView>
                        {/* ===== QUESTIONS AND ANSWERS ====== */}
                        <MyView style={styles.section}>
                            <MyView style={styles.section_table}>
                                <MyView style={styles.text_title_containter}>
                                    <MyText style={styles.text_title}>
                                        Your answers from {dayjs().format('Do MMMM, YYYY')}
                                    </MyText>
                                </MyView>

                                {yourAnswers.map((item: any, index: number) => (
                                    <MyView
                                        style={
                                            index < allSectionTips.wealthTips.length - 1
                                                ? styles.table_container
                                                : styles.table_container_last
                                        }
                                        key={index}
                                    >
                                        <MyView style={styles.answers_question}>
                                            <MyText style={[styles.bold, styles.answers_title]}>
                                                {item?.section.charAt(0).toUpperCase() +
                                                    item?.section.slice(1).toLowerCase()}{' '}
                                                - Question {index + 1}
                                            </MyText>

                                            <MyText style={styles.answers_text}>
                                                {questionTextArray[index].label}
                                            </MyText>

                                            <MyView style={styles.answers_answer}>
                                                <MyText style={[styles.bold, styles.answers_title]}>
                                                    Your answer:{' '}
                                                    <MyText style={styles.answers_text}>{item?.answerText}</MyText>
                                                </MyText>
                                            </MyView>
                                        </MyView>
                                    </MyView>
                                ))}
                            </MyView>
                        </MyView>
                    </MyView>
                </MyView>
            </MyPage>
        </MyDocument>
    );
};
