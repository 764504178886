import { Row, Col, H1, P } from '@reassure/ui';
import { QuestionnaireBackButton } from 'components/questionnaire-back-button';
import { AboutStyles, QuestionContainer } from 'components/styles';
import { Link } from 'react-router-dom';

export const AboutWealth = () => {
    return (
        <QuestionContainer>
            <Row>
                <Col>
                    <AboutStyles>
                        <div role="region" aria-label="about worklife mid-life mot" className="about">
                            <H1 className="about__title">About your Wealth</H1>
                            <P className="about__content">
                                The Wealth section of the Mid-life MOT is here to help you assess and plan what you
                                might need to ensure that your savings and spending are on track for the financial
                                future that you want. Through the questions you answer today, you will get ideas and
                                feedback on some of the opportunities and choices that you might want to take.
                            </P>

                            <Row style={{ marginTop: 25, justifyContent: 'space-between' }}>
                                <QuestionnaireBackButton route={'/question-eleven'} />
                                <Link className="about__btn" to="/question-twelve">
                                    <span>Continue</span>
                                </Link>
                            </Row>
                        </div>
                    </AboutStyles>
                </Col>
            </Row>
        </QuestionContainer>
    );
};
