
export const Logo = () => {
    return (
        <svg width="169px" height="28px" viewBox="0 0 791 131" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Phoenix_Group_Brandmark_Primary_RGB</title>
            <defs>
                <polygon id="path-1" points="0 0 473.243 0 473.243 85.995 0 85.995"></polygon>
            </defs>
            <g id="Phoenix_Group_Brandmark_Primary_RGB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M128.245,0.161 L0.001,0.161 C0.001,10.421 8.428,19.581 18.321,19.581 L98.382,19.581 C103.329,19.581 111.389,17.382 115.238,13.168 C111.389,17.382 108.825,25.076 108.825,30.023 L108.825,110.085 C108.825,119.977 117.985,128.405 128.245,128.405 L128.245,0.161" id="Fill-1" fill="#DE4D5F"></path>
                <path d="M86.291,42.298 L0,42.298 C0,52.558 8.428,61.718 18.321,61.718 L57.528,61.718 C62.473,61.718 69.436,59.52 73.284,55.305 C69.436,59.52 66.87,66.116 66.87,71.061 L66.87,110.084 C66.87,119.978 76.03,128.405 86.291,128.405 L86.291,42.298" id="Fill-2" fill="#E36877"></path>
                <g id="Group-6" transform="translate(0.000000, 44.945000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <g id="Clip-4"></g>
                    <path d="M44.336,39.124 L0,39.124 C0,49.201 7.146,57.628 17.588,57.628 C21.985,57.628 26.382,56.528 29.497,53.964 C26.932,57.078 25.832,61.475 25.832,65.872 C25.832,76.315 34.076,83.461 44.336,83.461 L44.336,39.124" id="Fill-3" fill="#E88491" mask="url(#mask-2)"></path>
                    <path d="M429.664,68.587 C414.954,68.587 404.289,56.635 404.289,43.028 C404.289,29.421 414.954,17.469 429.664,17.469 C444.19,17.469 454.488,29.421 454.488,43.028 C454.488,56.635 444.19,68.587 429.664,68.587 Z M429.664,0 C404.657,0 385.533,18.02 385.533,43.028 C385.533,68.035 404.657,86.055 429.664,86.055 C454.671,86.055 473.243,68.035 473.243,43.028 C473.243,18.02 454.671,0 429.664,0 L429.664,0 Z" id="Fill-5" fill="#FFFFFE" mask="url(#mask-2)"></path>
                </g>
                <path d="M233.466,65.723 L209.378,65.723 L209.378,18.835 L233.466,18.835 C247.441,18.835 258.657,27.293 258.657,42.371 C258.657,57.449 247.441,65.723 233.466,65.723 Z M229.421,0.079 L189.151,0.079 L189.151,128.794 L209.378,128.794 L209.378,84.479 L229.421,84.479 C261.599,84.479 277.964,65.356 277.964,42.371 C277.964,19.386 261.599,0.079 229.421,0.079 L229.421,0.079 Z" id="Fill-7" fill="#FFFFFE"></path>
                <path d="M337.909,44.945 C324.669,44.945 316.027,50.829 311.798,60.207 C312.901,56.346 313.637,50.462 313.637,46.232 L313.637,18.835 C313.637,7.434 307.753,0.079 295.249,0.079 L294.881,0.079 L294.881,128.794 L313.637,128.794 L313.637,85.215 C313.637,70.872 320.808,62.23 332.944,62.23 C345.264,62.23 351.7,70.872 351.7,85.215 L351.7,128.794 L370.455,128.794 L370.455,82.456 C370.455,59.472 357.584,44.945 337.909,44.945" id="Fill-8" fill="#FFFFFE"></path>
                <path d="M627.701,44.945 C616.007,44.945 608.431,49.167 603.509,55.22 C602.237,49.887 600.228,47.152 594.051,47.152 L585.041,47.152 L585.041,128.794 L603.797,128.794 L603.797,85.95 C603.797,72.159 610.049,62.23 623.288,62.23 C636.527,62.23 641.859,72.159 641.859,85.95 L641.859,128.794 L660.615,128.794 L660.615,83.376 C660.615,58.92 647.744,44.945 627.701,44.945" id="Fill-9" fill="#FFFFFE"></path>
                <path d="M527.119,61.862 C541.208,61.862 549.452,70.766 550.564,80.25 L503.883,80.25 C506.585,68.836 515.519,61.862 527.119,61.862 Z M527.119,44.945 C502.48,44.945 485.195,63.885 485.195,87.789 C485.195,111.509 502.48,130.633 528.774,130.633 C542.198,130.633 553.414,126.403 562.792,114.267 L550.288,103.786 C544.404,110.222 539.255,113.716 529.142,113.716 C515.911,113.716 505.797,106.351 503.517,93.489 L567.757,93.489 C568.308,90.547 568.492,87.237 568.492,84.295 C568.492,62.414 552.311,44.945 527.119,44.945 L527.119,44.945 Z" id="Fill-10" fill="#FFFFFE"></path>
                <path d="M761.737,88.033 L790.801,47.152 L778.481,47.152 C771.678,47.152 767.081,49.542 763.035,55.426 L754.761,67.562 C752.922,70.321 751.635,72.343 750.899,75.285 C749.98,72.343 748.693,70.321 746.854,67.562 L738.763,55.426 C734.902,49.726 730.305,47.152 723.318,47.152 L711.549,47.152 L740.442,88.016 L711.182,128.794 L723.134,128.794 C730.121,128.794 734.902,126.22 738.579,120.335 L746.854,107.28 C748.325,105.073 749.612,103.051 750.715,99.557 C751.635,103.051 752.922,105.073 754.393,107.28 L762.851,120.335 C766.713,126.22 771.31,128.794 778.297,128.794 L790.985,128.794 L761.737,88.033" id="Fill-11" fill="#FFFFFE"></path>
                <path d="M687.829,3.94 C681.209,3.94 676.061,9.089 676.061,15.525 C676.061,22.144 681.209,27.293 687.829,27.293 C694.265,27.293 699.413,22.144 699.413,15.525 C699.413,9.089 694.265,3.94 687.829,3.94" id="Fill-12" fill="#FFFFFE"></path>
                <polygon id="Fill-13" fill="#FFFFFE" points="678.451 128.794 697.207 128.794 697.207 47.152 678.451 47.152"></polygon>
            </g>
        </svg>
    );
};