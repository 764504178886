import styled from 'styled-components';
import ArrowRight from '../../assets/images/arrow-black.svg';
import { breakpointDown } from '@reassure/ui';

export const QuestionnaireNextButtonStyles = styled.div`
    padding-right: 10px;
    a {
        color: ${({ theme }) => theme.colors.charcoal};
        text-decoration: none;
        border: 2px solid ${({ theme }) => theme.colors.ember};
        display: block;
        border-radius: 4px;
        font-family: 'PhoenixSansMediumWeb';
        font-size: ${({ theme }) => theme.fontSizes[4]}; // 16px
        line-height: 22px;
        max-width: 400px;
        height: 48px;
        padding: 11px 30px 10px 25px;
        display: flex;
        align-items: center;
        gap: 100px;
        transition: border-bottom-right-radius 180ms ease-in-out;

        ${breakpointDown.xs`
            margin-left: 10px;
        `}

        &:hover {
            border-bottom-right-radius: 22px;
        }

        &:after {
            content: '';
            background: url(${ArrowRight}) no-repeat center center;
            height: 32px;
            width: 32px;
            transition: background 0.5s ease-in-out;
        }
    }
`;
