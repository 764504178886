import styled from 'styled-components';
import { breakpointDown } from '@reassure/ui';
import BackgroundWellbeing from '../../assets/images/background-wellbeing.svg';
import BackgroundWorklife from '../../assets/images/background-worklife.svg';
import BackgroundWealth from '../../assets/images/background-wealth.svg';

export const SectionTipsStyles = styled.div`
    .section-tips {
        &__section {
            h2 {
                margin: 20px 0;
                font-family: 'PhoenixSansBoldWeb';
                font-size: 28px;
                line-height: ${({ theme }) => theme.sizes[7]}!important; // 32px
                display: flex;
                align-items: center;
                gap: 15px;

                ${breakpointDown.md`
                    text-align: left;
                `}
            }
        }

        img {
            width: 21px;
            height: 21px;
            display: block;
        }

        &__bold {
            font-family: 'PhoenixSansBoldWeb';
        }

        &__row {
            ${breakpointDown.md`
              padding: 20px 0;
            `};
        }

        &__sub-title {
            color: ${({ theme }) => theme.colors.white};

            margin-bottom: 20px;
        }

        &__info-box {
            background-color: ${({ theme }) => theme.colors.aubergine};
            text-align: center;

            p {
                padding: 20px;
                line-height: ${({ theme }) => theme.fontSizes[6]}!important; // 20px;
                font-size: ${({ theme }) => theme.fontSizes[4]}!important; //16px;
            }
        }

        &__icon {
            background-color: ${({ theme }) => theme.colors.white};
            border-radius: 100%;
            padding: 10px;

            &--wellbeing {
                border: 4px solid ${({ theme }) => theme.colors.purple};
            }

            &--worklife {
                border: 4px solid ${({ theme }) => theme.colors.teal};
            }

            &--wealth {
                border: 4px solid ${({ theme }) => theme.colors.green};
            }
        }

        &__wellbeing {
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.07);
        }

        &__worklife {
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.07);
            margin: 12px 0;
        }

        &__wealth {
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.07);
            margin-bottom: 26px;
        }

        &__header {
            display: flex;
            align-items: center;
            border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey};
        }

        &__header-background {
            padding: 20px 20px 20px 40px;
            display: flex;
            gap: 20px;
            width: 83px;
            justify-content: center;
            align-items: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right center;

            &--wellbeing {
                background-image: url(${BackgroundWellbeing});
            }

            &--worklife {
                background-image: url(${BackgroundWorklife});
            }

            &--wealth {
                background-image: url(${BackgroundWealth});
            }
        }

        &__section-name {
            padding: 28px 20px;
            vertical-align: top;
            font-family: 'PhoenixSansBoldWeb';
            line-height: ${({ theme }) => theme.sizes[6]}!important; // 24px;
            font-size: ${({ theme }) => theme.fontSizes[8]}!important; //24px;
            color: ${({ theme }) => theme.colors.charcoal};

            ${breakpointDown.md`
               width: 15%;
            `};
        }

        &__content-container {
            border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey};
            position: relative;
            padding: 20px;

            ${breakpointDown.md`
                padding: 20px;
            `};

            div {
                color: ${({ theme }) => theme.colors.charcoal};
                font-size: ${({ theme }) => theme.fontSizes[5]}!important; //18px;
                line-height: ${({ theme }) => theme.sizes[6]}!important; // 24px;
            }
        }

        &__inner-content {
            padding-right: 100px;

            p {
                font-size: ${({ theme }) => theme.fontSizes[4]}!important; //16px;
            }
        }

        &__link-container {
            display: flex;
            gap: 20px;
            margin-top: 10px;
        }

        &__link {
            color: ${({ theme }) => theme.colors.charcoal};
            font-family: 'PhoenixSansRegularWeb';
            font-size: ${({ theme }) => theme.fontSizes[4]}!important; //16px;
            text-decoration: underline;
            -webkit-text-decoration-color: ${({ theme }) => theme.colors.ember}; /* Safari */
            text-decoration-color: ${({ theme }) => theme.colors.ember};
        }

        &__tick-box {
            position: absolute;
            bottom: 20px;
            right: 20px;
            border-radius: 2px;
            height: 25px;
            width: 25px;
            border: 2px solid ${({ theme }) => theme.colors.lightgrey};

            ${breakpointDown.md`
                display: none;
            `};
        }
    }
`;
