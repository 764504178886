import { Svg, Path } from '@react-pdf/renderer';
import { styles } from './results-pdf.styles';

const MySvg: any = Svg;
const MyPath: any = Path;

export const WorklifeIcon = () => (
    <MySvg style={styles.results_icon} viewBox="0 0 33 31">
        <MyPath
            d="M2.475 31C1.815 31 1.2375 30.7553 0.7425 30.2658C0.2475 29.7763 0 29.2053 0 28.5526V8.97368C0 8.32105 0.2475 7.75 0.7425 7.26053C1.2375 6.77105 1.815 6.52632 2.475 6.52632H9.9V2.44737C9.9 1.79474 10.1475 1.22368 10.6425 0.734211C11.1375 0.244737 11.715 0 12.375 0H20.625C21.285 0 21.8625 0.244737 22.3575 0.734211C22.8525 1.22368 23.1 1.79474 23.1 2.44737V6.52632H30.525C31.185 6.52632 31.7625 6.77105 32.2575 7.26053C32.7525 7.75 33 8.32105 33 8.97368V28.5526C33 29.2053 32.7525 29.7763 32.2575 30.2658C31.7625 30.7553 31.185 31 30.525 31H2.475ZM2.475 28.5526H30.525V8.97368H2.475V28.5526ZM12.375 6.52632H20.625V2.44737H12.375V6.52632Z"
            fill="#49A6B8"
        />
    </MySvg>
);
