import { H2, P, Link } from '@reassure/ui';
import { TopTip } from 'graphql/generated';
import { TopTipsStyles } from './top-tips.styles';
import IconWellbeing from '../../assets/images/icon-wellbeing.svg';
import IconWorklife from '../../assets/images/icon-worklife.svg';
import IconWealth from '../../assets/images/icon-wealth.svg';
interface TopTipsProps {
    topTips: [TopTip];
}

export const TopTips = ({ topTips }: TopTipsProps) => {
    return (
        <TopTipsStyles>
            <div className="top-tips">
                <div className="top-tips__section">
                    <H2 className="top-tips__header">Explore the top five tips to improve your score</H2>

                    {topTips.map((item, index) => (
                        <div key={index} className="top-tips__card">
                            <div className={'top-tips__card-section top-tips__card-section--' + item?.section}>
                                <div className="top-tips__number">0{index + 1}</div>
                                {item?.section === 'wellbeing' && (
                                    <div className={'top-tips__icon top-tips__icon--' + item?.section}>
                                        <img src={IconWellbeing} alt="icon" />{' '}
                                    </div>
                                )}
                                {item?.section === 'worklife' && (
                                    <div className={'top-tips__icon top-tips__icon--' + item?.section}>
                                        <img src={IconWorklife} alt="icon" />{' '}
                                    </div>
                                )}
                                {item?.section === 'wealth' && (
                                    <div className={'top-tips__icon top-tips__icon--' + item?.section}>
                                        <img src={IconWealth} alt="icon" />{' '}
                                    </div>
                                )}
                            </div>

                            <div className="top-tips__content">
                                <P>
                                    {item?.text}
                                    <br />

                                    <span className="top-tips__link-container">
                                        {item?.link_1_url !== '' && (
                                            <Link href={item?.link_1_url} target="_blank" className="top-tips__link">
                                                {item?.link_1}
                                            </Link>
                                        )}
                                        {item?.link_2_url !== '' && (
                                            <Link href={item?.link_2_url} target="_blank" className="top-tips__link">
                                                {item?.link_2}
                                            </Link>
                                        )}
                                        {item?.link_3_url !== '' && (
                                            <Link href={item?.link_3_url} target="_blank" className="top-tips__link">
                                                {item?.link_3}
                                            </Link>
                                        )}
                                        {item?.link_4_url !== '' && (
                                            <Link href={item?.link_4_url} target="_blank" className="top-tips__link">
                                                {item?.link_4}
                                            </Link>
                                        )}
                                        {item?.link_5_url !== '' && (
                                            <Link href={item?.link_5_url} target="_blank" className="top-tips__link">
                                                {item?.link_5}
                                            </Link>
                                        )}
                                        {item?.link_6_url !== '' && (
                                            <Link href={item?.link_6_url} target="_blank" className="top-tips__link">
                                                {item?.link_6}
                                            </Link>
                                        )}
                                    </span>
                                </P>
                                <div className="top-tips__tick-box"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </TopTipsStyles>
    );
};
