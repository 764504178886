import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { AppProvider } from '@reassure/ui';

import { client } from 'graphql/client';
import theme from 'theme';
import { App } from './app';
import './fonts.css';
import { SkipToContent } from 'components/skip-to-content';

const headStyles = {
    customCss: `
        body {
            background-color: ${theme.colors.body.background};
            color: ${theme.colors.primaryText};
            font-family: 'PhoenixSansRegularWeb';
        }
        h1, h2, h3, h4 {
            color: ${theme.colors.heading.color}!important;
            font-family: 'PhoenixSansBoldWeb';
            line-height: 36px!important;
        }
        p {
            font-size: 18px!important;
            line-height: 24px!important;
        }
      `,
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <AppProvider theme={theme} head={headStyles}>
        <ApolloProvider client={client}>
            <>
                <SkipToContent />
                <App />
            </>
        </ApolloProvider>
    </AppProvider>
);
