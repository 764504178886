import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    AWSDateTime: any;
};

export type Answer = {
    __typename?: 'Answer';
    answer: Scalars['String'];
    answerText: Scalars['String'];
    question: Scalars['String'];
    section: Scalars['String'];
};

export type Answers = {
    __typename?: 'Answers';
    wealthA?: Maybe<Array<Maybe<Answer>>>;
    wellbeingA?: Maybe<Array<Maybe<Answer>>>;
    worklifeA?: Maybe<Array<Maybe<Answer>>>;
};

export type FeedbackInput = {
    anythingElse?: InputMaybe<Scalars['String']>;
    howEasyRating?: InputMaybe<Scalars['String']>;
    overallExperienceRating?: InputMaybe<Scalars['String']>;
    takeActionFromTips?: InputMaybe<Scalars['String']>;
};

export type FormData = {
    wealth1: Scalars['String'];
    wealth2: Scalars['String'];
    wealth3: Scalars['String'];
    wealth4: Scalars['String'];
    wealth5: Scalars['String'];
    wealth6: Scalars['String'];
    wealth7: Scalars['String'];
    wellbeing1: Scalars['String'];
    wellbeing2: Scalars['String'];
    wellbeing3: Scalars['String'];
    wellbeing4: Scalars['String'];
    wellbeing5: Scalars['String'];
    wellbeing6: Scalars['String'];
    worklife1: Scalars['String'];
    worklife2: Scalars['String'];
    worklife3: Scalars['String'];
    worklife4: Scalars['String'];
    worklife5: Scalars['String'];
};

export type MidlifeMotData = {
    __typename?: 'MidlifeMOTData';
    completedMOTAt?: Maybe<Scalars['AWSDateTime']>;
    createdAt: Scalars['AWSDateTime'];
    openedMOTAt?: Maybe<Scalars['AWSDateTime']>;
    reminderAt?: Maybe<Scalars['AWSDateTime']>;
    savedPDFAt?: Maybe<Scalars['AWSDateTime']>;
    sessionID: Scalars['ID'];
    startedMOTAt?: Maybe<Scalars['AWSDateTime']>;
    ttl: Scalars['Int'];
    updatedAt: Scalars['AWSDateTime'];
    wealthScore?: Maybe<Scalars['Int']>;
    wellbeingScore?: Maybe<Scalars['Int']>;
    worklifeScore?: Maybe<Scalars['Int']>;
};

export enum ModelAttributeTypes {
    Null = '_null',
    Binary = 'binary',
    BinarySet = 'binarySet',
    Bool = 'bool',
    List = 'list',
    Map = 'map',
    Number = 'number',
    NumberSet = 'numberSet',
    String = 'string',
    StringSet = 'stringSet',
}

export type ModelBooleanInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']>;
    attributeType?: InputMaybe<ModelAttributeTypes>;
    eq?: InputMaybe<Scalars['Boolean']>;
    ne?: InputMaybe<Scalars['Boolean']>;
};

export type ModelFloatInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']>;
    attributeType?: InputMaybe<ModelAttributeTypes>;
    between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
    eq?: InputMaybe<Scalars['Float']>;
    ge?: InputMaybe<Scalars['Float']>;
    gt?: InputMaybe<Scalars['Float']>;
    le?: InputMaybe<Scalars['Float']>;
    lt?: InputMaybe<Scalars['Float']>;
    ne?: InputMaybe<Scalars['Float']>;
};

export type ModelIdInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']>;
    attributeType?: InputMaybe<ModelAttributeTypes>;
    beginsWith?: InputMaybe<Scalars['ID']>;
    between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    contains?: InputMaybe<Scalars['ID']>;
    eq?: InputMaybe<Scalars['ID']>;
    ge?: InputMaybe<Scalars['ID']>;
    gt?: InputMaybe<Scalars['ID']>;
    le?: InputMaybe<Scalars['ID']>;
    lt?: InputMaybe<Scalars['ID']>;
    ne?: InputMaybe<Scalars['ID']>;
    notContains?: InputMaybe<Scalars['ID']>;
    size?: InputMaybe<ModelSizeInput>;
};

export type ModelIntInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']>;
    attributeType?: InputMaybe<ModelAttributeTypes>;
    between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    eq?: InputMaybe<Scalars['Int']>;
    ge?: InputMaybe<Scalars['Int']>;
    gt?: InputMaybe<Scalars['Int']>;
    le?: InputMaybe<Scalars['Int']>;
    lt?: InputMaybe<Scalars['Int']>;
    ne?: InputMaybe<Scalars['Int']>;
};

export type ModelSizeInput = {
    between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    eq?: InputMaybe<Scalars['Int']>;
    ge?: InputMaybe<Scalars['Int']>;
    gt?: InputMaybe<Scalars['Int']>;
    le?: InputMaybe<Scalars['Int']>;
    lt?: InputMaybe<Scalars['Int']>;
    ne?: InputMaybe<Scalars['Int']>;
};

export enum ModelSortDirection {
    Asc = 'ASC',
    Desc = 'DESC',
}

export type ModelStringInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']>;
    attributeType?: InputMaybe<ModelAttributeTypes>;
    beginsWith?: InputMaybe<Scalars['String']>;
    between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    contains?: InputMaybe<Scalars['String']>;
    eq?: InputMaybe<Scalars['String']>;
    ge?: InputMaybe<Scalars['String']>;
    gt?: InputMaybe<Scalars['String']>;
    le?: InputMaybe<Scalars['String']>;
    lt?: InputMaybe<Scalars['String']>;
    ne?: InputMaybe<Scalars['String']>;
    notContains?: InputMaybe<Scalars['String']>;
    size?: InputMaybe<ModelSizeInput>;
};

export type OverallSectionParagraphs = {
    __typename?: 'OverallSectionParagraphs';
    wealthParagraph?: Maybe<Scalars['String']>;
    wellbeingParagraph?: Maybe<Scalars['String']>;
    worklifeParagraph?: Maybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    calculateResult: Result;
    createSession?: Maybe<Scalars['String']>;
    submitFeedback?: Maybe<Scalars['Boolean']>;
    updateSessionEvent?: Maybe<Scalars['Boolean']>;
    updateSessionResult?: Maybe<Scalars['Boolean']>;
};

export type QueryCalculateResultArgs = {
    input: FormData;
};

export type QuerySubmitFeedbackArgs = {
    input?: InputMaybe<FeedbackInput>;
};

export type QueryUpdateSessionEventArgs = {
    input?: InputMaybe<SessionEventInput>;
};

export type QueryUpdateSessionResultArgs = {
    input?: InputMaybe<SessionResultInput>;
};

export type Result = {
    __typename?: 'Result';
    allSectionTips?: Maybe<AllSectionTips>;
    answers?: Maybe<Answers>;
    overallSectionParagraphs?: Maybe<OverallSectionParagraphs>;
    score?: Maybe<Score>;
    topTips?: Maybe<Array<Maybe<TopTip>>>;
    totalPercentage?: Maybe<Scalars['Int']>;
};

export type Score = {
    __typename?: 'Score';
    overallMessage?: Maybe<Scalars['String']>;
    wealthScore?: Maybe<Scalars['Int']>;
    wellbeingScore?: Maybe<Scalars['Int']>;
    worklifeScore?: Maybe<Scalars['Int']>;
};

export type SessionEventInput = {
    sessionEvent?: InputMaybe<Scalars['String']>;
    sessionID?: InputMaybe<Scalars['String']>;
};

export type SessionResultInput = {
    sessionID?: InputMaybe<Scalars['String']>;
    wealthScore?: InputMaybe<Scalars['Int']>;
    wellbeingScore?: InputMaybe<Scalars['Int']>;
    worklifeScore?: InputMaybe<Scalars['Int']>;
};

export type AllSectionTips = {
    __typename?: 'allSectionTips';
    wealthTips?: Maybe<Array<Maybe<TopTip>>>;
    wellbeingTips?: Maybe<Array<Maybe<TopTip>>>;
    worklifeTips?: Maybe<Array<Maybe<TopTip>>>;
};

export type TopTip = {
    __typename?: 'topTip';
    link_1?: Maybe<Scalars['String']>;
    link_1_url?: Maybe<Scalars['String']>;
    link_2?: Maybe<Scalars['String']>;
    link_2_url?: Maybe<Scalars['String']>;
    link_3?: Maybe<Scalars['String']>;
    link_3_url?: Maybe<Scalars['String']>;
    link_4?: Maybe<Scalars['String']>;
    link_4_url?: Maybe<Scalars['String']>;
    link_5?: Maybe<Scalars['String']>;
    link_5_url?: Maybe<Scalars['String']>;
    link_6?: Maybe<Scalars['String']>;
    link_6_url?: Maybe<Scalars['String']>;
    section?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
};

export type CalculateResultQueryVariables = Exact<{
    input: FormData;
}>;

export type CalculateResultQuery = {
    __typename?: 'Query';
    calculateResult: {
        __typename?: 'Result';
        totalPercentage?: number | null;
        answers?: {
            __typename?: 'Answers';
            wellbeingA?: Array<{
                __typename?: 'Answer';
                section: string;
                question: string;
                answer: string;
                answerText: string;
            } | null> | null;
            worklifeA?: Array<{
                __typename?: 'Answer';
                section: string;
                question: string;
                answer: string;
                answerText: string;
            } | null> | null;
            wealthA?: Array<{
                __typename?: 'Answer';
                section: string;
                question: string;
                answer: string;
                answerText: string;
            } | null> | null;
        } | null;
        score?: {
            __typename?: 'Score';
            wellbeingScore?: number | null;
            worklifeScore?: number | null;
            wealthScore?: number | null;
            overallMessage?: string | null;
        } | null;
        overallSectionParagraphs?: {
            __typename?: 'OverallSectionParagraphs';
            wellbeingParagraph?: string | null;
            worklifeParagraph?: string | null;
            wealthParagraph?: string | null;
        } | null;
        topTips?: Array<{
            __typename?: 'topTip';
            section?: string | null;
            text?: string | null;
            link_1?: string | null;
            link_1_url?: string | null;
            link_2?: string | null;
            link_2_url?: string | null;
            link_3?: string | null;
            link_3_url?: string | null;
            link_4?: string | null;
            link_4_url?: string | null;
            link_5?: string | null;
            link_5_url?: string | null;
            link_6?: string | null;
            link_6_url?: string | null;
        } | null> | null;
        allSectionTips?: {
            __typename?: 'allSectionTips';
            wellbeingTips?: Array<{
                __typename?: 'topTip';
                section?: string | null;
                text?: string | null;
                link_1?: string | null;
                link_1_url?: string | null;
                link_2?: string | null;
                link_2_url?: string | null;
                link_3?: string | null;
                link_3_url?: string | null;
                link_4?: string | null;
                link_4_url?: string | null;
                link_5?: string | null;
                link_5_url?: string | null;
                link_6?: string | null;
                link_6_url?: string | null;
            } | null> | null;
            worklifeTips?: Array<{
                __typename?: 'topTip';
                section?: string | null;
                text?: string | null;
                link_1?: string | null;
                link_1_url?: string | null;
                link_2?: string | null;
                link_2_url?: string | null;
                link_3?: string | null;
                link_3_url?: string | null;
                link_4?: string | null;
                link_4_url?: string | null;
                link_5?: string | null;
                link_5_url?: string | null;
                link_6?: string | null;
                link_6_url?: string | null;
            } | null> | null;
            wealthTips?: Array<{
                __typename?: 'topTip';
                section?: string | null;
                text?: string | null;
                link_1?: string | null;
                link_1_url?: string | null;
                link_2?: string | null;
                link_2_url?: string | null;
                link_3?: string | null;
                link_3_url?: string | null;
                link_4?: string | null;
                link_4_url?: string | null;
                link_5?: string | null;
                link_5_url?: string | null;
                link_6?: string | null;
                link_6_url?: string | null;
            } | null> | null;
        } | null;
    };
};

export type CreateSessionQueryVariables = Exact<{ [key: string]: never }>;

export type CreateSessionQuery = { __typename?: 'Query'; createSession?: string | null };

export type UpdateSessionEventQueryVariables = Exact<{
    input?: InputMaybe<SessionEventInput>;
}>;

export type UpdateSessionEventQuery = { __typename?: 'Query'; updateSessionEvent?: boolean | null };

export type UpdateSessionResultQueryVariables = Exact<{
    input?: InputMaybe<SessionResultInput>;
}>;

export type UpdateSessionResultQuery = { __typename?: 'Query'; updateSessionResult?: boolean | null };

export type SubmitFeedbackQueryVariables = Exact<{
    input?: InputMaybe<FeedbackInput>;
}>;

export type SubmitFeedbackQuery = { __typename?: 'Query'; submitFeedback?: boolean | null };

export const CalculateResultDocument = gql`
    query CalculateResult($input: FormData!) {
        calculateResult(input: $input) {
            totalPercentage
            answers {
                wellbeingA {
                    section
                    question
                    answer
                    answerText
                }
                worklifeA {
                    section
                    question
                    answer
                    answerText
                }
                wealthA {
                    section
                    question
                    answer
                    answerText
                }
            }
            score {
                wellbeingScore
                worklifeScore
                wealthScore
                overallMessage
            }
            overallSectionParagraphs {
                wellbeingParagraph
                worklifeParagraph
                wealthParagraph
            }
            topTips {
                section
                text
                link_1
                link_1_url
                link_2
                link_2_url
                link_3
                link_3_url
                link_4
                link_4_url
                link_5
                link_5_url
                link_6
                link_6_url
            }
            allSectionTips {
                wellbeingTips {
                    section
                    text
                    link_1
                    link_1_url
                    link_2
                    link_2_url
                    link_3
                    link_3_url
                    link_4
                    link_4_url
                    link_5
                    link_5_url
                    link_6
                    link_6_url
                }
                worklifeTips {
                    section
                    text
                    link_1
                    link_1_url
                    link_2
                    link_2_url
                    link_3
                    link_3_url
                    link_4
                    link_4_url
                    link_5
                    link_5_url
                    link_6
                    link_6_url
                }
                wealthTips {
                    section
                    text
                    link_1
                    link_1_url
                    link_2
                    link_2_url
                    link_3
                    link_3_url
                    link_4
                    link_4_url
                    link_5
                    link_5_url
                    link_6
                    link_6_url
                }
            }
        }
    }
`;

/**
 * __useCalculateResultQuery__
 *
 * To run a query within a React component, call `useCalculateResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateResultQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateResultQuery(
    baseOptions: Apollo.QueryHookOptions<CalculateResultQuery, CalculateResultQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CalculateResultQuery, CalculateResultQueryVariables>(CalculateResultDocument, options);
}
export function useCalculateResultLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CalculateResultQuery, CalculateResultQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CalculateResultQuery, CalculateResultQueryVariables>(CalculateResultDocument, options);
}
export type CalculateResultQueryHookResult = ReturnType<typeof useCalculateResultQuery>;
export type CalculateResultLazyQueryHookResult = ReturnType<typeof useCalculateResultLazyQuery>;
export type CalculateResultQueryResult = Apollo.QueryResult<CalculateResultQuery, CalculateResultQueryVariables>;
export const CreateSessionDocument = gql`
    query CreateSession {
        createSession
    }
`;

/**
 * __useCreateSessionQuery__
 *
 * To run a query within a React component, call `useCreateSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateSessionQuery(
    baseOptions?: Apollo.QueryHookOptions<CreateSessionQuery, CreateSessionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CreateSessionQuery, CreateSessionQueryVariables>(CreateSessionDocument, options);
}
export function useCreateSessionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CreateSessionQuery, CreateSessionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CreateSessionQuery, CreateSessionQueryVariables>(CreateSessionDocument, options);
}
export type CreateSessionQueryHookResult = ReturnType<typeof useCreateSessionQuery>;
export type CreateSessionLazyQueryHookResult = ReturnType<typeof useCreateSessionLazyQuery>;
export type CreateSessionQueryResult = Apollo.QueryResult<CreateSessionQuery, CreateSessionQueryVariables>;
export const UpdateSessionEventDocument = gql`
    query UpdateSessionEvent($input: SessionEventInput) {
        updateSessionEvent(input: $input)
    }
`;

/**
 * __useUpdateSessionEventQuery__
 *
 * To run a query within a React component, call `useUpdateSessionEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateSessionEventQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionEventQuery(
    baseOptions?: Apollo.QueryHookOptions<UpdateSessionEventQuery, UpdateSessionEventQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UpdateSessionEventQuery, UpdateSessionEventQueryVariables>(
        UpdateSessionEventDocument,
        options
    );
}
export function useUpdateSessionEventLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UpdateSessionEventQuery, UpdateSessionEventQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UpdateSessionEventQuery, UpdateSessionEventQueryVariables>(
        UpdateSessionEventDocument,
        options
    );
}
export type UpdateSessionEventQueryHookResult = ReturnType<typeof useUpdateSessionEventQuery>;
export type UpdateSessionEventLazyQueryHookResult = ReturnType<typeof useUpdateSessionEventLazyQuery>;
export type UpdateSessionEventQueryResult = Apollo.QueryResult<
    UpdateSessionEventQuery,
    UpdateSessionEventQueryVariables
>;
export const UpdateSessionResultDocument = gql`
    query UpdateSessionResult($input: SessionResultInput) {
        updateSessionResult(input: $input)
    }
`;

/**
 * __useUpdateSessionResultQuery__
 *
 * To run a query within a React component, call `useUpdateSessionResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateSessionResultQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionResultQuery(
    baseOptions?: Apollo.QueryHookOptions<UpdateSessionResultQuery, UpdateSessionResultQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UpdateSessionResultQuery, UpdateSessionResultQueryVariables>(
        UpdateSessionResultDocument,
        options
    );
}
export function useUpdateSessionResultLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UpdateSessionResultQuery, UpdateSessionResultQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UpdateSessionResultQuery, UpdateSessionResultQueryVariables>(
        UpdateSessionResultDocument,
        options
    );
}
export type UpdateSessionResultQueryHookResult = ReturnType<typeof useUpdateSessionResultQuery>;
export type UpdateSessionResultLazyQueryHookResult = ReturnType<typeof useUpdateSessionResultLazyQuery>;
export type UpdateSessionResultQueryResult = Apollo.QueryResult<
    UpdateSessionResultQuery,
    UpdateSessionResultQueryVariables
>;
export const SubmitFeedbackDocument = gql`
    query SubmitFeedback($input: FeedbackInput) {
        submitFeedback(input: $input)
    }
`;

/**
 * __useSubmitFeedbackQuery__
 *
 * To run a query within a React component, call `useSubmitFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmitFeedbackQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFeedbackQuery(
    baseOptions?: Apollo.QueryHookOptions<SubmitFeedbackQuery, SubmitFeedbackQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SubmitFeedbackQuery, SubmitFeedbackQueryVariables>(SubmitFeedbackDocument, options);
}
export function useSubmitFeedbackLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SubmitFeedbackQuery, SubmitFeedbackQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SubmitFeedbackQuery, SubmitFeedbackQueryVariables>(SubmitFeedbackDocument, options);
}
export type SubmitFeedbackQueryHookResult = ReturnType<typeof useSubmitFeedbackQuery>;
export type SubmitFeedbackLazyQueryHookResult = ReturnType<typeof useSubmitFeedbackLazyQuery>;
export type SubmitFeedbackQueryResult = Apollo.QueryResult<SubmitFeedbackQuery, SubmitFeedbackQueryVariables>;
