import { Row, Col, H1, P, Link } from '@reassure/ui';
import { RetirementStyles, RURetirementContainer, RetirementIntranetBackground } from './retirement-intranet.styles';

export const RetirementIntranet = () => {
    return (
        <RetirementIntranetBackground>
            <RURetirementContainer>
                <Row>
                    <Col>
                        <RetirementStyles>
                            <div role="region" aria-label="retirement mid-life mot" className="retirement">
                                <H1 className="retirement__title">
                                    If you are looking for additional tools and resources, we have some suggestions
                                    below
                                </H1>
                                <ul className="retirement__outer-list">
                                    <li>
                                        <P>Wellbeing</P>
                                        <ul className="retirement__inner-list">
                                            <li>
                                                <P>
                                                    Our Health & Wellbeing platform provides content on fitness,
                                                    nutrition, and mental health. Visit{' '}
                                                    <Link href="https://landg.spectrum.life/login" target="blank">
                                                        Spectrum.Life
                                                    </Link>
                                                </P>
                                            </li>
                                            <li>
                                                <P>
                                                    The Menopause can be a particularly sensitive and personal issue -
                                                    talking openly and respectfully about it can benefit everyone. Find{' '}
                                                    {''}
                                                    <Link
                                                        href="https://phoenixgroup365.sharepoint.com/sites/OurPlace-Wellbeing/SitePages/Menopause(1).aspx"
                                                        target="blank"
                                                    >
                                                        more information on Menopause here
                                                    </Link>
                                                </P>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <P>Work Life</P>
                                        <ul className="retirement__inner-list">
                                            <li>
                                                <P>
                                                    Shape your future and development goals with {''}
                                                    <Link
                                                        href="https://phoenixgroup365.sharepoint.com/sites/OurPlace-LD"
                                                        target="blank"
                                                    >
                                                        Thrive at Phoenix
                                                    </Link>
                                                </P>
                                            </li>
                                            <li>
                                                <P>
                                                    Whether you're new to technology or looking to build your skills, we
                                                    have a wide range of guides and videos that can help. Visit our {''}
                                                    <Link
                                                        href="https://www.standardlife.co.uk/help/digital-skills-hub"
                                                        target="blank"
                                                    >
                                                        Digital Skills Hub
                                                    </Link>
                                                </P>
                                            </li>
                                            <li>
                                                <P>
                                                    Our Hybrid working guidelines can be found {''}
                                                    <Link
                                                        href="https://fa-enor-saasfaprod1.fa.ocs.oraclecloud.com/fscmUI/faces/deeplink?objType=CSO_ARTICLE_CONTENT_HCM&objKey=docId%3DHFAQ354%3Blocale%3Den_GB&action=EDIT_IN_TAB"
                                                        target="blank"
                                                    >
                                                        here
                                                    </Link>
                                                </P>
                                            </li>
                                            <li>
                                                <P>
                                                    Our Flexible working policy can be found {''}
                                                    <Link
                                                        href="https://fa-enor-saasfaprod1.fa.ocs.oraclecloud.com/fscmUI/faces/deeplink?objType=CSO_ARTICLE_CONTENT_HCM&objKey=docId%3DHFAQ384%3Blocale%3Den_GB&action=EDIT_IN_TAB"
                                                        target="blank"
                                                    >
                                                        here
                                                    </Link>
                                                </P>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <P>Wealth</P>
                                        <ul className="retirement__inner-list">
                                            <li>
                                                <P>
                                                    Access a range of tools and resources to support you in and outside
                                                    of the workplace, including Nudge and Money Mindset, at our {''}
                                                    <Link
                                                        href="https://phoenixgroup365.sharepoint.com/sites/ourplace-financialeducation?e=1%3ad734185b6b524727bf8b774d7ca1eaba&OR=Teams-HL&CT=1678197468038"
                                                        target="blank"
                                                    >
                                                        Financial Support & Education hub,
                                                    </Link>
                                                    {''} including;
                                                </P>
                                            </li>
                                            <li>
                                                <P>
                                                    This {''}
                                                    <Link
                                                        href="https://www.standardlife.co.uk/retirement/guides/saving-for-retirement"
                                                        target="blank"
                                                    >
                                                        saving for retirement guide
                                                    </Link>
                                                    {''} covers some key areas to think about{' '}
                                                </P>
                                            </li>
                                            <li>
                                                <P>
                                                    Find a financial expert at {''}
                                                    <Link href="https://www.unbiased.co.uk/" target="_blank">
                                                        unbiased.co.uk
                                                    </Link>
                                                </P>
                                            </li>
                                            <li>
                                                <P>
                                                    Assess your attitude to risk with this {''}
                                                    <Link
                                                        href="https://www.standardlife.co.uk/investments/tools/investment-risk"
                                                        target="blank"
                                                    >
                                                        tool
                                                    </Link>
                                                </P>
                                            </li>
                                            <li>
                                                <P>
                                                    <Link
                                                        href="https://www.moneyhelper.org.uk/en/pensions-and-retirement/pension-wise"
                                                        target="blank"
                                                    >
                                                        Pension Wise
                                                    </Link>
                                                    {''} offer a free appointment to discuss your pension access options
                                                </P>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </RetirementStyles>
                    </Col>
                </Row>
            </RURetirementContainer>
        </RetirementIntranetBackground>
    );
};
