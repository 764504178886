import { useEffect } from 'react';

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

type LocationProps = {
    state: {
        from: Location;
    };
};

export const Login = () => {
    const { route } = useAuthenticator(context => [context.route]);
    const location = useLocation() as unknown as LocationProps;
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || '/';

    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true });
            return;
        }
    }, [route, navigate, from]);

    return process.env.REACT_APP_AUTH_DOMAIN === '' ? (
        <View className="auth-wrapper">
            <Authenticator hideSignUp={true}></Authenticator>
        </View>
    ) : (
        <span />
    );
};
