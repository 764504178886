import { Container, H3, P, Row, Col } from '@reassure/ui';
import { HomeIntroCategoriesStyle } from './home-intro-categories.styles';
import wellbeingImg from '../../assets/images/homewellbeing.png';
import worklifeImg from '../../assets/images/homeworklife.png';
import wealthImg from '../../assets/images/homewealth.png';

export const HomeIntroCategories = () => {
    return (
        <HomeIntroCategoriesStyle>
            <Container>
                <Row>
                    <Col role="region" aria-label="intro wellbeing" col="12" lg="4">
                        <img className="mid-life__intro-image" src={wellbeingImg} alt="people doing gardening" />
                        <H3 className="mid-life__intro-categories-heading">Wellbeing</H3>
                        <P>
                            While many people are living longer lives than previous generations, not all of those years
                            are lived in good health. Thinking about your mental and physical health and wellbeing are
                            an essential part of planning for your future. While nothing changes overnight, many people
                            do experience changes to their health and wellbeing at different stages of their lives.
                            Evidence shows that often small changes in mid-life can have large benefits as you grow
                            older.
                        </P>
                    </Col>

                    <Col role="region" aria-label="intro work-life" col="12" lg="4">
                        <img className="mid-life__intro-image" src={worklifeImg} alt="people working together" />
                        <H3 className="mid-life__intro-categories-heading">Work-life</H3>
                        <P>
                            We spend a large part of our lives at work and getting the right balance between our career
                            ambitions and our wider lives is essential. Work matters to people in terms of their
                            financial wellbeing and ability to save, but for many people it also matters in terms of our
                            sense of purpose and meaning. Interestingly studies have shown that retired people are most
                            likely to say that they miss the social aspects of work, even more than the finances. So,
                            feeling in control of our work and careers is important to help us plan for how and when we
                            want to retire.
                        </P>
                    </Col>

                    <Col role="region" aria-label="intro wealth" col="12" lg="4">
                        <img
                            className="mid-life__intro-image"
                            src={wealthImg}
                            alt="a person buying some books in a store"
                        />
                        <H3 className="mid-life__intro-categories-heading">Wealth</H3>
                        <P>
                            The Mid-life MOT is designed to provide a useful check point in the middle years of your
                            life - reflecting on your current situation and preparing for the future. A key factor for
                            people's current and future life is around financial wellbeing, to balance day-to-day
                            spending with future retirement needs. There is a wide range of support out there to help
                            inform how we plan our finances and think about what our future spending needs might be. The
                            earlier that you can start this planning, and take positive steps, the greater the impact
                            will be.
                        </P>
                    </Col>
                </Row>
            </Container>
        </HomeIntroCategoriesStyle>
    );
};
