import styled, { keyframes } from 'styled-components';
import { breakpointDown } from '@reassure/ui';
import DownloadIcon from '../../../assets/images/download-icon.svg';
import WhiteDownloadIcon from '../../../assets/images/white-download-icon.svg';
import CalendarIcon from '../../../assets/images/calendar-icon.svg';

import { Container } from '@reassure/ui';

const SpinAnimation = keyframes`
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
`;

export const ResultsStyle = styled.div`
    .header-sticky-bar {
        margin-bottom: ${({ theme }) => theme.space[5]};
        display: flex;
        align-items: center;
        min-height: 71px;
        background-color: ${({ theme }) => theme.colors.green};
        color: ${({ theme }) => theme.colors.white};

        ${breakpointDown.md`
           min-height: 160px;
           text-align: center;
        `}

        &__title {
            margin: 0;
            color: ${({ theme }) => theme.colors.white}!important;
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            ${breakpointDown.sm`
                flex-direction: column;
            `}
        }

        &__download-pdf {
            display: flex;
            gap: 20px;
            align-items: center;

            ${breakpointDown.xs`
                display: block;
            `}
        }

        .tooltip {
            display: flex;
            gap: 10px;
            align-items: center;
            position: relative;
            &__icon {
            }

            &__text {
                text-decoration: underline;
                cursor: pointer;

                ${breakpointDown.xs`
                    margin: 10px 0;
                `}
            }

            &__help-box {
                display: none;
                width: 351px;
                padding: 20px;
                position: absolute;
                top: 30px;
                left: -50px;
                z-index: 99;
                border-radius: 6px;
                background-color: ${({ theme }) => theme.colors.aubergine};
            }

            &:hover {
                .tooltip__help-box {
                    display: block;
                }
            }
        }

        .pdf {
            &__btn {
                display: flex;
                align-items: center;
                background-color: transparent;
                border-radius: 4px;
                cursor: pointer;
                text-decoration: none;
                gap: 14px;
                padding: 6px 12px;
                border: 2px solid ${({ theme }) => theme.colors.white};
                font-family: 'PhoenixSansMediumWeb';
                color: ${({ theme }) => theme.colors.white};
                text-align: left;
                transition: border-bottom-right-radius 180ms ease-in-out;
                width: auto;

                &:focus,
                &:hover {
                    border-bottom-right-radius: 22px;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    background: url(${WhiteDownloadIcon}) no-repeat center center;
                    height: 32px;
                    width: 32px;
                }

                &--downloading {
                    opacity: 0.6;
                    pointer-events: none;
                    padding: 10px 12px;

                    &::before {
                        background: none;
                        opacity: 1;
                        content: '\f021';
                        font-family: 'Font Awesome 6 Free';
                        animation: ${SpinAnimation} 1s infinite linear;
                        font-weight: 900;
                        height: auto;
                        width: auto;
                    }
                }
            }
        }
    }

    .sticky {
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 100;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
    }

    .results {
        &__sticky-margin {
            margin-top: 70px;
        }

        & > div {
            ${breakpointDown.md`
                padding: 30px;
            `}
        }

        &__top {
            margin-bottom: ${({ theme }) => theme.space[7]}; // 32px;
            text-align: center;

            ${breakpointDown.md`
                margin-bottom: 0;
            `}
        }

        &__btn {
            display: flex;
            align-items: center;
            background-color: transparent;
            cursor: pointer;
            text-decoration: none;
            gap: 14px;
            padding: 6px 12px;
            border: 2px solid ${({ theme }) => theme.colors.ember};
            font-family: 'PhoenixSansMediumWeb';
            color: ${({ theme }) => theme.colors.charcoal};
            text-align: left;
            transition: border-bottom-right-radius 180ms ease-in-out;
            width: 320px;
            &:first-child {
                margin-bottom: 10px;
            }

            &:focus,
            &:hover {
                border-bottom-right-radius: 22px;
            }

            &:before {
                content: '';
                display: inline-block;
                background: url(${DownloadIcon}) no-repeat center center;
                height: 32px;
                width: 32px;
            }

            &--calendar {
                &:before {
                    background: url(${CalendarIcon}) no-repeat center center;
                    height: 32px;
                    width: 32px;
                }
            }
            &--downloading {
                opacity: 0.6;
                pointer-events: none;
                padding: 10px 12px;

                &::before {
                    background: none;
                    opacity: 1;
                    content: '\f021';
                    font-family: 'Font Awesome 6 Free';
                    animation: ${SpinAnimation} 1s infinite linear;
                    font-weight: 900;
                    height: auto;
                    width: auto;
                }
            }
        }

        &__title {
            font-family: 'PhoenixSansMediumWeb';
            margin-top: 0;
            font-size: 40px;
            line-height: ${({ theme }) => theme.fontSizes[12]}!important; // 42px;
        }
        &__subtitle {
            font-family: 'PhoenixSansMediumWeb';
        }
        &__section {
            height: 380px;
            border: 1px solid ${({ theme }) => theme.colors.white};
            border-radius: 5px;
            padding: 30px 26px;
            margin-bottom: 24px;
            text-align: center;
            box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.07);

            ${breakpointDown.md`
                margin-top: 30px;
                height: auto;
            `};

            &--wellbeing {
                border-color: ${({ theme }) => theme.colors.purple};
                position: relative;
            }

            &--worklife {
                border-color: ${({ theme }) => theme.colors.teal};
                position: relative;
            }

            &--wealth {
                border-color: ${({ theme }) => theme.colors.brightGreen};
                position: relative;
            }

            &--img {
                position: absolute;
                top: 85px;
                left: 50%;
                width: 25px;
                height: 25px;
                transform: translateX(-50%);
                z-index: 20;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__sections {
            padding-right: 85px;
        }

        &__section-title {
            font-family: 'PhoenixSansBoldWeb';
            margin: 0;
            margin-bottom: 11px;
            font-size: ${({ theme }) => theme.fontSizes[8]}!important; // 24px;
            line-height: ${({ theme }) => theme.fontSizes[8]}!important; // 24;

            ${breakpointDown.md`
                margin-top: 30px;
            `};
        }

        p {
            /* font-family: 'PhoenixSansRegularWeb'; */
            margin: 0;
        }

        .strong {
            font-family: 'PhoenixSansBoldWeb';
        }

        &__improve {
            margin-top: 40px;
            background-color: ${({ theme }) => theme.colors.aubergine};
            color: ${({ theme }) => theme.colors.white};
            padding: 20px;
            display: flex;
            align-items: center;
            gap: 20px;

            ${breakpointDown.md`
                display: block;
            `}
        }

        &__improve-title {
            margin-top: 0;
            width: 550px;
            color: ${({ theme }) => theme.colors.white}!important;
        }

        &__improve-text span {
            margin: 0 5px;

            a {
                color: ${({ theme }) => theme.colors.ember};
            }
        }

        &__section-score {
            font-family: 'PhoenixSansMediumWeb';
            margin-bottom: 11px !important;
        }

        &__link {
            font-family: 'PhoenixSansMediumWeb';
            color: ${({ theme }) => theme.colors.charcoal};
            text-decoration: none;
            font-size: ${({ theme }) => theme.fontSizes[5]}!important; // 24px;
            line-height: ${({ theme }) => theme.fontSizes[8]}!important; // 24;
        }

        &__reminder {
            max-width: 728px;
            margin: 0 auto;

            &__row {
                margin-top: ${({ theme }) => theme.space[5]};
            }
        }
    }
    .score-color {
        color: ${({ theme }) => theme.colors.green};
    }
`;

export const RuiContainer = styled(Container)`
    background-color: ${({ theme }) => theme.colors.white};
    max-width: 1200px;
    padding: 40px 65px;
`;
