import { SubmitHandler, useForm } from 'react-hook-form';
import { IFormValues } from './interface';
import { useSubmitFeedbackLazyQuery } from 'graphql/generated';

interface IFormValuesExtended extends IFormValues {
    takeActionFromTips?: string;
    anythingElse?: string;
    overallExperienceRating?: string;
    howEasyRating?: string;
}

export const useCreateFeedback = () => {
    const { register, handleSubmit } = useForm<IFormValuesExtended>();

    // WIP call - submitFeedback GraphQL - ===
    const [submitForm] = useSubmitFeedbackLazyQuery({
        variables: { input: {} },
    });

    const onSubmit: SubmitHandler<IFormValuesExtended> = data => {
        const { overallExperienceRating, howEasyRating, takeActionFromTips, anythingElse } = data;

        // from useSubmitFeedbackLazyQuery hook - apollo
        submitForm({
            variables: {
                input: {
                    overallExperienceRating: overallExperienceRating?.toString(),
                    howEasyRating: howEasyRating?.toString(),
                    takeActionFromTips,
                    anythingElse,
                },
            },
        });
    };

    return {
        register,
        handleSubmit,
        onSubmit,
    };
};
