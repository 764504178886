import styled from 'styled-components';

export const SkipToContentStyle = styled.a`
    // https://css-tricks.com/how-to-create-a-skip-to-content-link/
    z-index:2000;
    background-color: ${({ theme }) => theme.colors.lightaubergine};
    color: ${({ theme }) => theme.colors.white};
	display:block;  
	left: 0px;
	top:0;
	padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]};
	margin:0;
	position: fixed;
	transform: translateY(-100%);
	transition: transform 0.3s;
	text-decoration: none;

    &:focus {
	    transform: translate(3px, 3px);
    }
`;