/**
 * Styled-components theme for page body
 */
import pallette from './pallette';
const { eggshell, charcoal } = pallette;

const themeConfig = {
    colors: {
        body: {
            background: eggshell,
        },
        heading: {
            color: charcoal
        },
    }
};

export default themeConfig;
