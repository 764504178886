import styled from 'styled-components';
import { breakpointDown } from '@reassure/ui';

export const HomeIntroVideoStyle = styled.div`
    ${breakpointDown.md`
        margin-top: 30px;
    `}
    .video {
        &__container {
            padding: 56.25% 0 0 0;
            position: relative;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    p {
        font-family: 'PhoenixSansBoldWeb';
        line-height: 1.5;
    }
`;
