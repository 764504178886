import { Row, Col, H1, P } from '@reassure/ui';
import { QuestionnaireBackButton } from 'components/questionnaire-back-button';
import { AboutStyles, QuestionContainer } from 'components/styles';
import { Link } from 'react-router-dom';

export const AboutWorklife = () => {
    return (
        <QuestionContainer>
            <Row>
                <Col>
                    <AboutStyles>
                        <div role="region" aria-label="about worklife mid-life mot" className="about">
                            <H1 className="about__title">About your Work-life</H1>
                            <P className="about__content">
                                The Work-life section of the Mid-life MOT is here to help you consider what you might
                                want from work, and what support or information you might need to make it a reality.
                                Through the questions you answer today, you will get ideas and feedback on some of the
                                opportunities and choices that you might want to take.
                            </P>

                            <Row style={{ marginTop: 25, justifyContent: 'space-between' }}>
                                <QuestionnaireBackButton route={'/question-six'} />
                                <Link className="about__btn" to="/question-seven">
                                    <span>Continue</span>
                                </Link>
                            </Row>
                        </div>
                    </AboutStyles>
                </Col>
            </Row>
        </QuestionContainer>
    );
};
