import { ComponentPropsWithoutRef } from 'react';
import { useController } from 'react-hook-form';
import { RadioControl } from '../controls/radio-control';
import { FieldLabel, RadioFieldContainer } from './field.styles';

interface RadioFieldProps {
    name: string;
    label: string;
}

type ExcludedProps = 'onChange' | 'onBlur' | 'name' | 'checked' | 'id';

export const RadioField = ({
    name,
    label,
    ...otherProps
}: RadioFieldProps & Omit<ComponentPropsWithoutRef<'input'>, ExcludedProps>) => {

    
    const { field: { ref, value, ...field }, } = useController({name});

    const fieldId = `${name}_${otherProps.value}`;
    
    return (
        <RadioFieldContainer>
            <div className="input-container">
                <RadioControl {...field} {...otherProps} id={fieldId} defaultChecked={value === otherProps.value}/>
                <FieldLabel htmlFor={fieldId}>{label}</FieldLabel>
            </div>
        </RadioFieldContainer>
    );
};
