const initialState =
    process.env.REACT_APP_PRELOAD_FORM === 'true'
        ? {
              wellbeing1: 'd',
              wellbeing2: 'd',
              wellbeing3: 'a',
              wellbeing4: 'b',
              wellbeing5: 'd',
              wellbeing6: 'c',

              worklife1: 'd',
              worklife2: 'e',
              worklife3: 'a',
              worklife4: 'd',
              worklife5: 'a',

              wealth1: 'a',
              wealth2: 'd',
              wealth3: 'c',
              wealth4: 'c',
              wealth5: 'd',
              wealth6: 'c',
              wealth7: 'a',
          }
        : {
              wellbeing1: '',
              wellbeing2: '',
              wellbeing3: '',
              wellbeing4: '',
              wellbeing5: '',
              wellbeing6: '',

              worklife1: '',
              worklife2: '',
              worklife3: '',
              worklife4: '',
              worklife5: '',

              wealth1: '',
              wealth2: '',
              wealth3: '',
              wealth4: '',
              wealth5: '',
              wealth6: '',
              wealth7: '',
          };

export default initialState;
