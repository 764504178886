import { Svg, G, Polygon, Path } from '@react-pdf/renderer';

import { styles } from './results-pdf.styles';

const MySvg: any = Svg;
const MyG: any = G;
const MyPolygon: any = Polygon;
const MyPath: any = Path;

export const ChecklistIcon = () => (
    <MySvg style={styles.checklist_icon} viewBox="0 0 24 28">
        <MyG stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <MyG transform="translate(-230.000000, -893.000000)" fill="#2E2D2C" fill-rule="nonzero">
                <MyG transform="translate(230.000000, 893.000000)">
                    <MyPath d="M18,2 L18,0 L16,0 L16,2 L13,2 L13,0 L11,0 L11,2 L8,2 L8,0 L6,0 L6,2 L0,2 L0,28 L2,28 L2,4 L6,4 L6,6 L8,6 L8,4 L11,4 L11,6 L13,6 L13,4 L16,4 L16,6 L18,6 L18,4 L22,4 L22,26 L6,26 C4.9,26 4,26.9 4,28 L24,28 L24,2 L18,2 Z" />
                    <MyPolygon points="18 22 6 22 6 9 16 9 16 11 8 11 8 20 16 20 16 15 18 15" />
                    <MyPath d="M16.18,10.83 L11.5,15.51 L10.09,14.1 L8.67,15.51 L11.5,18.34 L18.92,10.92 C18.14,10.14 16.96,10.05 16.18,10.83 Z" />
                </MyG>
            </MyG>
        </MyG>
    </MySvg>
);
