import { Container, Row, Col, Link as RuiLink } from '@reassure/ui';
import { Link } from 'react-router-dom';
import { Logo } from 'components/logo';
import { HeaderStyle } from './header.styles';

export const Header = () => {
    return (
        <HeaderStyle>
            <Container>
                <Row className="headerRow">
                    <Col>
                        <Link to="/">
                            <Logo />
                        </Link>
                    </Col>
                    <Col>
                        <ul className="nav-list">
                            <li className="nav-list__item">
                                <RuiLink target="_blank" href="/help">
                                    Need more help?
                                </RuiLink>
                            </li>
                            <li className="nav-list__item">
                                <RuiLink
                                    href="https://www.thephoenixgroup.com/site-services/accessibility"
                                    target="_blank"
                                >
                                    Accessibility
                                </RuiLink>
                            </li>
                            <li className="nav-list__item">
                                <RuiLink
                                    href="https://www.thephoenixgroup.com/site-services/privacy-hub"
                                    target="_blank"
                                >
                                    Privacy policy
                                </RuiLink>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </HeaderStyle>
    );
};
